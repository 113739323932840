import FormField from '@sportnet/ui/FormField/redux-form';
import FormGroup from '@sportnet/ui/FormGroup';
import Col, { Row } from '@sportnet/ui/Grid';
import Label from '@sportnet/ui/Label/Label';
import { Loader } from '@sportnet/ui/Loader';
import Button from '@sportnet/ui/Button';
import Editor from '@sportnet/content/editor';
import defaultWidgets from '@sportnet/content/editor/defaultWidgets';
import { theme as defaultContentTheme } from '@sportnet/content/theme/theme';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { getProp } from '@sportnet/utilities';
import { ThemeProvider } from 'styled-components';
import API from '../../API';
import { required, __ } from '../../utils';
import { PART_FORM_NAME } from '.';
import MandatoryVideo from '../MandatoryVideo';

const availableWidgets = [
  'imagead',
  'document',
  'externalArticle',
  'photogallery',
  'photo',
  'multiblock',
  'table',
  'social',
  'video',
  'faq',
  'hr',
  'iframe',
  'banners',
  'textBlock',
];

class SeminarPartForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      testTemplates: [],
      isFetchingTemplates: false,
      mandatoryVideoForm: false,
      partGroupOptions: [
        ...props.parts
          .filter(({ group }) => !!group)
          .reduce(
            (uniqueGroups, { group }) => uniqueGroups.add(group),
            new Set(),
          ),
      ].map((group) => ({
        label: group,
        value: group,
      })),
    };
    this.handleChangeVideo = this.handleChangeVideo.bind(this);
  }

  componentDidMount() {
    this.fetchTemplates();
  }

  fetchTemplates = async () => {
    try {
      this.setState({ isFetchingTemplates: true });
      const res = await API.templateList(this.props.org, {
        offset: 0,
        limit: 1000,
        private: true,
      });
      this.setState({
        testTemplates: res.templates,
      });
    } catch (e) {
      alert(__('Nepodarilo sa získať zoznam dostupných šablón testov'));
    } finally {
      this.setState({ isFetchingTemplates: false });
    }
  };

  handleChangeVideo = (video) => {
    this.props.dispatch(change(PART_FORM_NAME, 'mandatoryVideo', video));
    this.setState({ mandatoryVideoForm: false });
  };

  render() {
    if (this.state.isFetchingTemplates) {
      return <Loader size="m" />;
    }
    const { mandatoryVideo } = this.props;
    return (
      <>
        <Row>
          <Col xs={12}>
            <Field
              label={__('Názov časti')}
              component={FormField}
              name="name"
              required
              validate={[required]}
            />
          </Col>
          <Col xs={12}>
            <Field
              label={__('Skupina')}
              info={__(
                'Zadajte skupinu podľa ktorej sa zoskupia položky v menu',
              )}
              type="theselectsimple"
              component={FormField}
              name="group"
              options={this.state.partGroupOptions}
              createNewOption={(newValue) => {
                const option = {
                  value: newValue,
                  label: newValue,
                };
                this.setState((prevState) => {
                  return {
                    ...prevState,
                    partGroupOptions: [...prevState.partGroupOptions, option],
                  };
                });
                return option;
              }}
            />
          </Col>
          <Col xs={12}>
            <Field
              multiple
              name="testTemplates"
              component={FormField}
              type="theselectsimple"
              label={__('Šablóny testov')}
              options={this.state.testTemplates.map((template) => ({
                label: template.name,
                value: template._id,
              }))}
              format={(i) => {
                return (i || []).map((item) => {
                  if (item && item._id) {
                    return item._id;
                  }
                  return item;
                });
              }}
            />
          </Col>
          <Col xs={12}>
            <Field
              multiple
              name="partDependencies"
              component={FormField}
              type="theselectsimple"
              label={__('Závislosť na iných častiach')}
              options={this.props.parts
                .filter(
                  ({ _id }) =>
                    !!!this.props.initialValues ||
                    _id !== this.props.initialValues._id,
                )
                .map((part) => ({
                  label: part.name,
                  value: part._id,
                }))}
            />
          </Col>
          <Col xs={12}>
            <ThemeProvider theme={defaultContentTheme}>
              <FormGroup>
                <Label>{__('Popis')}</Label>
                <Editor
                  appId={process.env.REACT_APP_APP_ID}
                  appSpace={this.props.org}
                  contentDivider="default"
                  token={Cookies.get('access_token')}
                  initialValue={getProp(
                    this.props,
                    ['initialValues', 'content'],
                    [],
                  )}
                  availableWidgets={defaultWidgets.filter((i) =>
                    availableWidgets.includes(i.type),
                  )}
                  onChange={(value) => {
                    this.props.dispatch(
                      change(this.props.form, 'content', value),
                    );
                  }}
                />
              </FormGroup>
            </ThemeProvider>
          </Col>
          <Col xs={12}>
            <FormGroup>
              <Label>{__('Povinné video')}</Label>
              <Button
                primary={!!mandatoryVideo}
                basic={!!!mandatoryVideo}
                onClick={() => {
                  this.setState((prevState) => ({
                    mandatoryVideoForm: !prevState.mandatoryVideoForm,
                  }));
                }}
                icon={mandatoryVideo ? 'check-circle' : 'plus'}
              >
                {__('Povinné video')}
              </Button>
            </FormGroup>
          </Col>
        </Row>
        {this.state.mandatoryVideoForm && (
          <MandatoryVideo
            isOpen={this.state.mandatoryVideoForm}
            handleClose={() => this.setState({ mandatoryVideoForm: false })}
            video={mandatoryVideo}
            onVideoChange={this.handleChangeVideo}
          />
        )}
      </>
    );
  }
}

SeminarPartForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  org: PropTypes.string.isRequired,
  parts: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      group: PropTypes.string,
    }),
  ),
};

SeminarPartForm.defaultProps = {
  parts: [],
};

export default compose(
  connect((state) => {
    const valueSelector = formValueSelector(PART_FORM_NAME);
    return {
      mandatoryVideo: valueSelector(state, 'mandatoryVideo'),
    };
  }),
  reduxForm({ destroyOnUnmount: true }),
)(SeminarPartForm);
