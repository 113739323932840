import Loader from '@sportnet/ui/Loader';
import connectQueryHoc from '@sportnet/query-hoc';
import React, { Component } from 'react';
import { compose } from 'redux';
import styled from 'styled-components';
import API from '../../API';
import TestPrint from '../../components/TestPrint';
import { questionsQueryParameters } from '../QuestionsList';

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

class QuestionsPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test: null,
    };

    this.loadTest = this.loadTest.bind(this);
  }

  componentDidMount() {
    this.loadTest(this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.serializedQuery !== prevProps.serializedQuery) {
      this.loadTest(this.props);
    }
  }

  async loadTest(props) {
    const {
      query: { smarttags, q, offset, limit, withAnswers },
      match: {
        params: { userspace_id: userspaceId },
      },
    } = props;
    try {
      const params = {
        offset,
        limit,
        withAnswers,
      };
      if (q) {
        params.q = q;
      }
      if (smarttags) {
        params.smarttags = smarttags;
      }
      const test = await API.questionListAsAnonymouseTest(userspaceId, params);
      this.setState({
        test,
      });
    } catch (e) {
      alert(e);
    }
  }

  render() {
    if (!this.state.test) {
      return (
        <LoaderWrapper>
          <Loader size="xl" color="#F00" />
        </LoaderWrapper>
      );
    }
    return <TestPrint testDetail={this.state.test} evalHidden={false} />;
  }
}

export default compose(
  connectQueryHoc({
    parameters: questionsQueryParameters,
  }),
)(QuestionsPrint);
