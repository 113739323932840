import { ContextBar, ContextBarItem } from '@sportnet/ui/ContextBar';
import HeaderBar from '@sportnet/ui/HeaderBar';
import { ContentLoader } from '@sportnet/ui/Loader';
import NotFound from '@sportnet/ui/NotFound';
import Paginator from '@sportnet/ui/Paginator';
import Segment from '@sportnet/ui/Segment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getProp } from '@sportnet/utilities';
import API from '../../API';
import Layout from '../../components/Layout';
import TestMenu from '../../components/TestMenu';
import TestUsersTable from '../../components/TestUsersTable';
import { __ } from '../../utils';
import { activeAppSpaceSelector } from '../Authorization/selectors';

class TestUsersList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tests: [],
      isFetching: false,
      offset: 0,
      total: 0,
      nextOffset: null,
    };
    this.limit = 20;
    this.testDetail = this.testDetail.bind(this);
    this.handleChangeOffset = this.handleChangeOffset.bind(this);
  }
  componentDidMount() {
    this.fetchTests();
    this.getTestTemplate();
  }

  handleChangeOffset(offset) {
    this.fetchTests(offset);
  }
  fetchTests(offset = 0) {
    const { params } = this.props.match;
    this.setState({
      isFetching: true,
    });
    API.adminTestList(params.userspace_id, params.test_id, {
      offset,
      limit: this.limit,
    })
      .then((res) => {
        this.setState({
          isFetching: false,
          tests: res.tests,
          offset: res.offset,
          nextOffset: res.next_offset,
          total: res.total,
        });
      })
      .catch((e) => {
        console.error(e);
      });
  }
  testDetail(test) {
    const { history } = this.props;
    const { params } = this.props.match;
    history.push(
      `/userspace/${params.userspace_id}/template/${test.templateId}/test/${test._id}`,
    );
  }
  getTestTemplate() {
    const { params } = this.props.match;
    API.templateDetail(params.userspace_id, params.test_id)
      .then((res) => {
        this.setState({
          testDetail: res,
        });
      })
      .catch((e) => {
        console.error(e);
      });
  }

  render() {
    const bottomFixed = (
      <ContextBar>
        <ContextBarItem>
          <Paginator
            total={this.state.total}
            offset={this.state.offset}
            onChangeOffset={this.handleChangeOffset}
            limit={20}
            step={20}
          />
        </ContextBarItem>
      </ContextBar>
    );

    return (
      <Layout
        topFixed={
          <HeaderBar>
            <HeaderBar.Action
              icon="back"
              as={Link}
              to={`/userspace/${this.props.match.params.userspace_id}/templates`}
              title={__('Testy')}
            />
            <HeaderBar.Header>
              {this.props.match.params.test_id
                ? `${__('Šablóna testu')}: ${getProp(
                    this.state,
                    ['testDetail', 'name'],
                    this.props.match.params.test_id,
                  )}`
                : `${__('Nová šablóna testu')}`}
            </HeaderBar.Header>
          </HeaderBar>
        }
        bottomFixed={bottomFixed}
      >
        <TestMenu
          active="Zoznam testov"
          isPrivate={
            !!this.props.match.params.test_id &&
            getProp(this.state, ['testDetail', 'private'], false)
          }
        />
        <Segment noBottomGutter>
          {this.state.isFetching ? (
            <ContentLoader size="xl" />
          ) : this.state.tests.length === 0 ? (
            <NotFound
              icon="file"
              title={__('Vybraný test zatiaľ nebol ani raz vyriešený')}
            />
          ) : (
            <Segment raised>
              <TestUsersTable
                tests={this.state.tests}
                offset={this.state.offset}
                nextOffset={this.state.nextOffset}
                total={this.state.total}
                limit={this.limit}
                testDetail={this.testDetail}
                handleChangeOffset={this.handleChangeOffset}
              />
            </Segment>
          )}
        </Segment>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userspace: activeAppSpaceSelector(state),
  };
};

TestUsersList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userspace_id: PropTypes.string,
      test_id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  userspace: PropTypes.shape({
    org_profile: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(TestUsersList);
