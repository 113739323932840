import Content from '@sportnet/content/editor';
import PropTypes from 'prop-types';
import React from 'react';
import config from '../../config';
import { useAuth } from '@sportnet/auth-react';

const ContentEditor = ({ value, onChange, availableWidgets }) => {
  const { accessToken, ppo: appSpace } = useAuth();
  return (
    <Content
      appId={process.env.REACT_APP_APP_ID}
      appSpace={appSpace}
      contentDivider={config.DEFAULT_CONTENT_DIVIDER}
      token={accessToken}
      initialValue={value}
      onChange={onChange}
      availableWidgets={availableWidgets}
    />
  );
};

ContentEditor.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
  availableWidgets: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ),
};

ContentEditor.defaultProps = {
  availableWidgets: undefined,
};

export default ContentEditor;
