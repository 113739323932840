import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { userspaceUsersReducer } from './containers/Administration/reducer';
import { applicationReducer } from './containers/Authorization/reducer';

export default combineReducers({
  application: applicationReducer,
  userspaceUsers: userspaceUsersReducer,
  form: formReducer,
});
