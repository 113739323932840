import Button from '@sportnet/ui/Button';
import Checkbox from '@sportnet/ui/Checkbox';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import Col, { Row } from '@sportnet/ui/Grid';
import Header from '@sportnet/ui/Header';
import HeaderBar from '@sportnet/ui/HeaderBar';
import Label from '@sportnet/ui/Label/Label';
import { ContentLoader } from '@sportnet/ui/Loader';
import Segment from '@sportnet/ui/Segment';
import { Table, Tbody, Td, Tr } from '@sportnet/ui/Table';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getProp } from '@sportnet/utilities';
import styled from 'styled-components';
import API from '../../API';
import ContentView from '../../components/Content/view';
import Image from '../../components/Image';
import Layout from '../../components/Layout';
import { renderSmartTagsFromTest } from '../../components/TestPrint';
import {
  nl2br,
  __,
  formatDate,
  formatDateLocalized,
  getDurationInMinutes,
} from '../../utils';
import { activeAppSpaceSelector } from '../Authorization/selectors';

const NotFound = styled.div`
  color: #999;
  margin-left: ${rem(30)};
`;

const HeaderTag = styled.span`
  font-weight: bold;
`;

const TextIconWrapper = styled.div`
  display: flex;
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-right: ${rem(20)};
  width: ${rem(10)};
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.color.primary};
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

const Print = styled.div`
  @media print {
    * {
      display: block !important;
      height: auto !important;
      width: auto !important;
      line-height: 1 !important;
    }
    @page {
      size: auto; /* auto is the initial value */

      /* this affects the margin in the printer settings */
      margin: 25mm;
    }
    .print-summary {
      padding: 0;
      box-shadow: none;
    }
    .print-summary-table {
      td {
        height: auto;
      }
    }
    .print-wrapper {
      padding: 0;
    }
    .print-col {
      width: 100%;
      padding: 0;
      > div {
        box-shadow: none;
      }
    }
    .print-question-segment {
      padding: 0;
      display: block;
      * {
        display: table;
      }
    }
    img {
      visibility: visible;
    }
    .print-widgets-wrapper {
      * {
        background: none !important;
      }
    }
  }
`;

class TestUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      testDetail: null,
      nonEvaluated: null,
      evalHidden: false,
    };
    this.evaluateQuestion = this.evaluateQuestion.bind(this);
    this.evaluateTest = this.evaluateTest.bind(this);
    this.printTest = this.printTest.bind(this);
    this.oldTitle = window.document.title;
  }

  componentDidMount() {
    this.fetchTestDetail();
  }

  componentWillUnmount() {
    window.document.title = this.oldTitle;
  }

  getQuestionIcon(question, evalHidden) {
    if (evalHidden) {
      return null;
    }

    // ak bol vyhodnoteny
    if (this.state.testDetail.evaluated) {
      if (question.correct) {
        // spravne zodpovedana otazka
        return <Image name="checkbox-correct" width={23} height={23} />;
      }
      if (question.answers.filter((a) => a.checked).length === 0) {
        // nezodpovedal otazku
        return <Image name="checkbox-unknown" width={23} height={23} />;
      }
      // zle zodpovedal otazku
      return <Image name="checkbox-wrong" width={23} height={23} />;
    }
    if (question.type === 'text') {
      return <Image name="exclamation-mark-circle" width={23} height={23} />;
    }
    return null;
  }

  fetchTestDetail() {
    const { params } = this.props.match;
    API.adminTestDetail(params.userspace_id, params.template_id, params.test_id)
      .then((res) => {
        let i = 0;
        const nonEvaluated = res.questions.reduce((prev, next) => {
          i += 1;
          if (next.type === 'text') {
            const el = next;
            el.checked = false;
            el.index = i - 1;
            return { ...prev, [el._id]: el };
          }
          return prev;
        }, {});
        this.setState(
          {
            isFetching: false,
            testDetail: res,
            nonEvaluated,
          },
          () => {
            window.document.title = `Sportnet TEST - ${
              this.state.testDetail.name
            } - ${formatDate(this.state.testDetail.startAt)} - ${this.state.testDetail.user.display_name}`;
          },
        );
      })
      .catch((e) => {
        console.error(e);
      });
  }

  checkIfCorrect(answer, evalHidden) {
    if (evalHidden) {
      if (answer.checked) {
        return <Image name="checkbox-correct" width={16} height={16} />;
      }
      return <Image name="checkbox" width={16} height={16} />;
    }

    if (answer.correct && answer.checked) {
      return <Image name="checkbox-correct" width={16} height={16} />;
    }
    if (answer.correct === false) {
      return <Image name="checkbox-wrong" width={16} height={16} />;
    }

    return <Image name="checkbox" width={16} height={16} />;
  }

  evaluateQuestion(questionId) {
    const nonEvaluated = { ...this.state.nonEvaluated };
    nonEvaluated[questionId].checked =
      !this.state.nonEvaluated[questionId].checked;
    this.setState({
      nonEvaluated,
    });
  }

  evaluateTest() {
    const confirm = window.confirm(
      __(
        'Skutočne si želáte vyhodnotiť test? Pred odoslaním prosím skontrolujte, či ste vyhodnotili všetky otázky.',
      ),
    );
    if (confirm) {
      const { params } = this.props.match;
      const data = {
        evaluation: Object.keys(this.state.nonEvaluated).map((index) => {
          return {
            questionIndex: this.state.nonEvaluated[index].index,
            correct: this.state.nonEvaluated[index].checked,
          };
        }),
      };
      API.adminTestEvaluate(
        params.userspace_id,
        params.template_id,
        params.test_id,
        {
          body: data,
        },
      )
        .then(() => {
          this.props.history.push(
            `/userspace/${params.userspace_id}/template/${params.template_id}/tests`,
          );
        })
        .catch((e) => {
          alert(e);
        });
    }
  }

  printTest = () => {
    const {
      match: {
        params: {
          userspace_id: userspaceId,
          template_id: templateId,
          test_id: testId,
        },
      },
    } = this.props;
    const { evalHidden } = this.state;
    window.open(
      `/userspace/${userspaceId}/template/${templateId}/test/${testId}/print?evalHidden=${evalHidden}`,
    );
  };

  toggleEvalHidden = () => {
    this.setState({
      evalHidden: !this.state.evalHidden,
    });
  };

  render() {
    const { testDetail, evalHidden } = this.state;
    const { location } = this.props;
    const query = new URLSearchParams(location.search);
    const backLink = query.get('back');
    return (
      <Layout
        topFixed={
          <HeaderBar>
            <HeaderBar.Action
              icon="back"
              as={Link}
              to={
                backLink ||
                `/userspace/${this.props.match.params.userspace_id}/template/${this.props.match.params.template_id}/users`
              }
              title={__('Späť')}
            />
            <HeaderBar.Header>
              {`${__('Riešený test')}: ${getProp(testDetail, ['name'], '')}`}
            </HeaderBar.Header>
          </HeaderBar>
        }
        bottomFixed={
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <div>
                <Button primary basic onClick={this.toggleEvalHidden}>
                  {evalHidden
                    ? __('Zobraziť hodnotenie')
                    : __('Skryť hodnotenie')}
                </Button>{' '}
                <Button primary basic onClick={this.printTest}>
                  {__('Vytlačiť test')}
                </Button>{' '}
                {testDetail && !testDetail.evaluated && (
                  <Button primary onClick={this.evaluateTest}>
                    {__('Vyhodnotiť')}
                  </Button>
                )}
              </div>
            </ContextBarItem>
          </ContextBar>
        }
      >
        {this.state.isFetching ? (
          <ContentLoader size="xl" />
        ) : (
          <Print>
            <Segment className="print-wrapper">
              <div style={{ fontSize: rem(14) }}>
                <Segment raised className="print-summary">
                  <Header size="l">{testDetail.name}</Header>
                  <Table className="print-summary-table">
                    <Tbody>
                      <Tr>
                        <Td>
                          <HeaderTag>
                            {__('Inštitúcia vykonávajúca skúšku')}:
                          </HeaderTag>
                        </Td>
                        <Td>{testDetail.template.testInstitutionName}</Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <HeaderTag>
                            {__('Osoba, ktorá vedie skúšku')}:
                          </HeaderTag>
                        </Td>
                        <Td>{testDetail.template.testLectorName}</Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <HeaderTag>
                            {__('Meno a priezvisko testovaného')}:
                          </HeaderTag>
                        </Td>
                        <Td>
                          <StyledLink
                            to={`/userspace/${this.props.match.params.userspace_id}/users/${testDetail.user.sportnet_id}/tests`}
                            title={__('Zobraziť všetky testy používateľa')}
                          >
                            {testDetail.user.display_name} (
                            {testDetail.user.sportnet_id})
                          </StyledLink>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <HeaderTag>{__('Dátum konania skúšky')}:</HeaderTag>
                        </Td>
                        <Td>
                          {formatDateLocalized(new Date(testDetail.startAt))}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <HeaderTag>{__('Účel vykonania skúšky')}:</HeaderTag>
                        </Td>
                        <Td>{testDetail.template.testPurpose}</Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <HeaderTag>{__('Miesto konania skúšky')}:</HeaderTag>
                        </Td>
                        <Td>{testDetail.template.testVenue}</Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <HeaderTag>{__('Okruhy otázok v teste')}:</HeaderTag>
                        </Td>
                        {testDetail.questions && (
                          <Td>
                            {renderSmartTagsFromTest(testDetail.questions)}
                          </Td>
                        )}
                      </Tr>
                      <Tr>
                        <Td>
                          <HeaderTag>{__('Počet otázok v teste')}</HeaderTag>
                        </Td>
                        <Td>{testDetail.questions.length}</Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <HeaderTag>
                            {__('Dĺžka času na vypracovanie testu')}
                          </HeaderTag>
                        </Td>
                        <Td>{testDetail.limit / 60} min</Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <HeaderTag>
                            {__('Dĺžka vypracovania testu')}
                          </HeaderTag>
                        </Td>
                        <Td>
                          {testDetail.userFinishAt
                            ? getDurationInMinutes(
                                testDetail.userFinishAt,
                                testDetail.startAt,
                              )
                            : testDetail.limit / 60}{' '}
                          min
                        </Td>
                      </Tr>
                      {!evalHidden && (
                        <>
                          <Tr>
                            <Td>
                              <HeaderTag>
                                {__('Počet správnych odpovedí')}
                              </HeaderTag>
                            </Td>
                            <Td>
                              {testDetail.evaluated
                                ? testDetail.nrofCorrectQuestions
                                : '-'}
                            </Td>
                          </Tr>
                          <Tr>
                            <Td>
                              <HeaderTag>
                                {__('Počet nesprávnych odpovedí:')}
                              </HeaderTag>
                            </Td>
                            <Td>
                              {testDetail.evaluated
                                ? testDetail.nrofQuestions -
                                  testDetail.nrofCorrectQuestions
                                : '-'}
                            </Td>
                          </Tr>
                          <Tr>
                            <Td>
                              <HeaderTag>
                                {__('Počet nezodpovedaných otázok')}
                              </HeaderTag>
                            </Td>
                            <Td>
                              {testDetail.evaluated
                                ? testDetail.questions.filter((q) => {
                                    return (
                                      q.answers.filter((a) => a.checked)
                                        .length === 0
                                    );
                                  }).length
                                : '-'}
                            </Td>
                          </Tr>
                        </>
                      )}
                      <Tr>
                        <Td>
                          <HeaderTag>
                            {__('Minimálna potrebná úspešnosť')}
                          </HeaderTag>
                        </Td>
                        <Td>
                          {parseFloat(testDetail.minPassed * 100).toFixed(2)}%
                        </Td>
                      </Tr>
                      {!evalHidden && (
                        <Tr>
                          <Td>
                            <HeaderTag>{__('Dosiahnutá úspešnosť')}</HeaderTag>
                          </Td>
                          <Td>
                            {testDetail.evaluated
                              ? `${parseFloat(
                                  testDetail.evaluation * 100,
                                ).toFixed(2)}% (${
                                  testDetail.nrofCorrectQuestions
                                }/${testDetail.nrofQuestions})`
                              : '-'}
                          </Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                </Segment>
              </div>
              <Row>
                {testDetail.questions.map((question, index) => {
                  return (
                    <Col xs={12} s={6} className="print-col">
                      <Segment
                        raised
                        key={question._id}
                        className="print-question-segment"
                      >
                        <Row style={{ paddingBottom: rem(15) }}>
                          <Col>
                            <strong style={{ fontSize: '14px' }}>
                              <TextIconWrapper>
                                <IconWrapper>
                                  {this.getQuestionIcon(question, evalHidden)}
                                </IconWrapper>
                                {index + 1}.{nl2br(question.text)}
                              </TextIconWrapper>
                            </strong>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="print-widgets-wrapper">
                            <ContentView items={question.description} />
                          </Col>
                        </Row>
                        {(question.answers || []).length === 0 && (
                          <NotFound>{__('Žiadna odpoveď')}</NotFound>
                        )}
                        {question.answers.map((answer) => {
                          const answerStyle = {};
                          if (!evalHidden && answer.isCorrect) {
                            answerStyle.color = 'green';
                            answerStyle.fontWeight = 'bold';
                          }
                          const isTextAnswer =
                            question.type === 'text' && question.answers.length;
                          return (
                            <Row
                              key={
                                answer.text ? answer.text : answer.userAnswer
                              }
                              style={{ fontSize: rem(13), lineHeight: rem(18) }}
                            >
                              <Col>
                                <TextIconWrapper style={answerStyle}>
                                  {!isTextAnswer && (
                                    <IconWrapper>
                                      {this.checkIfCorrect(answer, evalHidden)}
                                    </IconWrapper>
                                  )}
                                  {nl2br(
                                    answer.text
                                      ? answer.text
                                      : answer.userAnswer,
                                  )}{' '}
                                  {!evalHidden && answer.isCorrect
                                    ? __('(správna odpoveď)')
                                    : ''}
                                </TextIconWrapper>
                              </Col>
                              <Col m={6} className="print-widgets-wrapper">
                                <ContentView items={answer.description} />
                              </Col>
                              <Col m={6} />
                              {isTextAnswer && (
                                <Col>
                                  {!evalHidden &&
                                    !testDetail.evaluated &&
                                    question.type === 'text' && (
                                      <>
                                        <br />
                                        <Label id={question._id}>
                                          <Checkbox
                                            id={question._id}
                                            onChange={() => {
                                              this.evaluateQuestion(
                                                question._id,
                                              );
                                            }}
                                          />
                                          {__('Odpoveď je správna')}
                                        </Label>
                                      </>
                                    )}
                                </Col>
                              )}
                            </Row>
                          );
                        })}
                      </Segment>
                    </Col>
                  );
                })}
              </Row>
            </Segment>
          </Print>
        )}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userspace: activeAppSpaceSelector(state),
  };
};

TestUsers.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userspace_id: PropTypes.string.isRequired,
      template_id: PropTypes.string.isRequired,
      test_id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  userspace: PropTypes.shape({
    org_profile: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(TestUsers);
