import HeaderBar from '@sportnet/ui/HeaderBar';
import Segment from '@sportnet/ui/Segment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Button from '@sportnet/ui/Button';
import Sidebar from '@sportnet/ui/Sidebar';
import Input from '@sportnet/ui/Input';
import Label from '@sportnet/ui/Label/Label';
import Message from '@sportnet/ui/Message';
import FormField from '@sportnet/ui/FormField';
import Paginator from '@sportnet/ui/Paginator';
import { ContextBar } from '@sportnet/ui/ContextBar';
import SportnetApi from '../../SportnetApi';
import { __ } from '../../utils';
import Layout from '../../components/Layout';
import API from '../../API';
import useQuery from '../../hooks/useQuery';
import TestUsersTable from '../../components/TestUsersTable';

const ChangeUserForm = ({ onChangeUser }) => {
  const [sportnetId, setSportnetId] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [foundPerson, setFoundPerson] = useState(null);
  const [displayName, setDisplayName] = useState('');
  const [personError, setPersonError] = useState('');
  const searchUser = () => {
    if (!sportnetId) {
      return;
    }
    setIsSearching(true);
    SportnetApi.getPublicUserProfile(sportnetId)
      .then((r) => {
        setFoundPerson(r);
        setDisplayName(`${r.name} ${r.surname}`);
        setPersonError('');
      })
      .catch((e) => {
        if (e.details && e.details.statusCode === 404) {
          setPersonError(__('Osoba nenájdená. Skontrolujte prosím SportnetId'));
        } else {
          setPersonError(e.details ? e.details.name : e.name);
        }
        setDisplayName('');
      })
      .finally(() => {
        setIsSearching(false);
      });
  };

  return (
    <Segment>
      <Label required style={{ marginBottom: '5px' }}>
        {__('SportnetId')}
      </Label>
      <Input
        placeholder={__('Zadajte SportnetId osoby')}
        onChange={(e) => {
          setSportnetId(e.target.value);
          setFoundPerson(null);
          setDisplayName('');
        }}
        value={sportnetId}
      >
        <input />
        <Input.Button
          primary
          icon="search"
          onClick={searchUser}
          loading={isSearching}
        />
      </Input>
      <br />
      {personError && (
        <Message
          danger
          onDispose={() => {
            setPersonError('');
          }}
        >
          {personError}
        </Message>
      )}
      {foundPerson && (
        <>
          <FormField
            label={__('Meno osoby')}
            name="displayName"
            type="text"
            disabled
            value={displayName}
            onChange={(e) => {
              setDisplayName(e.target.value);
            }}
          />
          <Button
            primary
            onClick={() => {
              onChangeUser(foundPerson);
            }}
          >
            {__('Zobraziť testy')}
          </Button>
        </>
      )}
    </Segment>
  );
};
ChangeUserForm.propTypes = { onChangeUser: PropTypes.func.isRequired };

const UserTests = ({
  match: {
    params: { userspace_id: appSpace, sportnetId },
  },
  history,
}) => {
  const query = useQuery();
  const offset = parseInt(query.get('offset'), 10) || 0;
  const limit = parseInt(query.get('limit'), 10) || 100;

  // vyvola zobrazenie sidebaru na zmenu usera
  const [changeUser, setChangeUser] = useState(false);

  // ak sa zmeni user, nacitame si jeho profil, aby sme zobrazili meno
  const [displayName, setDisplayName] = useState();
  const [, setIsLoadingUserDetail] = useState();
  const [personError, setPersonError] = useState('');
  useEffect(() => {
    setIsLoadingUserDetail(true);
    SportnetApi.getPublicUserProfile(sportnetId)
      .then((r) => {
        setDisplayName(`${r.name} ${r.surname}`);
        setPersonError('');
      })
      .catch((e) => {
        if (e.details && e.details.statusCode === 404) {
          setPersonError(__('Osoba nenájdená. Skontrolujte prosím SportnetId'));
        } else {
          setPersonError(e.details ? e.details.name : e.name);
        }
        setDisplayName('');
      })
      .finally(() => {
        setIsLoadingUserDetail(false);
      });
  }, [sportnetId]);

  // naloadovanie zoznamu testov usera
  const [isLoadingTests, setIsLoadingTests] = useState();
  const [userTests, setUserTests] = useState([]);
  const [nextOffset, setNextOffset] = useState(null);
  useEffect(() => {
    setIsLoadingTests(true);
    API.adminUserAllTestsList(appSpace, sportnetId, { limit, offset })
      .then(({ tests, next_offset: no }) => {
        setUserTests(tests);
        setNextOffset(no);
      })
      .catch((e) => {
        alert(
          `Chyba pri získavaní zoznamu testov používateľa: ${
            e.details ? e.details.name : e.name
          }`,
        );
      })
      .finally(() => {
        setIsLoadingTests(false);
      });
  }, [appSpace, sportnetId, offset, limit]);

  return (
    <Layout
      topFixed={
        <>
          <HeaderBar>
            <HeaderBar.Header>
              {__('Zoznam všetkých testov používateľa')}:{' '}
              {displayName ? `${displayName} (${sportnetId})` : sportnetId}
            </HeaderBar.Header>
            <HeaderBar.Spacer />
            <HeaderBar.Item>
              <Button icon="pencil" onClick={() => setChangeUser((s) => !s)}>
                {__('Zmeniť používateľa')}
              </Button>
            </HeaderBar.Item>
          </HeaderBar>
        </>
      }
      bottomFixed={
        <ContextBar>
          <Paginator
            offset={offset}
            limit={limit}
            nextOffset={nextOffset}
            onChangeOffset={(newOffset) => {
              query.set('offset', newOffset);
              history.push(
                `/userspace/${appSpace}/users/${sportnetId}/tests?${query.toString()}`,
              );
            }}
          />
        </ContextBar>
      }
    >
      <Segment>
        {personError && (
          <Message
            danger
            onDispose={() => {
              setPersonError('');
            }}
          >
            {personError}
          </Message>
        )}
        <Segment raised loading={isLoadingTests}>
          <TestUsersTable
            tests={userTests}
            testNameColumn
            userColumn={false}
            testDetail={(test) => {
              history.push(
                `/userspace/${appSpace}/template/${test.templateId}/test/${
                  test._id
                }?back=${encodeURIComponent(
                  window.location.pathname + window.location.search,
                )}`,
              );
            }}
          />
        </Segment>
      </Segment>
      {changeUser && (
        <Sidebar
          visible
          header={__('Zmeniť používateľa')}
          onClose={() => setChangeUser(false)}
        >
          <ChangeUserForm
            onChangeUser={(newUser) => {
              setChangeUser(false);
              history.push(`/userspace/${appSpace}/users/${newUser._id}/tests`);
            }}
          />
        </Sidebar>
      )}
    </Layout>
  );
};

UserTests.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userspace_id: PropTypes.string.isRequired,
      sportnetId: PropTypes.string.isRequired,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default compose(connect(() => ({})))(UserTests);
