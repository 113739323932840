import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import configureStore from './configureStore';
import GlobalStyles from './globalStyles';
import { theme } from './theme';
import { createRoot } from 'react-dom/client';
import App from './containers/App';
import { BrowserRouter } from 'react-router-dom';

const store = configureStore();

const rootElement = document.getElementById('root');
const root = createRoot(rootElement); // create a root

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </>
    </ThemeProvider>
  </Provider>,
);
