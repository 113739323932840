import { useAuth } from '@sportnet/auth-react';

function withSportnetAuth(Component) {
  function ComponentWithSportnetAuthProp(props) {
    const auth = useAuth();
    return <Component {...props} auth={auth ?? {}} />;
  }
  return ComponentWithSportnetAuthProp;
}

export default withSportnetAuth;
