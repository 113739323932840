import React from 'react';
import Message from '@sportnet/ui/Message';
import Loader from '@sportnet/ui/Loader';
import BasicTable from '@sportnet/ui/BasicTable';
import PropTypes from 'prop-types';
import Button from '@sportnet/ui/Button';
import TagmanagerConnector, {
  smarttagsToUrl,
} from '@sportnet/tagmanager-connector';
import SmarttagControl from '@sportnet/ui/SmarttagsControl/SmarttagControl';
import { AlertModal } from '@sportnet/ui/Modal';
import SmarttagTags from '@sportnet/ui/SmarttagsControl/SmarttagTags';
import Input from '@sportnet/ui/Input';
import FormGroup from '@sportnet/ui/FormGroup';
import Col, { Row } from '@sportnet/ui/Grid';
import Header from '@sportnet/ui/Header';
import Text from '@sportnet/ui/Text';
import Api from '../../API';
import { __, getExceptionMessage } from '../../utils';
import { ErrorText } from './styles';
import { useAuth } from '@sportnet/auth-react';

const QuestionsSettings = ({
  payload,
  totalQuestions,
  onChange,
  editIsDisabled,
}) => {
  const payloadItem = {
    smarttags: [],
    count: '',
  };
  // hadles form data with groups and number of questions in groups
  const [formData, setFormData] = React.useState({ ...payloadItem });
  // form field errors
  const [formErrors, setFormErrors] = React.useState({
    smarttags: '',
    count: '',
  });
  // max count request loader
  const [maxCountIsLoading, setMaxCountIsLoading] = React.useState(false);
  // max count of question for selected smart:tags
  const [formInfoMaxCount, setFormInfoMaxCount] = React.useState(0);
  // any exception during processing the form or validations
  const [exception, setException] = React.useState({
    message: null,
  });
  // `true` if form should be disabled
  const [formIsDisabled, setFormIsDisabled] = React.useState(false);

  const { accessToken, ppo: appSpace } = useAuth();

  const getQuestionWord = (num) => {
    const c = num || 0;
    if (c === 1) {
      return __('otázku');
    }
    if (c >= 2 && c <= 4) {
      return __('otázky');
    }
    return __('otázok');
  };

  const vygenerujMudruVetu = () => {
    const sentence = `
Test bude obsahovať ${formData.count || 0} ${getQuestionWord(
      formData.count,
    )}, ktoré
`;

    const parts = [];

    const delimiter = <>, zároveň </>;

    formData.smarttags.forEach((stag) => {
      if (stag.values.length > 1) {
        parts.push(
          <>
            obsahujú jeden zo smarttagov&nbsp;
            <strong>
              {stag.values.map((v) => `${stag.key}:${v.key}`).join(', ')}
            </strong>
          </>,
        );
        parts.push(delimiter);
      } else if (stag.values.length === 1) {
        parts.push(
          <>
            obsahujú smarttag&nbsp;
            <strong>{`${stag.key}:${stag.values[0].key}`}</strong>
          </>,
        );
        parts.push(delimiter);
      }
    });

    parts.pop();

    return (
      <div>
        {sentence}
        {parts}
      </div>
    );
  };

  const generateNewId = () => {
    if (payload && payload.length) {
      const max = payload.reduce((acc, val) => {
        return acc < val._id ? val._id : acc;
      }, -1);
      return max + 1;
    }
    return 1;
  };

  const validate = async () => {
    let result = true;
    if (formData.smarttags.length === 0) {
      setFormErrors((prev) => ({
        ...prev,
        smarttags: __('Zadajte aspoň jeden SAMART:TAG'),
      }));
      result = false;
    }
    if (formData.count !== 0 && !formData.count) {
      setFormErrors((prev) => ({
        ...prev,
        count: __('Počet otázok musí byť celé číslo, väčšie ako 0.'),
      }));
      result = false;
    } else if (!Number.isInteger(formData.count) || formData.count <= 0) {
      setFormErrors((prev) => ({
        ...prev,
        count: __('Počet otázok musí byť celé číslo, väčšie ako 0.'),
      }));
      result = false;
    }

    if (!result) {
      return result;
    }

    // request, ci je pripustny pocet otazok
    if (formData.count > formInfoMaxCount) {
      result = false;
      const msg = __('Maximálny počet otázok vo vybraných okruhoch je: ');
      setFormErrors((prev) => ({
        ...prev,
        count: `${msg} ${formInfoMaxCount}`,
      }));
    }

    return result;
  };

  const handleAddSmarttagsClick = async () => {
    try {
      if (await validate()) {
        const copy = [
          ...payload,
          {
            ...formData,
            smarttags: [formData.smarttags],
            _id: generateNewId(),
          },
        ];
        setFormData({ ...payloadItem });
        if (onChange) {
          onChange(copy);
        }
      }
    } catch (e) {
      let m = `${__('Pri pridávaní okruhov sa vyskytla chyba')}`;
      m += ` ${getExceptionMessage(e)}`;
      setException({ message: m });
    }
  };

  const handleDeleteSmarttagClick = (pl) => {
    const copy = [...payload];
    // hladaj v poli podla generovaneho _id
    const idx = copy.findIndex((el) => el._id === pl._id);
    if (idx > -1) {
      copy.splice(idx, 1);
    }
    if (onChange) {
      onChange(copy);
    }
  };

  const renderSmarttagsToRow = (pl) => {
    if (pl.smarttags) {
      return [
        pl.smarttags.map((strow) => {
          return <SmarttagTags items={strow} />;
        }),
        pl.count,
        !editIsDisabled ? (
          <Button
            danger
            icon="close"
            onClick={() => {
              handleDeleteSmarttagClick(pl);
            }}
          />
        ) : null,
      ];
    }
    return ['', <strong>{`Celkom: ${totalQuestions}`}</strong>, ''];
  };

  const handleChangeFormData = async (name, data) => {
    switch (name) {
      case 'smarttags':
        setFormData((prev) => {
          return {
            ...prev,
            smarttags: [...data],
          };
        });
        setFormErrors({
          count: '',
          smarttags: '',
        });
        // request na pocet otazok
        if (data && data.length) {
          setFormIsDisabled(true);
          setMaxCountIsLoading(true);
          try {
            const response = await Api.validateQuestionGroups(appSpace, {
              smarttags: smarttagsToUrl(data),
            });
            setFormInfoMaxCount(response.count);
          } catch (e) {
            let m = `${__('Získavanie max. počtu otázok zlyhalo!')}`;
            m += ` ${getExceptionMessage(e)}`;
            setException({ message: m });
          } finally {
            setFormIsDisabled(false);
            setMaxCountIsLoading(false);
          }
        }
        break;
      case 'count':
        setFormErrors({
          smarttags: '',
          count: '',
        });
        setFormData((prev) => ({
          ...prev,
          count: Number(data),
        }));
        break;
      default:
    }
  };

  const handleCloseAlert = () => {
    setException({ message: null });
  };

  return (
    <>
      <AlertModal isOpen={!!exception.message} handleClose={handleCloseAlert}>
        {exception.message}
      </AlertModal>
      <Row>
        <Col>
          {payload.length === 0 ? (
            <>
              <BasicTable
                columns={[
                  { header: __('Smart:tag') },
                  { header: __('Počet') },
                  { header: '' },
                ]}
                rows={[]}
              />
              <Message primary title={__('Nie sú zvolené žiadne kritériá')}>
                Výberom SMART:TAG a zadaním počtu otázok zvolíte kritéria pre
                generovanie testu.
              </Message>
            </>
          ) : (
            <BasicTable
              columns={[
                { header: __('Smart:tag') },
                { header: __('Počet') },
                { header: '' },
              ]}
              rows={[...payload, { _id: generateNewId(), totalQuestions }]}
              renderRow={renderSmarttagsToRow}
              rowKey="_id"
            />
          )}
        </Col>
      </Row>
      {!editIsDisabled && (
        <>
          <Row>
            <Col m={12}>
              <Header size="s">{__('Nové kritérium')}</Header>
            </Col>
          </Row>
          <Row>
            <Col m={6}>
              <FormGroup>
                <TagmanagerConnector
                  appId={process.env.REACT_APP_APP_ID}
                  appspace={appSpace}
                  accessToken={accessToken}
                >
                  {(
                    getSmarttagsKeys,
                    getSmarttagsValues,
                    getSmarttagsByFts,
                  ) => (
                    <SmarttagControl
                      name="smarttags"
                      disabled={formIsDisabled}
                      placeholder={__('Okruhy otázok')}
                      getSmarttagsKeys={getSmarttagsKeys}
                      getSmarttagsValues={getSmarttagsValues}
                      getSmarttagsByFts={getSmarttagsByFts}
                      value={formData.smarttags}
                      onChange={(st) => handleChangeFormData('smarttags', st)}
                      error={formErrors.smarttags}
                    />
                  )}
                </TagmanagerConnector>
                {formErrors.smarttags && (
                  <ErrorText>
                    <Text danger>{formErrors.smarttags}</Text>
                  </ErrorText>
                )}
              </FormGroup>
            </Col>
            <Col m={5}>
              <FormGroup>
                <Input
                  type="number"
                  disabled={formIsDisabled}
                  min="0"
                  name="count"
                  placeholder={__('Počet')}
                  value={formData.count}
                  onChange={(el) =>
                    handleChangeFormData('count', el.target.value)
                  }
                  error={formErrors.count}
                >
                  <input />
                  <Input.Button>
                    {maxCountIsLoading ? (
                      <Loader size="xs" />
                    ) : (
                      <span>
                        Max.&nbsp;
                        {formData.smarttags.length ? formInfoMaxCount : 0}
                      </span>
                    )}
                  </Input.Button>
                </Input>
                {formErrors.count && (
                  <ErrorText>
                    <Text danger>{formErrors.count}</Text>
                  </ErrorText>
                )}
              </FormGroup>
            </Col>
            <Col m={1}>
              <Button
                success
                disabled={formIsDisabled}
                onClick={handleAddSmarttagsClick}
                icon="plus"
              >
                {__('Pridaj')}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col m={12}>
              {formData.smarttags && formData.smarttags.length > 0 && (
                <Message primary>
                  {vygenerujMudruVetu(formData.smarttags)}
                </Message>
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

QuestionsSettings.propTypes = {
  onChange: PropTypes.func,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      smarttags: PropTypes.arrayOf(PropTypes.shape({})),
      count: PropTypes.count,
    }),
  ).isRequired,
  totalQuestions: PropTypes.number.isRequired,
  editIsDisabled: PropTypes.bool,
};

QuestionsSettings.defaultProps = {
  onChange: null,
  editIsDisabled: false,
};

export default QuestionsSettings;
