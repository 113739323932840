import PropTypes from 'prop-types';
import * as React from 'react';
import { getProp } from '@sportnet/utilities';
import styled, { css } from 'styled-components';

const Wrapper = styled('div')`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  transition: background-color 0.3s ease-in-out;
  ${({ hovering }) =>
    hovering &&
    css`
      background-color: ${({ theme }) => theme.color.successBg};
    `};
`;

class Dropzone extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hovering: 0, // this is number because of how onDragEnter and onDragLeave works
    };
  }

  handleDragEnter = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ hovering: prevState.hovering + 1 }));
  };

  handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragLeave = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ hovering: prevState.hovering - 1 }));
  };

  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ hovering: 0 });

    const droppedFiles = e.dataTransfer
      ? e.dataTransfer.files
      : getProp(e, ['target', 'files'], []);
    const files = [];
    Array.from(droppedFiles).forEach((file) => {
      if (file.type) {
        files.push(file);
      }
    });

    this.props.onDropFiles(files);
  };

  render() {
    const { children } = this.props;
    const { hovering } = this.state;
    return (
      <Wrapper
        onDragEnter={this.handleDragEnter}
        onDragOver={this.handleDragOver}
        onDragLeave={this.handleDragLeave}
        onDrop={this.handleDrop}
        hovering={hovering}
      >
        {children}
      </Wrapper>
    );
  }
}

Dropzone.propTypes = {
  children: PropTypes.node.isRequired,
  onDropFiles: PropTypes.func.isRequired,
};

export default Dropzone;
