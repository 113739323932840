import { useState } from 'react';
import MediaManagerConnector from '@sportnet/media-manager-connector';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import Button from '@sportnet/ui/Button';
import { __ } from '../../utils';
import Input from '@sportnet/ui/Input';
import styled from 'styled-components';
import { rem } from 'polished';
import Header from '@sportnet/ui/Header';
import { useAuth } from '@sportnet/auth-react';

const Actions = styled.div`
  display: flex;
  > * {
    margin-left: ${rem(8)};
  }
`;

const MandatoryVideo = ({ isOpen, handleClose, video, onVideoChange }) => {
  const [mediaManagerOpened, setMediaManagerOpened] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState(
    video ? video.public_url || '' : '',
  );
  const [typedVideoUrl, setTypedVideoUrl] = useState('');
  const { appSpace, ppo: accessToken } = useAuth();
  const onSelecteFileFromMM = ([file] = []) => {
    setMediaManagerOpened(false);
    if (file.mimetype !== 'video/mp4') {
      alert(__('Prosím zvoľte video súbor vo formáte mp4'));
    } else {
      onChangeVideoUrl(file.public_url);
    }
  };
  const onChangeVideoUrl = (url) => {
    setTypedVideoUrl(url);
    setCurrentVideoUrl('');
    setTimeout(() => {
      setTypedVideoUrl('');
      setCurrentVideoUrl(url);
    }, 500);
  };
  return (
    <>
      <Modal isOpen={isOpen} handleClose={handleClose}>
        <ModalContent>
          <Header size="s">{__('Povinné video')}</Header>
          <Input
            placeholder={__(
              'Zadajte kód z video aplikácie, alebo zvoľte z mediamanagera',
            )}
            value={typedVideoUrl || currentVideoUrl}
            onChange={(e) => {
              const url = e.target.value;
              // parsujeme zadanu URL. Ak je spravna, nastavime URL videa. Ak nie, nerobim nic.
              setTypedVideoUrl(url);
              try {
                const parsedUrl = new URL(url);
                if (
                  parsedUrl.protocol.startsWith('http') ||
                  parsedUrl.protocol.startsWith('ftp')
                ) {
                  // odchytime video.sportnet.online
                  const matcher =
                    parsedUrl.pathname.match(/^\/embed\/(.+)\/?$/);
                  if (
                    parsedUrl.hostname === 'video.sportnet.online' &&
                    matcher
                  ) {
                    onChangeVideoUrl(
                      `https://video.sportnet.online/mp4/${matcher[1]}`,
                    );
                  } else {
                    onChangeVideoUrl(parsedUrl.href);
                  }
                }
              } catch (e) {}
            }}
          >
            <input />
            <Input.Button
              primary
              onClick={() => {
                setMediaManagerOpened(true);
              }}
            >
              {__('📂 Mediamanager')}
            </Input.Button>
            <Input.Button
              primary
              onClick={() => {
                window.open(
                  `https://video.sportnet.online/admin/${appSpace}/videos`,
                );
              }}
            >
              {__('🎥 Video')}
            </Input.Button>
          </Input>
          <br />
          {currentVideoUrl && (
            <video controls width="100%" style={{ maxHeight: '450px' }}>
              <source type="video/mp4" src={currentVideoUrl}></source>
            </video>
          )}
        </ModalContent>
        <ModalActions>
          <Button primary basic onClick={handleClose}>
            {__('Zrušiť')}
          </Button>
          <Actions>
            {video && (
              <Button
                basic
                danger
                icon="trash"
                onClick={() => {
                  onVideoChange(null);
                }}
              >
                {__('Odstrániť povinné video')}
              </Button>
            )}
            <Button
              primary
              onClick={() => {
                onVideoChange(
                  currentVideoUrl ? { public_url: currentVideoUrl } : null,
                );
              }}
            >
              {__('OK')}
            </Button>
          </Actions>
        </ModalActions>
      </Modal>
      <MediaManagerConnector
        opened={mediaManagerOpened}
        appSpace={appSpace}
        appId={process.env.REACT_APP_APP_ID}
        accessToken={accessToken}
        multiple={false}
        onClose={() => {
          setMediaManagerOpened(false);
        }}
        onError={(e) => {
          setMediaManagerOpened(false);
          throw e;
        }}
        onUnauthorized={() => {
          setMediaManagerOpened(false);
          throw Error('Media manager unauthorized');
        }}
        onSelectFiles={onSelecteFileFromMM}
      />
    </>
  );
};

export default MandatoryVideo;
