import SportnetApi from '../../SportnetApi';
import { RECEIVE_USERSPACE_USERS_SUCCESS } from './constants';

export function receiveUserspaceUsersSuccess(userspaceUsers) {
  return {
    type: RECEIVE_USERSPACE_USERS_SUCCESS,
    payload: {
      userspaceUsers,
    },
  };
}

export const loadAppSpaceUsers = userspaceId => {
  return async dispatch => {
    try {
      const { users } = await SportnetApi.appgrantGetRoles(
        process.env.REACT_APP_APP_ID,
        userspaceId,
      );
      dispatch(receiveUserspaceUsersSuccess(users));
    } catch (e) {
      console.error(e);
    }
  };
};
