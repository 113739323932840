import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import HeaderBar from '@sportnet/ui/HeaderBar';
import { ContentLoader, Loader } from '@sportnet/ui/Loader';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import Segment from '@sportnet/ui/Segment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { submit } from 'redux-form';
import { getProp } from '@sportnet/utilities';
import API from '../../API';
import Layout from '../../components/Layout';
import SeminarMenu from '../../components/SeminarMenu';
import { __ } from '../../utils';
import { userspaceUsersSelector } from '../Administration/selectors';
import {
  aclSelector,
  activeAppSpaceSelector,
  appInfoSelector,
  authUserSelector,
} from '../Authorization/selectors';
import SeminarForm from './seminarForm';
import PartForm from './partForm';
import withSportnetAuth from '../../hoc/withSportnetAuth';
import { compose } from 'redux';

export const FORM_NAME = 'SEMINAR_FORM';
export const PART_FORM_NAME = 'SEMINAR_PART_FORM';

class Seminars extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isFetchingPart: false,
      isCopyingSeminar: false,
      isSubmitting: false,
      data: null,
      disabled: false,
      activePart: null,
      partModalOpened: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.seminar_id !== this.props.match.params.seminar_id
    ) {
      this.fetchData();
    }
  }

  fetchData = async () => {
    const {
      params: { seminar_id: seminarId },
    } = this.props.match;
    const { ppo: appSpace } = this.props.auth;
    if (seminarId) {
      try {
        this.setState({
          isFetching: true,
        });
        const { canUpdateSeminar, ...data } = await API.seminarDetail(
          appSpace,
          seminarId,
        );
        this.setState({
          data,
          disabled: !canUpdateSeminar,
        });
      } catch (e) {
        alert(__('Nepodarilo sa získať detail seminára'));
      } finally {
        this.setState({
          isFetching: false,
        });
      }
    } else {
      this.setState({
        data: {},
      });
    }
  };

  copySeminar = async () => {
    if (window.confirm(__('Skutočne si želáte skopírovať seminár?'))) {
      try {
        const {
          params: { seminar_id: seminarId },
        } = this.props.match;
        const { ppo: appSpace } = this.props.auth;
        this.setState({
          isCopyingSeminar: true,
        });
        const { _id } = await API.seminarCopy(appSpace, seminarId);
        this.props.history.push(`/userspace/${appSpace}/seminar/${_id}`);
      } catch (e) {
        alert(__('Seminár sa nepodarilo skopírovať'));
      } finally {
        this.setState({
          isCopyingSeminar: false,
        });
      }
    }
  };

  deleteSeminar = async () => {
    const {
      params: { seminar_id: seminarId },
    } = this.props.match;
    const { ppo: appSpace } = this.props.auth;
    if (window.confirm(__('Skutočne si želáte zmazať seminár?'))) {
      try {
        this.setState({
          isRemoving: true,
        });
        await API.seminarDelete(appSpace, seminarId);
        this.props.history.push(`/userspace/${appSpace}/seminars`);
      } catch (e) {
        alert(__('Seminár sa nepodarilo odstrániť'));
      } finally {
        this.setState({
          isRemoving: false,
        });
      }
    }
  };

  handleSubmit = async (values) => {
    const {
      params: { seminar_id: seminarId },
    } = this.props.match;
    const { ppo: appSpace } = this.props.auth;
    const { name, content = [], apiKey } = values;
    const submitData = { name, content, apiKey, picture: null };

    if (apiKey === '') {
      submitData.apiKey = null;
    }

    if (values.picture) {
      submitData.picture = values.picture;
    }
    try {
      this.setState({
        isSubmitting: true,
      });
      if (seminarId) {
        await API.seminarUpdate(appSpace, seminarId, {
          body: submitData,
        });
      } else {
        const res = await API.seminarCreate(appSpace, { body: submitData });
        this.props.history.push(`/userspace/${appSpace}/seminar/${res._id}`);
      }
    } catch (e) {
      alert(`Chyba: ${e.details ? e.details.name : e.name}`);
    } finally {
      this.setState({
        isSubmitting: false,
      });
    }
  };

  handleSubmitPart = async (values) => {
    const {
      params: { seminar_id: seminarId },
    } = this.props.match;
    const { ppo: appSpace } = this.props.auth;
    const { activePart } = this.state;

    const transformedValues = {
      name: values.name,
      group: values.group ?? '',
      content: values.content || [],
      partDependencies: values.partDependencies || [],
      testTemplates: (values.testTemplates || []).map((templateId) => {
        if (typeof templateId === 'string') {
          return {
            _id: templateId,
          };
        }
        return { _id: templateId._id };
      }),
      mandatoryVideo: values.mandatoryVideo,
    };
    try {
      this.setState({ isSubmittingPart: true });
      if (this.state.activePart) {
        await API.seminarPartUpdate(appSpace, seminarId, activePart._id, {
          body: transformedValues,
        });
      } else {
        await API.seminarPartCreate(appSpace, seminarId, {
          body: transformedValues,
        });
      }
      this.setState({
        partModalOpened: false,
      });
      await this.fetchData();
    } catch (e) {
      alert(__('Nepodarilo sa uložiť časť seminára'));
    } finally {
      this.setState({ isSubmittingPart: false });
    }
  };

  handleDeletePart = async () => {
    if (!window.confirm(__(`Odstrániť časť semináru?`))) {
      return;
    }
    const {
      params: { seminar_id: seminarId },
    } = this.props.match;
    const { ppo: appSpace } = this.props.auth;
    const { activePart } = this.state;
    try {
      this.setState({ isDeletingPart: true });
      if (this.state.activePart) {
        await API.seminarPartDelete(appSpace, seminarId, activePart._id);
      }
      this.setState({
        partModalOpened: false,
      });
      await this.fetchData();
    } catch (e) {
      alert(__('Nepodarilo sa odstrániť časť seminára'));
    } finally {
      this.setState({ isDeletingPart: false });
    }
  };

  closePartModal = () => {
    this.setState({
      partModalOpened: false,
      activePart: null,
    });
  };

  setActivePart = async (partId) => {
    const {
      params: { seminar_id: seminarId },
    } = this.props.match;
    const { ppo: appSpace } = this.props.auth;
    try {
      this.setState({ isFetchingPart: true });
      const res = await API.seminarPartDetail(appSpace, seminarId, partId);
      this.setState({
        activePart: res,
        partModalOpened: true,
      });
    } catch (e) {
      alert(__('Nepodarilo sa získať detail časti seminára'));
    } finally {
      this.setState({ isFetchingPart: false });
    }
  };

  render() {
    const {
      params: { seminar_id: seminarId },
    } = this.props.match;
    const { ppo: appSpace } = this.props.auth;

    return (
      <Layout
        topFixed={
          <HeaderBar>
            <HeaderBar.Action
              icon="back"
              as={Link}
              to={`/userspace/${this.props.match.params.userspace_id}/seminars`}
              title={__('Semináre')}
            />
            <HeaderBar.Header>
              {seminarId
                ? `${__('Úprava seminára')}: ${getProp(
                    this.state.data || {},
                    ['name'],
                    seminarId,
                  )}`
                : `${__('Nový seminár')}`}
            </HeaderBar.Header>
          </HeaderBar>
        }
        bottomFixed={
          <ContextBar>
            <ContextBarItem>
              {this.state.disabled &&
                __('Seminár nie je možné upraviť, pretože už je spustený')}
            </ContextBarItem>
            <ContextBarSpacer />
            <ContextBarItem>
              {!!this.state.data && seminarId && (
                <>
                  <Button
                    basic
                    loading={this.state.isCopyingSeminar}
                    onClick={this.copySeminar}
                  >
                    {__('Skopírovať seminár')}
                  </Button>
                  &nbsp;
                </>
              )}
              {!!this.state.data && !this.state.disabled && (
                <>
                  {seminarId && (
                    <>
                      <Button
                        basic
                        onClick={() => {
                          this.setState({
                            partModalOpened: true,
                          });
                        }}
                      >
                        {__('Pridať časť')}
                      </Button>
                      &nbsp;
                      <Button
                        loading={this.state.isRemoving}
                        danger
                        onClick={this.deleteSeminar}
                      >
                        {__('Odstrániť')}
                      </Button>
                      &nbsp;
                    </>
                  )}
                  <Button
                    primary
                    loading={this.state.isSubmitting}
                    onClick={() => {
                      this.props.dispatch(submit(FORM_NAME));
                    }}
                  >
                    {seminarId ? __('Uložiť') : __('Vytvoriť')}
                  </Button>
                </>
              )}
            </ContextBarItem>
          </ContextBar>
        }
      >
        <SeminarMenu active={seminarId ? 'Úprava seminára' : 'Nový seminár'} />
        <Modal
          handleClose={this.closePartModal}
          isOpen={this.state.partModalOpened}
        >
          <ModalContent>
            {this.state.isFetchingPart ? (
              <Loader size="m" />
            ) : (
              <>
                {this.state.partModalOpened && (
                  <PartForm
                    form={PART_FORM_NAME}
                    onSubmit={this.handleSubmitPart}
                    initialValues={this.state.activePart}
                    org={appSpace}
                    parts={getProp(this.state, ['data', 'parts'], [])}
                  />
                )}
              </>
            )}
          </ModalContent>
          <ModalActions>
            <Button
              onClick={this.closePartModal}
              disabled={
                this.state.isSubmittingPart || this.state.isDeletingPart
              }
            >
              {__('Zavrieť')}
            </Button>
            <div>
              <Button
                danger
                loading={this.state.isDeletingPart}
                disabled={
                  this.state.isSubmittingPart || this.state.isDeletingPart
                }
                onClick={this.handleDeletePart}
              >
                {__('Odstrániť')}
              </Button>
              &nbsp;
              <Button
                primary
                loading={this.state.isSubmittingPart}
                disabled={
                  this.state.isSubmittingPart || this.state.isDeletingPart
                }
                onClick={() => {
                  this.props.dispatch(submit(PART_FORM_NAME));
                }}
              >
                {__('Uložiť')}
              </Button>
            </div>
          </ModalActions>
        </Modal>
        <Segment>
          {this.state.isFetching ? (
            <ContentLoader size="xl" />
          ) : (
            <>
              {this.state.data && (
                <SeminarForm
                  form={FORM_NAME}
                  disabled={this.state.disabled}
                  initialValues={this.state.data}
                  onSubmit={this.handleSubmit}
                  appSpace={appSpace}
                  seminarId={seminarId}
                  setActivePart={this.setActivePart}
                />
              )}
            </>
          )}
        </Segment>
      </Layout>
    );
  }
}

Seminars.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userspace_id: PropTypes.string.isRequired,
      seminar_id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  authUser: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
  appInfo: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    authUser: authUserSelector(state),
    userspace: activeAppSpaceSelector(state),
    userspaceUsers: userspaceUsersSelector(state),
    appInfo: appInfoSelector(state),
    acl: aclSelector(state),
  };
};

export default compose(
  withRouter,
  withSportnetAuth,
  connect(mapStateToProps),
)(Seminars);
