import styled from 'styled-components';
import { rem } from 'polished';

export const ErrorText = styled.div`
  font-size: ${rem(11)};
  margin-top: ${rem(3)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;
