import Button from '@sportnet/ui/Button';
import Segment from '@sportnet/ui/Segment';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import FormField from '@sportnet/ui/FormField';
import { getProp } from '@sportnet/utilities';
import PropTypes from 'prop-types';
import { __ } from '../../utils';
import API from '../../API';

const SidebarButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const UserForm = ({ user, onRemove, onSave }) => {
  const [editingUser, setEditingUser] = useState(user);

  const changeEditingUserSettings = (setting, value) => {
    setEditingUser((current) => {
      if (setting === 'maxRetries') {
        return {
          ...current,
          settings: {
            ...getProp(editingUser, ['settings'], {}),
            maxRetries: Number(value) || null,
          },
        };
      }
      if (setting === 'validityDays') {
        return {
          ...current,
          settings: {
            ...getProp(editingUser, ['settings'], {}),
            validity: {
              ...getProp(editingUser, ['settings', 'validity'], {}),
              days: Number(value) || null,
            },
          },
        };
      }
      if (setting === 'validityFrom') {
        return {
          ...current,
          settings: {
            ...getProp(editingUser, ['settings'], {}),
            validity: {
              ...getProp(editingUser, ['settings', 'validity'], {}),
              from: value ? value.toDate() : null,
            },
          },
        };
      }
      if (setting === 'validityTo') {
        return {
          ...current,
          settings: {
            ...getProp(editingUser, ['settings'], {}),
            validity: {
              ...getProp(editingUser, ['settings', 'validity'], {}),
              to: value ? value.toDate() : null,
            },
          },
        };
      }
      return current;
    });
  };

  const editingUserSettings = useMemo(() => {
    return editingUser ? editingUser.settings || {} : {};
  }, [editingUser]);

  const [isSaving, setIsSaving] = useState(false);
  const saveEditingUserSettings = () => {
    if (!editingUser) {
      return;
    }
    setIsSaving(true);
    API.templateUpdateUser(
      user.userspace,
      user.templateId,
      user.user.sportnetId,
      {
        body: {
          maxRetries: editingUserSettings.maxRetries || null,
          validity: {
            from: getProp(editingUserSettings, ['validity', 'from'], null),
            to: getProp(editingUserSettings, ['validity', 'to'], null),
            days: getProp(editingUserSettings, ['validity', 'days'], null),
          },
        },
      },
    )
      .then(() => {
        if (onSave) {
          onSave(editingUser);
        }
      })
      .catch((e) => {
        alert(
          `Pri ukladaní parametrov používateľa nastala chyba: ${
            e.details ? e.details.name : e.name
          }`,
        );
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const [isRemoving, setIsRemoving] = useState(false);
  const removeUser = () => {
    if (!editingUser) {
      return;
    }
    if (
      !window.confirm(
        __(
          'Prajete si odstrániť používateľa? Testy, ktoré už riešil ostanú zachované.',
        ),
      )
    ) {
      return;
    }
    setIsRemoving(true);
    API.templateDeleteUser(
      user.userspace,
      user.templateId,
      `${user.user.sportnetId}`,
    )
      .then(() => {
        if (onRemove) {
          onRemove(editingUser);
        }
      })
      .catch((e) => {
        alert(
          `Pri odstraňovaní používateľa nastala chyba: ${
            e.details ? e.details.name : e
          }`,
        );
      })
      .finally(() => {
        setIsRemoving(false);
      });
  };

  return (
    <Segment>
      <FormField
        label={__('Počet opakovaní')}
        info={__('Maximálny počet opakovaní testu na používateľa')}
        name="maxRetries"
        type="text"
        value={editingUserSettings.maxRetries || ''}
        onChange={(e) => {
          changeEditingUserSettings(e.target.name, e.target.value);
        }}
        placeholder={__('neobmedzene')}
        disabled={isSaving || isRemoving}
      />
      <FormField
        label={__('Dostupnosť testu v dňoch')}
        info={__('Počet dní dostupnosti testu na riešenie od prvého spustenia')}
        name="validityDays"
        type="text"
        value={
          editingUserSettings.validity
            ? editingUserSettings.validity.days || ''
            : ''
        }
        onChange={(e) => {
          changeEditingUserSettings(e.target.name, e.target.value);
        }}
        placeholder={__('neobmedzene')}
        disabled={isSaving || isRemoving}
      />
      <FormField
        label={__('Platnosť testu od')}
        name="validityFrom"
        type="dateTime"
        value={
          editingUserSettings.validity && editingUserSettings.validity.from
            ? new Date(editingUserSettings.validity.from)
            : null
        }
        onChange={(dt) => changeEditingUserSettings('validityFrom', dt)}
        placeholder={__('bez obmedzenia')}
        disabled={isSaving || isRemoving}
      />
      <FormField
        label={__('Platnosť testu do')}
        name="validityTo"
        type="dateTime"
        value={
          editingUserSettings.validity && editingUserSettings.validity.to
            ? new Date(editingUserSettings.validity.to)
            : null
        }
        onChange={(dt) => changeEditingUserSettings('validityTo', dt)}
        placeholder={__('bez obmedzenia')}
        disabled={isSaving || isRemoving}
      />
      <SidebarButtons>
        <Button
          danger
          onClick={removeUser}
          disabled={isSaving || isRemoving}
          loading={isRemoving}
        >
          {__('Odstrániť')}
        </Button>
        <Button
          primary
          onClick={saveEditingUserSettings}
          disabled={isSaving || isRemoving}
          loading={isSaving}
        >
          {__('Uložiť')}
        </Button>
      </SidebarButtons>
    </Segment>
  );
};

UserForm.propTypes = {
  user: PropTypes.shape({
    userspace: PropTypes.string.isRequired,
    templateId: PropTypes.string.isRequired,
    user: PropTypes.shape({
      sportnetId: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    }).isRequired,
    settings: PropTypes.shape(),
  }).isRequired,
  onRemove: PropTypes.func,
  onSave: PropTypes.func,
};

UserForm.defaultProps = {
  onRemove: null,
  onSave: null,
};

export default UserForm;
