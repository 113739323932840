import Button from '@sportnet/ui/Button';
import { ContextBar, ContextBarItem } from '@sportnet/ui/ContextBar';
import Header from '@sportnet/ui/Header';
import HeaderBar from '@sportnet/ui/HeaderBar';
import { ContentLoader } from '@sportnet/ui/Loader';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import NotFound from '@sportnet/ui/NotFound';
import Paginator from '@sportnet/ui/Paginator';
import Segment from '@sportnet/ui/Segment';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatUserName, getProp } from '@sportnet/utilities';
import styled, { css } from 'styled-components';
import API from '../../API';
import Layout from '../../components/Layout';
import TestMenu from '../../components/TestMenu';
import TestUsersTable from '../../components/TestUsersTable';
import { __, getDurationInMinutes } from '../../utils';
import { activeAppSpaceSelector } from '../Authorization/selectors';

const NumberOfTests = styled.span`
  font-weight: bold;
  ${(props) => {
    return css`
      color: ${props.color};
    `;
  }};
`;

const LabelsWrapper = styled.div`
  display: flex;
  max-width: ${rem(350)};
  margin: 0;
`;

const Label = styled.div`
  margin: 0 ${rem(5)};
  color: ${({ color }) => color};
`;

class TestByUsersList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      users: [],
      offset: 0,
      total: 0,
      detail: false,
      tests: [],
    };
    this.limit = 100;
    this.redColor = '#b90000';
    this.yellowColor = '#b9a500';
    this.greenColor = '#48b900';
    this.testDetail = this.testDetail.bind(this);
    this.handleChangeOffset = this.handleChangeOffset.bind(this);
    this.handleShowDetail = this.handleShowDetail.bind(this);
    this.handleHideDetail = this.handleHideDetail.bind(this);
  }
  componentDidMount() {
    this.getListByUsers();
    this.getTestTemplate();
  }

  getListByUsers(offset = 0) {
    const { params } = this.props.match;
    API.adminTestListByUsers(params.userspace_id, params.test_id, {
      offset,
      limit: this.limit,
    })
      .then((res) => {
        this.setState({
          isFetching: false,
          users: res.users,
          offset: res.offset,
          total: res.total,
        });
      })
      .catch((e) => {
        console.error(e);
      });
  }

  getTestTemplate() {
    const { params } = this.props.match;
    API.templateDetail(params.userspace_id, params.test_id)
      .then((res) => {
        this.setState({
          testDetail: res,
        });
      })
      .catch((e) => {
        console.error(e);
      });
  }
  getTestDuration(test) {
    if (test.userFinishAt) {
      return `${getDurationInMinutes(
        new Date(test.userFinishAt),
        new Date(test.startAt),
      )} / ${test.limit / 60}`;
    }
    return `${getDurationInMinutes(
      new Date(test.expiresAt),
      new Date(test.startAt),
    )} / ${test.limit / 60}`;
  }
  getTotal() {
    const { total } = this.state;
    const { limit } = this;
    let page = total / limit;
    if (page % 1 !== 0) {
      page = Math.ceil(page);
    }
    return page;
  }
  handleChangeOffset(offset) {
    this.getListByUsers(offset);
  }
  handleShowDetail(tests) {
    this.setState({
      detail: true,
      tests,
    });
  }

  handleHideDetail() {
    this.setState({
      detail: false,
      tests: [],
    });
  }

  testDetail(test) {
    const { history } = this.props;
    const { params } = this.props.match;
    history.push(
      `/userspace/${params.userspace_id}/template/${test.templateId}/test/${test._id}`,
    );
  }
  render() {
    const bottomFixed = (
      <ContextBar>
        <ContextBarItem>
          <Paginator
            total={this.state.total}
            limit={this.limit}
            offset={this.state.offset}
            onChangeOffset={this.handleChangeOffset}
            step={this.limit}
          />
        </ContextBarItem>
      </ContextBar>
    );

    return (
      <Layout
        topFixed={
          <HeaderBar>
            <HeaderBar.Action
              icon="back"
              as={Link}
              to={`/userspace/${this.props.match.params.userspace_id}/templates`}
              title={__('Testy')}
            />
            <HeaderBar.Header>
              {this.props.match.params.test_id
                ? `${__('Šablóna testu')}: ${getProp(
                    this.state,
                    ['testDetail', 'name'],
                    this.props.match.params.test_id,
                  )}`
                : `${__('Nová šablóna testu')}`}
            </HeaderBar.Header>
          </HeaderBar>
        }
        bottomFixed={bottomFixed}
      >
        <TestMenu
          active="Zoznam riešiteľov"
          isPrivate={
            !!this.props.match.params.test_id &&
            getProp(this.state, ['testDetail', 'private'], false)
          }
        />
        <Segment noBottomGutter>
          {this.state.isFetching ? (
            <ContentLoader size="xl" />
          ) : this.state.total === 0 ? (
            <NotFound
              icon="file"
              title={__('Vybraný test nemá žiadnych riešiteľov')}
            />
          ) : (
            <>
              <Segment raised>
                <Table>
                  <Thead>
                    <Tr center>
                      <Th />
                      <Th>
                        <LabelsWrapper>
                          <Label color={this.greenColor}>{__('Úspešné')}</Label>
                          /
                          <Label color={this.redColor}>{__('Neúspešné')}</Label>
                          /
                          <Label color={this.yellowColor}>
                            {__('Nevyhodnotené')}
                          </Label>
                        </LabelsWrapper>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {this.state.users.map((user) => {
                      return (
                        <Tr
                          key={user.user.sportnet_id}
                          onClick={() => {
                            this.handleShowDetail(user.tests);
                          }}
                        >
                          <Td>
                            <span>{user.user.display_name}</span>
                          </Td>
                          <Td>
                            <NumberOfTests color={this.greenColor}>
                              {user.nrofPassed}
                            </NumberOfTests>
                            <span> / </span>
                            <NumberOfTests color={this.redColor}>
                              {user.nrofFailed}
                            </NumberOfTests>
                            <span> / </span>
                            <NumberOfTests color={this.yellowColor}>
                              {user.nrofNotEvaluated}
                            </NumberOfTests>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </Segment>
              {this.state.detail && (
                <Modal handleClose={this.handleHideDetail} isOpen>
                  <ModalContent>
                    <Header size="s">
                      {formatUserName(this.state.tests[0].user)}
                    </Header>
                    <TestUsersTable
                      tests={this.state.tests}
                      testDetail={this.testDetail}
                    />
                  </ModalContent>
                  <ModalActions>
                    <Button onClick={this.handleHideDetail}>
                      {__('Zavrieť')}
                    </Button>
                  </ModalActions>
                </Modal>
              )}
            </>
          )}
        </Segment>
      </Layout>
    );
  }
}

TestByUsersList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userspace_id: PropTypes.string,
      test_id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  userspace: PropTypes.shape({
    org_profile: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    userspace: activeAppSpaceSelector(state),
  };
};

export default connect(mapStateToProps)(TestByUsersList);
