import BasicTable from '@sportnet/ui/BasicTable';
import FormField from '@sportnet/ui/FormField/redux-form';
import FormGroup from '@sportnet/ui/FormGroup';
import Col, { Row } from '@sportnet/ui/Grid';
import Label from '@sportnet/ui/Label/Label';
import Editor from '@sportnet/content/editor';
import ContentView from '../../components/Content/view';
import defaultWidgets from '@sportnet/content/editor/defaultWidgets';
import { theme as defaultContentTheme } from '@sportnet/content/theme/theme';
import Input from '@sportnet/ui/Input';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, Field, reduxForm } from 'redux-form';
import { getProp } from '@sportnet/utilities';
import { ThemeProvider } from 'styled-components';
import { required, validateApiKey, __ } from '../../utils';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import API from '../../API';
import { appInfoSelector } from '../Authorization/selectors';
import styled from 'styled-components';
import { rem } from 'polished';
import withSportnetAuth from '../../hoc/withSportnetAuth';

const Dependency = styled.div`
  padding: ${rem(4)} ${rem(12)};
  margin: ${rem(4)};
  background: #5283e7;
  color: #fff;
  border-radius: ${rem(8)};
  display: inline-block;
`;

const availableWidgets = [
  'imagead',
  'document',
  'externalArticle',
  'photogallery',
  'photo',
  'multiblock',
  'table',
  'social',
  'video',
  'faq',
  'hr',
  'iframe',
  'banners',
  'textBlock',
];

class SeminarForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      copied: {
        callbackUrl: false,
        apiKey: false,
      },
    };
  }

  copyInputValue(inputId) {
    const value = document.querySelector(`#${inputId}`).value;

    if (value) {
      navigator.clipboard.writeText(value);

      this.setState((prev) => ({
        copied: {
          ...prev.copied,
          [inputId]: true,
        },
      }));
      setTimeout(() => {
        this.setState((prev) => ({
          copied: {
            ...prev.copied,
            [inputId]: false,
          },
        }));
      }, 1000);
    }
  }

  generateApiKey() {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const idLength = 32;

    let id = '';
    const charactersLength = characters.length;
    for (let i = 0; i < idLength; i++) {
      id += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    document.querySelector(`#apiKey`).value = id;
    this.props.dispatch(change(this.props.form, 'apiKey', id));
  }

  reorderParts = async (parts) => {
    const { seminarId } = this.props;
    const { ppo: appSpace } = this.props.auth;
    try {
      this.setState({
        isReordering: true,
      });
      await API.seminarPartsReorder(appSpace, seminarId, {
        body: { partIds: parts.map((p) => p._id) },
      });
    } catch (e) {
      if (e.details && e.details.name === 'UPDATE_FORBIDDEN') {
        alert(
          __(
            'V prípade, ak existuje už spustená inštancia semináru, nie je možné seminár meniť.',
          ),
        );
      } else {
        alert(__('Nepodarilo sa zoradiť časti seminára'));
      }
    } finally {
      this.setState({
        isReordering: false,
      });
    }
  };

  render() {
    const { appInfo, disabled, seminarId } = this.props;
    const { ppo: appSpace, accessToken } = this.props.auth;
    const { copied } = this.state;
    const appSetup = {
      appId: appInfo._id,
      appSpace,
      token: accessToken,
    };
    return (
      <>
        <Segment
          raised
          header={
            <SegmentHeader withSeparator size="s">
              {__('Základné nastavenia')}
            </SegmentHeader>
          }
        >
          <Row>
            <Col xs={12}>
              <Field
                label={__('Názov seminára')}
                component={FormField}
                name="name"
                required
                validate={[required]}
                disabled={disabled}
              />
            </Col>
            <Col xs={12}>
              <Field
                component={FormField}
                label={__('Náhľadový obrázok')}
                type="image"
                name="picture"
                auth={appSetup}
                disabled={disabled}
              />
            </Col>
            <Col xs={12}>
              <ThemeProvider theme={defaultContentTheme}>
                <FormGroup>
                  <Label>{__('Popis')}</Label>
                  {disabled ? (
                    <ContentView
                      items={getProp(
                        this.props,
                        ['initialValues', 'content'],
                        [],
                      )}
                    />
                  ) : (
                    <Editor
                      appId={process.env.REACT_APP_APP_ID}
                      appSpace={appSpace}
                      contentDivider="default"
                      token={Cookies.get('access_token')}
                      initialValue={getProp(
                        this.props,
                        ['initialValues', 'content'],
                        [],
                      )}
                      availableWidgets={defaultWidgets.filter((i) =>
                        availableWidgets.includes(i.type),
                      )}
                      onChange={(value) => {
                        this.props.dispatch(
                          change(this.props.form, 'content', value),
                        );
                      }}
                    />
                  )}
                </FormGroup>
              </ThemeProvider>
            </Col>
          </Row>
        </Segment>

        {seminarId && (
          <Segment
            raised
            loading={this.state.isReordering}
            header={
              <SegmentHeader withSeparator size="s">
                {__('Časti seminára')}
              </SegmentHeader>
            }
          >
            <BasicTable
              columns={[
                { header: __('Názov') },
                { header: __('Závislosti') },
                { header: __('Podmienky') },
              ]}
              rows={getProp(this.props.initialValues || {}, ['parts'], [])}
              renderRow={(part) => {
                const dependencies = getProp(
                  this.props.initialValues || {},
                  ['parts'],
                  [],
                ).filter((i) => (part.partDependencies || []).includes(i._id));

                return [
                  <>
                    <Dependency>{part.nr}</Dependency>
                    {!!part.group && (
                      <>
                        <strong>[{part.group}]</strong>{' '}
                      </>
                    )}
                    {part.name}
                  </>,
                  <div>
                    {dependencies.map((i) => (
                      <Dependency key={i._id}>{i.name}</Dependency>
                    ))}
                  </div>,
                  <div>
                    {part.mandatoryVideo && (
                      <Dependency>{__('Povinné video')}</Dependency>
                    )}
                    {(part.testTemplates || []).map((i) => (
                      <Dependency
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!disabled) {
                            window.open(
                              `/userspace/${appSpace}/template/${i._id}`,
                            );
                          }
                        }}
                        key={i._id}
                      >
                        {i.name}
                      </Dependency>
                    ))}
                  </div>,
                ];
              }}
              {...(!disabled
                ? {
                    onReorder: this.reorderParts,
                    onClickRow: (part) => {
                      this.props.setActivePart(part._id);
                    },
                  }
                : {})}
              rowKey="_id"
            />
          </Segment>
        )}

        {seminarId && (
          <Segment
            raised
            header={
              <SegmentHeader withSeparator size="s">
                {__('Prepojenie s externou aplikáciou')}
              </SegmentHeader>
            }
          >
            <Row>
              <Col xs={12}>
                <FormGroup>
                  <Label>{__('Callback URL')}</Label>
                  <Input
                    component={FormField}
                    name={'callbackUrl'}
                    id="callbackUrl"
                    value={`${API.baseUrl}/app/${appSpace}/seminar-ticket/${seminarId}`}
                    readOnly
                  >
                    <input />
                    <Input.Button
                      type="button"
                      onClick={() => {
                        this.copyInputValue('callbackUrl');
                      }}
                      {...(copied.callbackUrl
                        ? {
                            success: true,
                            icon: 'check',
                          }
                        : { icon: 'files' })}
                    ></Input.Button>
                  </Input>
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup>
                  <Field
                    name={'apiKey'}
                    counter={{ words: false, characters: true }}
                    component={FormField}
                    id="apiKey"
                    label={__('Api Key (32 - 64 znakov)')}
                    validate={[validateApiKey]}
                    onChange={(e) => {
                      this.props.dispatch(
                        change(this.props.form, 'apiKey', 'abcd'),
                      );
                    }}
                  >
                    <input />
                    <Input.Button
                      type="button"
                      primary
                      basic
                      onClick={() => {
                        this.generateApiKey();
                      }}
                    >
                      Generovať
                    </Input.Button>
                    <Input.Button
                      type="button"
                      onClick={(e) => {
                        this.copyInputValue('apiKey');
                      }}
                      {...(copied.apiKey
                        ? {
                            success: true,
                            icon: 'check',
                          }
                        : { icon: 'files' })}
                    ></Input.Button>
                  </Field>
                </FormGroup>
              </Col>
            </Row>
          </Segment>
        )}
      </>
    );
  }
}

SeminarForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  seminarId: PropTypes.string.isRequired,
  setActivePart: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  appInfo: appInfoSelector(state),
});

export default compose(
  withSportnetAuth,
  connect(mapStateToProps),
  reduxForm({}),
)(SeminarForm);
