import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import API from './API';
import rootReducer from './rootReducer';
import SportnetApi from './SportnetApi';

if (process.env.REACT_APP_SPORTNET_API_BASE_URL) {
  SportnetApi.setBaseUrl(String(process.env.REACT_APP_SPORTNET_API_BASE_URL));
} else {
  throw Error('REACT_APP_SPORTNET_API_BASE_URL not set');
}

if (process.env.REACT_APP_API_BASE_URL) {
  API.setBaseUrl(String(process.env.REACT_APP_API_BASE_URL));
} else {
  throw Error('REACT_APP_API_BASE_URL not set');
}

let composeEnhancers = compose;
if (typeof window !== 'undefined') {
  const devToolsCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  composeEnhancers = devToolsCompose
    ? devToolsCompose({
        trace: true,
        traceLimit: 25,
      })
    : compose;
}

const thunk = thunkMiddleware.withExtraArgument({
  API,
  SportnetApi,
});

const enhancer = composeEnhancers(applyMiddleware(thunk));

export default function configureStore() {
  return createStore(rootReducer, enhancer);
}
