import Icon from '@sportnet/ui/Icon';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import * as React from 'react';
import styled from 'styled-components';
import { __ } from '../../utils';
import Dropzone from './dropzone';
import FileLine from './file';

const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const Wrapper = styled.div`
  position: relative;
`;

const Label = styled('label')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${rem(20)} ${rem(10)};
  border-radius: ${rem(4)};
  border: ${rem(1.5)} dashed
    ${({ error, theme }) => (error ? theme.color.danger : theme.separatorColor)};
  cursor: pointer;
  flex-grow: 1;
`;

const FileList = styled.div`
  & > div:first-child {
    margin-top: ${rem(5)};
  }
`;

const DGTitle = styled.div``;

class CompFileInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }
  nextId = 0;

  onChange = (files) => {
    const value = [...this.props.value];
    for (let i = 0; i < (files || []).length; i++) {
      const file = (files || [])[i];
      value.push({
        _id: this.getId(),
        name: file.name,
        size: file.size,
        file,
        _new: true,
      });
    }
    this.props.onChange(value);
    this.resetValue();
  };

  onInputChange = (e) => {
    this.onChange(e.target.files);
  };

  onFileClick = (file) => {
    if (this.props.onFileClick) {
      return () => this.props.onFileClick && this.props.onFileClick(file);
    }
    return null;
  };

  onDelete = (file) => (e) => {
    e.stopPropagation();
    const { value } = this.props;
    if (window.confirm(__('Skutočne si želáte odstrániť súbor?'))) {
      this.props.onChange(value.filter((val) => val._id !== file._id));
      if (this.props.onDeleteCallback) {
        this.props.onDeleteCallback(file);
      }
    }
  };

  getId = () => {
    const id = this.nextId;
    this.nextId += 1;
    return String(id);
  };

  resetValue = () => {
    this.setState({
      value: '',
    });
  };

  renderFile = (file) => {
    return (
      <FileLine
        key={file._id || file.fileid}
        file={file}
        {...(this.props.onDeleteCallback && {
          onDelete: this.onDelete(file),
        })}
        onClick={this.onFileClick(file)}
      />
    );
  };

  render() {
    const {
      inputRef,
      readOnly,
      id,
      className,
      accept,
      multiple,
      value,
      error,
      disabled,
    } = this.props;
    const inputId = id || this.context.id;
    let files = [...value];
    const newFiles = files.reduce((acc, file) => {
      if (!file.fileid) {
        return [...acc, file];
      }
      return acc;
    }, []);
    if (newFiles.length && files.length !== newFiles.length) {
      files = newFiles;
    }

    return (
      <Wrapper>
        {!disabled ? (
          <Dropzone onDropFiles={this.onChange}>
            <Label htmlFor={inputId} error={error}>
              <Icon size="l" name="file-download" />
              <DGTitle>{__('Sem vložte súbory')}</DGTitle>
            </Label>
            <FileInput
              value={this.state.value}
              type="file"
              className={className}
              ref={inputRef}
              readOnly={!!readOnly}
              id={inputId}
              accept={accept}
              multiple={multiple}
              onChange={this.onInputChange}
            />
            <FileList>{files.map(this.renderFile)}</FileList>
          </Dropzone>
        ) : (
          <>
            <FileInput
              value={this.state.value}
              type="file"
              className={className}
              ref={inputRef}
              readOnly={!!readOnly}
              id={inputId}
              accept={accept}
              multiple={multiple}
              onChange={this.onInputChange}
            />
            <FileList>{(value || []).map(this.renderFile)}</FileList>
          </>
        )}
      </Wrapper>
    );
  }
}

CompFileInput.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  inputRef: PropTypes.func,
  readOnly: PropTypes.bool,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      fileid: PropTypes.string,
      name: PropTypes.string.isRequired,
      size: PropTypes.number,
      file: PropTypes.shape({}).isRequired,
      removed: PropTypes.bool.isRequired,
      url: PropTypes.string,
      _new: PropTypes.bool,
    }).isRequired,
  ),
  onDeleteCallback: PropTypes.func,
  onFileClick: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};

CompFileInput.defaultProps = {
  value: [],
  disabled: false,
  className: '',
  id: null,
  readOnly: false,
  inputRef: () => {
    //
  },
  accept: '',
  multiple: false,
  onDeleteCallback: () => {
    //
  },
  onFileClick: null,
  error: false,
};

export default CompFileInput;
