import React from 'react';

const APP_CONTEXT_DEFAULT_VALUE = {
  // objekt appspace s ktorym pracujem
  activeAppSpace: undefined,
  // vsetky appspaces ku ktorym ma uzivatel pristup
  appSpaces: undefined,
  // info o aplikacii
  appInfo: undefined,
  // appky, ku ktorym ma uzivatel pristup
  apps: undefined,
  //
  activeAppSpaceId: undefined,
  //
  notificationsCount: -1,
};

const AppContext = React.createContext(APP_CONTEXT_DEFAULT_VALUE);
export default AppContext;
