import Tabber from '@sportnet/ui/Tabber';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { activeAppSpaceSelector } from '../../containers/Authorization/selectors';

class SeminarMenu extends PureComponent {
  handleClickEditSeminarTab = () => {
    const { userspace, match } = this.props;
    this.props.history.push(
      `/userspace/${userspace.org_profile._id}/seminar/${match.params.seminar_id}`,
    );
  };

  handleClickNewTestTab = () => {
    const { userspace } = this.props;
    this.props.history.push(`/userspace/${userspace.org_profile._id}/seminar`);
  };

  handleClickSeminarUsersTab = () => {
    const { userspace, match } = this.props;
    this.props.history.push(
      `/userspace/${userspace.org_profile._id}/seminar/${match.params.seminar_id}/users`,
    );
  };

  render() {
    return (
      <Tabber
        items={
          this.props.match.params.seminar_id
            ? ['Úprava seminára', 'Zoznam účastníkov']
            : ['Nový seminár']
        }
        active={this.props.active}
        onClickItem={(item) => {
          switch (item) {
            case 'Úprava seminára':
              this.handleClickEditSeminarTab();
              break;
            case 'Nový seminár':
              this.handleClickNewSeminarTab();
              break;
            case 'Zoznam účastníkov':
              this.handleClickSeminarUsersTab();
              break;
            default:
              this.handleClickEditSeminarTab();
              break;
          }
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userspace: activeAppSpaceSelector(state),
  };
};

SeminarMenu.propTypes = {
  userspace: PropTypes.shape({
    org_profile: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      seminar_id: PropTypes.node,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  active: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(withRouter(SeminarMenu));
