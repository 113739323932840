import { Button } from '@sportnet/ui';
import { Loader } from '@sportnet/ui/Loader';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import * as React from 'react';
import styled, { css, withTheme } from 'styled-components';

const Title = styled.div`
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Name = styled(Title)`
  font-size: ${rem(14)};
  display: flex;
  ${({ removed }) =>
    removed &&
    css`
      text-decoration: line-through;
    `}
`;

const Wrapper = styled('div')`
  position: relative;
  padding: ${rem(8)} ${rem(10)};
  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `};
  ${({ isNew }) =>
    isNew &&
    css`
      background: #ebf1ff;
    `}
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily};
  .sportnetui-btn {
    padding: 0 ${rem(7)};
    font-size: ${rem(8)};
    min-height: ${rem(22)};
    .icon {
      height: ${rem(8)};
      width: ${rem(8)};
    }
  }
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.separatorColor};
  min-height: ${rem(45)};
  align-items: center;
`;

const ButtoWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

class FileLine extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
    };
  }

  render() {
    const { file, onClick, onDelete, theme } = this.props;
    return (
      <Wrapper
        isNew={!file.fileid}
        onClick={async () => {
          if (onClick) {
            this.setState({
              isFetching: true,
            });
            await onClick();
            this.setState({
              isFetching: false,
            });
          }
        }}
        clickable={!!onClick}
      >
        <Name removed={!!file.removed}>
          {file.name || ''}{' '}
          {this.state.isFetching && (
            <div style={{ paddingLeft: '20px' }}>
              <Loader theme={theme} size="xs" />
            </div>
          )}
        </Name>
        {onDelete && (!file.removed || !file.fileid) && (
          <ButtoWrapper>
            <Button danger icon="close" onClick={onDelete} />
          </ButtoWrapper>
        )}
      </Wrapper>
    );
  }
}

FileLine.propTypes = {
  file: PropTypes.shape({
    removed: PropTypes.bool,
    fileid: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  theme: PropTypes.shape({}).isRequired,
};

FileLine.defaultProps = {
  onClick: () => {},
  onDelete: () => {},
};

export default withTheme(FileLine);
