import React from 'react';
import PropTypes from 'prop-types';
import SvgPicture from './svgPicture';

const Image = props => {
  const {
    name,
    color,
    height,
    width
  } = props;
  return (
    <SvgPicture name={name} height={height} width={width} color={color} />
  );
};

Image.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

Image.defaultProps = {
  color: null,
  height: null,
  width: null,
};

export default Image;
