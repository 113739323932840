import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import FormField from '@sportnet/ui/FormField';
import FormGroup from '@sportnet/ui/FormGroup';
import Col, { Row } from '@sportnet/ui/Grid';
import HeaderBar from '@sportnet/ui/HeaderBar';
import Label from '@sportnet/ui/Label/Label';
import { ContentLoader } from '@sportnet/ui/Loader';
import { AlertModal } from '@sportnet/ui/Modal';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import Select from '@sportnet/ui/Select';
import SmarttagControl from '@sportnet/ui/SmarttagsControl/SmarttagControl';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { getProp } from '@sportnet/utilities';
import TagmanagerConnector from '@sportnet/tagmanager-connector';
import API from '../../API';
import ContentEditor from '../../components/Content/editor';
import Layout from '../../components/Layout';
import { getAvailableWidgets, __ } from '../../utils';
import {
  activeAppSpaceSelector,
  notActiveAppSpacesSelector,
} from '../Authorization/selectors';
import CopyQuestionModal from '../CopyQuestionModal';
import MandatoryVideo from '../MandatoryVideo';
import { AddAnswerButtonWrapper } from './styles';
import withSportnetAuth from '../../hoc/withSportnetAuth';
import { compose } from 'redux';

class Question extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      mandatoryVideoForm: false,
      haveAnswers: true,
      video: null,
      answers: [
        {
          text: '',
          checked: false,
          description: {
            widgets: [],
          },
        },
      ],
      content: {
        widgets: [],
      },
      smarttags: [],
      newTag: '',
      questionName: '',
      isFetching: false,
      hint: {
        widgets: [],
      },
      modalQuestion: {
        isOpen: false,
        userspaceId: null,
      },
      errorMessage: null,
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.handleApiError = this.handleApiError.bind(this);

    this.hanleChangeHaveAnsw = this.hanleChangeHaveAnsw.bind(this);
    this.handleChangeVideo = this.handleChangeVideo.bind(this);
    this.handleChangeText = this.handleChangeText.bind(this);
    this.handleChangeSwitch = this.handleChangeSwitch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeQuestionName = this.handleChangeQuestionName.bind(this);
    this.handleAddAnswer = this.handleAddAnswer.bind(this);
    this.handleDeleteAnswer = this.handleDeleteAnswer.bind(this);

    this.handleUpdateContent = this.handleUpdateContent.bind(this);
    this.handleUpdateAnswerContent = this.handleUpdateAnswerContent.bind(this);
    this.handleChangeHint = this.handleChangeHint.bind(this);
    this.handleCloseQuestionModal = this.handleCloseQuestionModal.bind(this);
    this.handleCopyQuestion = this.handleCopyQuestion.bind(this);
    this.loadQuestion = this.loadQuestion.bind(this);
    this.handleUpdateHint = this.handleUpdateHint.bind(this);
  }

  componentDidMount() {
    this.loadQuestion(this.props);
  }

  handleApiError(err, msg) {
    let apiErr;
    if (err.details) {
      apiErr = `${err.details.statusCode}: ${err.details.description}`;
    }
    this.setState({
      errorMessage: `${msg}\n ERROR: ${apiErr || ''}`,
    });
  }

  async loadQuestion(props) {
    const { params } = props.match;
    this.setState({
      isFetching: true,
    });
    let stateUpdate = {
      isFetching: false,
    };

    if (params.question_id) {
      try {
        const {
          description,
          smarttags,
          text,
          answers,
          type,
          hint,
          mandatoryVideo,
        } = await API.questionDetail(params.userspace_id, params.question_id);
        stateUpdate = {
          ...stateUpdate,
          smarttags,
          content: {
            widgets: description,
          },
          video: mandatoryVideo,
          questionName: text,
          haveAnswers: type === 'options',
          hint: {
            widgets: hint || [],
          },
          answers: answers.map((answer) => {
            return {
              text: answer.text,
              checked: answer.isCorrect,
              description: answer.description
                ? {
                    widgets: answer.description,
                  }
                : {
                    widgets: [],
                  },
            };
          }),
        };
      } catch (e) {
        this.handleApiError(e, __('Otázku sa nepodarilo načítať!'));
      }
    }

    this.setState(stateUpdate);
  }

  handleSubmit() {
    const { haveAnswers, smarttags, answers, questionName, hint, video } =
      this.state;
    const { params } = this.props.match;
    if (!smarttags.length) {
      this.setState({
        errorMessage: __('Vyplňte prosím okruhy (SMART:TAGS) otázky.'),
      });
      return false;
    }
    if (!questionName || questionName.length < 1) {
      this.setState({ errorMessage: __('Vyplňte prosím znenie otázky.') });
      return false;
    }
    const data = {};
    data.text = questionName;
    data.description = this.state.content.widgets;
    data.smarttags = smarttags;
    data.answers = [];
    data.mandatoryVideo = video;
    if (haveAnswers) {
      data.type = 'options';
      const newA = [];
      let hasCorrectAnswer = false;
      answers.forEach((answ) => {
        if (
          answ.text !== '' ||
          (answ.description && answ.description.widgets.length > 0)
        ) {
          newA.push({
            text: answ.text,
            isCorrect: answ.checked,
            description: answ.description ? answ.description.widgets : [],
          });
          if (answ.checked) {
            hasCorrectAnswer = true;
          }
        }
      });
      if (!hasCorrectAnswer) {
        this.setState({
          errorMessage: __('Otázka musí mať aspoň jednu správnu odpoveď'),
        });
        return false;
      }
      data.answers = newA;
    } else {
      data.type = 'text';
    }
    if (hint.widgets && hint.widgets.length > 0) {
      data.hint = hint.widgets;
    }
    if (params.question_id) {
      API.questionUpdate(params.userspace_id, params.question_id, {
        body: data,
      })
        .then(() => {
          this.props.history.push(
            `/userspace/${params.userspace_id}/questions`,
          );
        })
        .catch((e) => {
          this.handleApiError(e, __('Otázku sa nepodarilo uložiť!'));
        });
    } else {
      API.questionCreate(params.userspace_id, { body: data })
        .then(() => {
          this.props.history.push(
            `/userspace/${params.userspace_id}/questions`,
          );
        })
        .catch((e) => {
          this.handleApiError(e, __('Otázku sa nepodarilo uložiť!'));
        });
    }
    return true;
  }

  handleCloseAlert() {
    this.setState({
      errorMessage: null,
    });
  }

  handleUpdateContent(widgets) {
    this.setState({
      content: {
        widgets,
      },
    });
  }

  handleUpdateHint(widgets) {
    this.setState({
      hint: {
        widgets,
      },
    });
  }

  handleUpdateAnswerContent(content, i) {
    const { answers } = this.state;
    const newAnswers = [...answers];
    newAnswers[i].description = content;
    this.setState({
      answers: newAnswers,
    });
  }

  handleAddAnswer() {
    this.setState((prev) => {
      const newAnswers = [...prev.answers];
      newAnswers.push({
        text: '',
        checked: false,
        description: {
          widgets: [],
        },
      });
      return {
        ...prev,
        answers: newAnswers,
      };
    });
  }

  handleDeleteAnswer(i) {
    this.setState((prev) => {
      const newAnswers = [...prev.answers];
      newAnswers.splice(i, 1);
      return {
        ...prev,
        answers: newAnswers,
      };
    });
  }

  handleChangeSwitch(e, i) {
    const { answers } = this.state;
    const newAnswers = [...answers];
    newAnswers[i].checked = !answers[i].checked;
    this.setState({
      answers: newAnswers,
    });
  }

  handleChangeText(e, i) {
    const { answers } = this.state;
    const newAnswers = [...answers];
    newAnswers[i].text = e.target.value;
    this.setState({
      answers: newAnswers,
    });
  }

  hanleChangeHaveAnsw() {
    this.setState((prevState) => ({
      haveAnswers: !prevState.haveAnswers,
    }));
  }

  handleChangeVideo(video) {
    this.setState({
      video,
      mandatoryVideoForm: false,
    });
  }

  handleChangeQuestionName(e) {
    this.setState({
      questionName: e.target.value,
    });
  }

  handleChangeHint(e) {
    this.setState({
      hint: {
        text: e.target.value,
      },
    });
  }

  handleCopyQuestion(e) {
    const userspaceId = e.target.value;
    window.setTimeout(() => {
      this.setState({
        modalQuestion: {
          userspaceId,
          isOpen: true,
        },
      });
    }, 0);
  }

  handleCloseQuestionModal() {
    this.setState({
      modalQuestion: {
        userspaceId: null,
        isOpen: false,
      },
    });
  }

  renderAnswerBlock(answer, i) {
    const { text, checked } = answer;
    const { answers } = this.state;

    return (
      <Segment
        secondary
        key={String(i + 1)}
        header={
          <SegmentHeader
            size="xs"
            withSeparator
            collapsible
            onDispose={
              answers.length > 1 ? () => this.handleDeleteAnswer(i) : undefined
            }
          >
            {`Odpoveď č. ${i + 1}`}
          </SegmentHeader>
        }
      >
        <AlertModal
          isOpen={!!this.state.errorMessage}
          handleClose={this.handleCloseAlert}
        >
          {this.state.errorMessage}
        </AlertModal>
        <Row>
          <Col>
            <FormField
              label={__('Odpoveď')}
              type="textarea"
              value={text}
              onChange={(e) => this.handleChangeText(e, i)}
            />
          </Col>
          <Col>
            <FormField
              label={__('Odpoveď je správna')}
              type="checkbox"
              value={checked}
              checked={checked}
              onChange={(e) => this.handleChangeSwitch(e, i)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ContentEditor
              value={getProp(answer.description, ['widgets'], [])}
              onChange={(widgets) => {
                const content = getProp(answer, ['description'], {
                  widgets: [],
                });
                content.widgets = widgets.reduce((acc, next) => {
                  if (next.type === 'text') {
                    return [
                      ...acc,
                      {
                        ...next,
                        value: next.text,
                      },
                    ];
                  }
                  return [...acc, next];
                }, []);
                this.handleUpdateAnswerContent(content, i);
              }}
              availableWidgets={getAvailableWidgets()}
            />
          </Col>
        </Row>
      </Segment>
    );
  }

  render() {
    const {
      haveAnswers,
      video,
      answers,
      questionName,
      isFetching,
      hint,
      modalQuestion,
      content,
      newTag,
      smarttags,
    } = this.state;

    const {
      otherUserspaces,
      match: {
        params: { question_id: questionId, userspace_id: userspaceId },
      },
    } = this.props;

    if (!this.props.userspace) {
      return null;
    }

    return (
      <Layout
        topFixed={
          <HeaderBar>
            <HeaderBar.Action
              icon="back"
              as={Link}
              to={`/userspace/${userspaceId}/questions`}
              title={__('Otázky')}
            />
            <HeaderBar.Header>
              {questionId
                ? `${__('Úprava otázky')}: ${questionId}`
                : __('Nová otázka')}
            </HeaderBar.Header>
          </HeaderBar>
        }
        bottomFixed={
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <Select
                value=""
                onChange={this.handleCopyQuestion}
                disabled={isFetching}
              >
                <option value="" hidden>
                  {__('Skopírovať do...')}
                </option>
                {otherUserspaces.map((userspace) => (
                  <option
                    key={userspace.org_profile._id}
                    value={userspace.org_profile._id}
                  >
                    {userspace.org_profile.name}
                  </option>
                ))}
              </Select>
            </ContextBarItem>
            <ContextBarItem>
              <Button primary onClick={this.handleSubmit}>
                {__('Uložiť')}
              </Button>
            </ContextBarItem>
          </ContextBar>
        }
      >
        <CopyQuestionModal
          onSubmit={this.handleCloseQuestionModal}
          isOpen={modalQuestion.isOpen}
          handleClose={this.handleCloseQuestionModal}
          userspaceId={modalQuestion.userspaceId}
          defaults={{
            haveAnswers,
            video,
            answers,
            content,
            smarttags,
            newTag,
            questionName,
            hint,
          }}
        />
        <Segment>
          {isFetching ? (
            <ContentLoader size="xl" />
          ) : (
            <>
              <Segment
                raised
                header={
                  <SegmentHeader size="s" withSeparator>
                    {__('Nastavenia')}
                  </SegmentHeader>
                }
              >
                <Row>
                  <Col m={6}>
                    <FormField
                      label={__('Znenie')}
                      type="textarea"
                      value={questionName}
                      onChange={this.handleChangeQuestionName}
                      required
                    />
                  </Col>
                  <Col m={6}>
                    <FormGroup>
                      <Label htmlFor="smarttags">{__('SMART:TAGS')}</Label>
                      <TagmanagerConnector
                        appId={process.env.REACT_APP_APP_ID}
                        appspace={this.props.userspace.org_profile._id}
                        accessToken={this.props.auth.accessToken}
                      >
                        {(
                          getSmarttagsKeys,
                          getSmarttagsValues,
                          getSmarttagsByFts,
                        ) => (
                          <SmarttagControl
                            name="smarttags"
                            getSmarttagsKeys={getSmarttagsKeys}
                            getSmarttagsValues={getSmarttagsValues}
                            getSmarttagsByFts={getSmarttagsByFts}
                            value={this.state.smarttags}
                            onChange={(v) => this.setState({ smarttags: v })}
                            required
                          />
                        )}
                      </TagmanagerConnector>
                    </FormGroup>
                  </Col>
                  <Col>
                    <Button
                      primary={!!video}
                      basic={!!!video}
                      onClick={() => {
                        this.setState((prevState) => ({
                          mandatoryVideoForm: !prevState.mandatoryVideoForm,
                        }));
                      }}
                      icon={video ? 'check-circle' : 'plus'}
                    >
                      {__('Povinné video')}
                    </Button>
                  </Col>
                </Row>
              </Segment>

              <Segment
                raised
                header={
                  <SegmentHeader
                    withSeparator
                    subtitle={__(
                      'Nápoveda bude zobrazená len v testoch označených ako "Cvičný"',
                    )}
                    collapsible
                    size="s"
                  >
                    {__('Nápoveda')}
                  </SegmentHeader>
                }
              >
                <Row>
                  <Col>
                    <ContentEditor
                      onChange={(widgets) => {
                        const newWidgets = widgets.reduce((acc, next) => {
                          if (next.type === 'text') {
                            return [
                              ...acc,
                              {
                                ...next,
                                value: next.text,
                              },
                            ];
                          }
                          return [...acc, next];
                        }, []);
                        this.handleUpdateHint(newWidgets);
                      }}
                      value={hint.widgets || []}
                      availableWidgets={getAvailableWidgets()}
                    />
                  </Col>
                </Row>
              </Segment>

              <Segment
                raised
                header={
                  <SegmentHeader withSeparator collapsible size="s">
                    {__('Popis')}
                  </SegmentHeader>
                }
              >
                <Row>
                  <Col>
                    <ContentEditor
                      onChange={(widgets) => {
                        const newWidgets = widgets.reduce((acc, next) => {
                          if (next.type === 'text') {
                            return [
                              ...acc,
                              {
                                ...next,
                                value: next.text,
                              },
                            ];
                          }
                          return [...acc, next];
                        }, []);
                        this.handleUpdateContent(newWidgets);
                      }}
                      value={this.state.content.widgets || []}
                      availableWidgets={getAvailableWidgets()}
                    />
                  </Col>
                </Row>
              </Segment>

              <Segment
                raised
                header={
                  <SegmentHeader withSeparator collapsible size="s">
                    {__('Odpovede')}
                  </SegmentHeader>
                }
              >
                <Row>
                  <Col>
                    <FormField
                      label={__('Otázka má odpovede')}
                      type="checkbox"
                      value={haveAnswers}
                      checked={haveAnswers}
                      onChange={this.hanleChangeHaveAnsw}
                    />
                  </Col>
                </Row>

                {haveAnswers && (
                  <>
                    {answers &&
                      answers.map((answer, i) =>
                        this.renderAnswerBlock(answer, i),
                      )}
                    <AddAnswerButtonWrapper>
                      <Button
                        success
                        icon="plus"
                        onClick={this.handleAddAnswer}
                      >
                        {__('Pridať odpoveď')}
                      </Button>
                    </AddAnswerButtonWrapper>
                  </>
                )}
              </Segment>
            </>
          )}
        </Segment>
        {this.state.mandatoryVideoForm && (
          <MandatoryVideo
            isOpen={this.state.mandatoryVideoForm}
            handleClose={() => this.setState({ mandatoryVideoForm: false })}
            video={video}
            onVideoChange={this.handleChangeVideo}
          />
        )}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userspace: activeAppSpaceSelector(state),
    otherUserspaces: notActiveAppSpacesSelector(state),
  };
};

Question.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userspace_id: PropTypes.string.isRequired,
      question_id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  userspace: PropTypes.shape({
    org_profile: PropTypes.shape({
      name: PropTypes.string.isRequired,
      _id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  otherUserspaces: PropTypes.arrayOf(
    PropTypes.shape({
      org_profile: PropTypes.shape({
        name: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
};

const connected = compose(
  withSportnetAuth,
  withRouter,
  connect(mapStateToProps),
)(Question);
export default connected;
