import React, { useEffect, useState } from 'react';
import { __ } from '../../utils';
import Header from '@sportnet/ui/Header';
import Segment from '@sportnet/ui/Segment';
import API from '../../API';
import { withRouter } from 'react-router-dom';
import TestUsersTable from '../../components/TestUsersTable';

const SeminarUserTests = ({
  user,
  seminar,
  match: {
    params: { userspace_id: appSpace },
  },
  history,
}) => {
  const [testsAreLoading, setTestsAreLoading] = useState(false);
  const [userTests, setUserTests] = useState([]);
  useEffect(() => {
    if (!user || !seminar) {
      return;
    }
    setTestsAreLoading(true);
    API.adminUserAllTestsList(appSpace, user.sportnetId, {
      limit: 0,
      seminarId: seminar._id,
    })
      .then(({ tests }) => {
        setUserTests(tests);
      })
      .catch((e) => {
        alert(__('Chyba pri načítaní testov'));
        console.error(e);
      })
      .finally(() => {
        setTestsAreLoading(false);
      });
  }, [user, seminar, appSpace]);
  if (!user || !seminar) {
    return null;
  }
  return (
    <>
      <Header icon="person" subtitle={seminar.name}>
        {user.displayName}
      </Header>
      <Segment loading={testsAreLoading}>
        <TestUsersTable
          tests={userTests}
          testNameColumn
          userColumn={false}
          testDetail={(test) => {
            history.push(
              `/userspace/${appSpace}/template/${test.templateId}/test/${
                test._id
              }?back=${encodeURIComponent(
                window.location.pathname + window.location.search,
              )}`,
            );
          }}
        />
      </Segment>
    </>
  );
};

export default withRouter(SeminarUserTests);
