import BasicTable from '@sportnet/ui/BasicTable';
import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import HeaderBar from '@sportnet/ui/HeaderBar';
import { ContentLoader } from '@sportnet/ui/Loader';
import NotFound from '@sportnet/ui/NotFound';
import Paginator from '@sportnet/ui/Paginator';
import Segment from '@sportnet/ui/Segment';
import Sidebar from '@sportnet/ui/Sidebar';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import connectQueryHoc, { QueryHocTypes } from '@sportnet/query-hoc';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { getProp } from '@sportnet/utilities';
import styled from 'styled-components';
import API from '../../API';
import Layout from '../../components/Layout';
import SeminarMenu from '../../components/SeminarMenu';
import { __ } from '../../utils';
import AddUsersForm from './addUsersForm';
import UserForm from './userForm';
import DefaultFilter from '@sportnet/ui/Filter';
import Modal, { ModalActions } from '@sportnet/ui/Modal';
import { ModalContent } from '@sportnet/ui/Modal/modal';
import SeminarUserTests from './seminarUserTests';
import MessageModal from './MessageModal';

export const FORM_NAME = 'SEMINAR_FORM';
export const PART_FORM_NAME = 'SEMINAR_PART_FORM';

const USER_STATUS_MAP = {
  pending: __('Čaká na spustenie'),
  inprogress: __('Prebieha'),
  finished: __('Dokončené'),
  complete: __('Absolvoval/a'),
  incomplete: __('Neabsolvoval/a'),
};

export const getSeminarUserStatusName = (status) => {
  return USER_STATUS_MAP[status] || status;
};

const AVAILABLE_STATUS_CHANGES_MAP = {
  pending: [],
  complete: ['incomplete'],
  incomplete: ['complete'],
  finished: ['complete', 'incomplete'],
  inprogress: [],
};

const getAvailableStatusChanges = (currentStatus) => {
  return [
    currentStatus,
    ...(AVAILABLE_STATUS_CHANGES_MAP[currentStatus] || []),
  ].map((status) => ({
    label: USER_STATUS_MAP[status],
    value: status,
  }));
};

export const Faded = styled.span`
  font-size: 90%;
  color: ${({ theme }) => theme.color.fadedText};
`;

export const FinishedPartName = styled.span`
  display: inline-block;
  vertical-align: middle;
  background: ${({ theme }) => theme.color.primary};
  color: #fff;
  border-radius: 8px;
  padding: 3px 5px;
  margin: 3px 3px 3px 3px;
  max-width: 50%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

class SeminarUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      data: [],
      nextOffset: null,
      sidebarOpened: false,
      userData: null,
      total: 0,
      seminar: null,
      statusFilter: null,
      selected: {},
      courierSenderOpened: false,
    };
    this.setSelected = this.setSelected.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.serializedQuery !== nextProps.serializedQuery) {
      this.fetchData(nextProps);
    }
  }

  toggleUserModal = (userData) => {
    this.setState((prevState) => ({
      ...(userData ? { userData } : { userData: null }),
      sidebarOpened: userData ? true : !prevState.sidebarOpened,
    }));
  };

  toggleCourierSender = () => {
    this.setState((prevState) => ({
      courierSenderOpened: !prevState.courierSenderOpened,
    }));
  };

  showUserTests = (user) => {
    this.setState({ showUserTests: user });
  };

  fetchData = async (props = this.props) => {
    const {
      match: {
        params: { userspace_id: appSpace, seminar_id: seminarId },
      },
      query: { offset, limit, status },
    } = props;
    if (seminarId) {
      try {
        this.setState({
          isFetching: true,
        });
        const [{ users, nextOffset, total }, seminar] = await Promise.all([
          API.seminarUsersList(appSpace, seminarId, { offset, limit, status }),
          this.state.seminar || API.seminarDetail(appSpace, seminarId),
        ]);
        this.setState({
          data: users,
          nextOffset,
          total,
          seminar,
        });
      } catch (e) {
        alert(__('Nepodarilo sa získať zoznam účastníkov seminára'));
      } finally {
        this.setState({
          isFetching: false,
        });
      }
    }
  };

  handleChangeParameter = (name) => {
    return (value) => {
      const update = { [name]: value };
      // reset paginator
      if (name !== 'offset') {
        update.offset = 0;
      }
      this.props.setParameter(update);
    };
  };

  setSelected(selected) {
    this.setState({ selected });
  }

  render() {
    const isAnySelected = Object.values(this.state.selected).some(Boolean);

    const {
      params: { seminar_id: seminarId, userspace_id: appSpace },
    } = this.props.match;
    const {
      query: { offset, limit, status },
    } = this.props;

    return (
      <Layout
        topFixed={
          <HeaderBar>
            <HeaderBar.Action
              icon="back"
              as={Link}
              to={`/userspace/${this.props.match.params.userspace_id}/seminars`}
              title={__('Semináre')}
            />
            <HeaderBar.Header>
              {__('Zoznam účastníkov')}:{' '}
              {this.state.seminar ? this.state.seminar.name : seminarId}
            </HeaderBar.Header>
          </HeaderBar>
        }
        bottomFixed={
          <ContextBar>
            <ContextBarItem>
              <Paginator
                offset={offset}
                onChangeOffset={this.handleChangeParameter('offset')}
                limit={limit}
                step={limit}
                total={this.state.total}
              />
            </ContextBarItem>
            <ContextBarSpacer />
            <ContextBarItem>
              <Button
                primary
                disabled={!isAnySelected}
                onClick={() => this.toggleCourierSender()}
              >
                {__('Odoslať správu')}
              </Button>
              &nbsp;
              <Button primary onClick={() => this.toggleUserModal()}>
                {__('Pridať účastníka')}
              </Button>
            </ContextBarItem>
          </ContextBar>
        }
      >
        <SeminarMenu active="Zoznam účastníkov" />
        <DefaultFilter
          value={{
            status: status ? { value: status } : undefined,
          }}
          onChange={(nextFilterValue) => {
            this.props.setParameter({
              status: nextFilterValue.status
                ? nextFilterValue.status.value
                : undefined,
            });
          }}
          filters={[
            {
              type: 'select',
              name: 'status',
              label: 'Stav',
              options: Object.entries(USER_STATUS_MAP).map(
                ([value, label]) => ({ value, label }),
              ),
            },
          ]}
        />
        <Segment>
          {!this.state.isFetching && this.state.data.length === 0 ? (
            <NotFound icon="file" title={__('Žiadni účastníci')} />
          ) : (
            <Segment raised>
              {this.state.isFetching ? (
                <ContentLoader size="xl" />
              ) : (
                <BasicTable
                  rowKey="_id"
                  selected={this.state.selected}
                  onSelect={this.setSelected}
                  columns={[
                    { header: __('Meno a priezvisko / SportNet ID') },
                    { header: __('Dátum poslednej aktivity') },
                    { header: __('Stav') },
                    { header: __('Odomknuté časti') },
                    { header: __('Riešené testy') },
                  ]}
                  rows={this.state.data}
                  renderRow={(item) => [
                    <>
                      <strong>{item.user.displayName}</strong>
                      <br />
                      {item.user.sportnetId}
                    </>,
                    item.modified
                      ? format(new Date(item.modified), 'dd.MM.yyyy HH:mm')
                      : '-',
                    getSeminarUserStatusName(item.status),
                    (item.unlockedPartIds || []).map((pid) => {
                      const seminarPart = this.state.seminar
                        ? this.state.seminar.parts.find(
                            ({ _id }) => _id === pid,
                          )
                        : null;
                      if (seminarPart) {
                        return (
                          <FinishedPartName
                            title={`${seminarPart.nr}. ${seminarPart.name}`}
                          >{`${seminarPart.nr}. ${seminarPart.name}`}</FinishedPartName>
                        );
                      }
                      return null;
                    }),
                    <Button
                      type="button"
                      basic
                      primary
                      onClick={(e) => {
                        e.stopPropagation();
                        this.showUserTests(item.user);
                      }}
                    >
                      {__('Zobraziť')}
                    </Button>,
                  ]}
                  onClickRow={(item) => {
                    this.toggleUserModal(item);
                  }}
                />
              )}
            </Segment>
          )}
        </Segment>

        <Sidebar
          visible={this.state.sidebarOpened}
          onClose={() => this.toggleUserModal()}
          header={getProp(
            this.state,
            ['userData', 'user', 'displayName'],
            __('Pridať nových používateľov'),
          )}
        >
          {this.state.userData ? (
            <UserForm
              user={this.state.userData}
              seminar={this.state.seminar}
              onRemove={async () => {
                await this.fetchData();
                this.toggleUserModal();
              }}
              onSave={async (editedUser) => {
                const idx = this.state.data.findIndex(
                  (i) => i._id === editedUser._id,
                );
                const items = [...this.state.data];
                items[idx] = editedUser;
                if (idx > -1) {
                  this.setState({
                    data: items,
                  });
                }
                this.toggleUserModal();
              }}
              availableStatuses={getAvailableStatusChanges(
                this.state.userData.status,
              )}
            />
          ) : (
            <AddUsersForm
              appSpace={appSpace}
              seminarId={seminarId}
              onAdd={() => {
                this.fetchData();
              }}
            />
          )}
        </Sidebar>
        <Modal
          isOpen={!!this.state.showUserTests}
          handleClose={() => this.showUserTests(null)}
          size="m"
        >
          {!!this.state.showUserTests && (
            <ModalContent>
              <SeminarUserTests
                user={this.state.showUserTests}
                seminar={this.state.seminar}
              />
            </ModalContent>
          )}
          <ModalActions>
            <Button type="button" onClick={() => this.showUserTests(null)}>
              {__('Zavrieť')}
            </Button>
          </ModalActions>
        </Modal>

        {this.state.courierSenderOpened && (
          <MessageModal
            isOpen={this.state.courierSenderOpened}
            onClose={() => this.toggleCourierSender()}
            recipientSportnetIds={Object.entries(this.state.selected)
              .filter(([, selected]) => selected)
              .map(([seminarUserId]) => {
                return this.state.data.find(({ _id }) => _id === seminarUserId)
                  .user.sportnetId;
              })}
          />
        )}
      </Layout>
    );
  }
}

SeminarUsers.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userspace_id: PropTypes.string.isRequired,
      seminar_id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  query: PropTypes.shape({
    offset: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
  }).isRequired,
  setParameter: PropTypes.func.isRequired,
  serializedQuery: PropTypes.string.isRequired,
};

export default compose(
  withRouter,
  connectQueryHoc({
    parameters: {
      offset: {
        type: QueryHocTypes.Number,
        defaultValue: 0,
      },
      limit: {
        type: QueryHocTypes.Number,
        defaultValue: 100,
      },
      status: {
        type: QueryHocTypes.String,
      },
    },
  }),
)(SeminarUsers);
