import Content from '@sportnet/content/view';
import PropTypes from 'prop-types';
import React from 'react';

const ContentView = ({ items }) => {
  return (
    <Content
      items={items || []}
      renderLink={(link) => (
        <a href="/" target="_blank" {...link.linkProps}>
          {link.linkProps.children}
        </a>
      )}
    />
  );
};

ContentView.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
};

ContentView.defaultProps = {
  items: [],
};

export default ContentView;
