import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { __ } from '../../utils';
import Icon from '@sportnet/ui/Icon';
import {
  getTimeDurationAsString,
  getTimeDurationFromSeconds,
  formatDate,
} from '../../utils';

const Em = styled.div`
  font-size: ${rem(12)};
  font-style: italic;
`;

const TestRow = styled(Tr)`
  & > td {
    background: ${({ color }) => color};
  }
`;

class TestUsersTable extends PureComponent {
  constructor(props) {
    super(props);
    this.redColor = '#ffeded';
    this.yellowColor = '#feffed';
    this.greenColor = '#f4ffed';

    this.testDetail = this.props.testDetail.bind(this);
  }

  getTestDuration(test) {
    if (test.userFinishAt) {
      return getTimeDurationAsString(test.userFinishAt, test.startAt);
    }
    return getTimeDurationAsString(test.expiresAt, test.startAt);
  }

  getTestLimit(test) {
    return getTimeDurationFromSeconds(test.limit);
  }

  getPassedIcon(test) {
    if (test.passed === true) {
      return <Icon name="check-circle" color="green" />;
    }
    if (test.passed === false) {
      return <Icon name="remove-circle" color="red" />;
    }
    return <span />;
  }

  render() {
    return (
      <Table>
        <Thead>
          <Tr>
            <Th />
            {this.props.testNameColumn && <Th>{__('Test')}</Th>}
            {this.props.userColumn && <Th>{__('Meno užívateľa')}</Th>}
            <Th>{__('Dátum a čas začiatku testovania')}</Th>
            <Th>{__('Doba riešenia testu (h:mm:ss)')}</Th>
            <Th>{__('Časový limit testu (h:mm:ss)')}</Th>
            <Th>{__('Hodnotenie')}</Th>
            <Th>{__('Stav')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {this.props.tests.map((test) => {
            let color;
            let status;
            if (test.evaluated) {
              color = this.greenColor;
              status = `${__('Vyhodnotený')}`;
            } else if (!test.evaluated && test.finished) {
              color = this.yellowColor;
              status = `${__('Nevyhodnotený')}`;
            } else {
              color = this.redColor;
              status = `${__('Prebiehajúci')}`;
            }
            return (
              <TestRow
                color={color}
                key={test._id}
                onClick={() => this.testDetail(test)}
              >
                <Td>{this.getPassedIcon(test)}</Td>
                {this.props.testNameColumn && <Td>{test.name}</Td>}
                {this.props.userColumn && (
                  <Td>
                    {test.user.display_name}
                    <Em>{test.user.sportnet_id}</Em>
                  </Td>
                )}
                <Td>{formatDate(new Date(test.startAt), 'minutes')}</Td>
                <Td>{test.finished ? this.getTestDuration(test) : '-'}</Td>
                <Td>{this.getTestLimit(test)}</Td>
                <Td>
                  {test.evaluated
                    ? `${Math.round(test.evaluation * 10000) / 100} %`
                    : '-'}
                </Td>
                <Td>{status}</Td>
              </TestRow>
            );
          })}
        </Tbody>
      </Table>
    );
  }
}

TestUsersTable.defaultProps = {
  total: 0,
  limit: 0,
  testDetail: () => {},
  userColumn: true,
  testNameColumn: false,
};

TestUsersTable.propTypes = {
  tests: PropTypes.arrayOf(PropTypes.object).isRequired,
  testDetail: PropTypes.func,
  testNameColumn: PropTypes.bool,
  userColumn: PropTypes.bool,
};

export default TestUsersTable;
