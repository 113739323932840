import { createSelector } from 'reselect';
import { authUserSelector } from '../Authorization/selectors';

export const userspaceUsersSelector = state => state.userspaceUsers || [];

export const userspaceOtherUsersSelector = createSelector(
  userspaceUsersSelector,
  authUserSelector,
  (users, authUser) => {
    if (authUser) {
      return users.filter(u => u.user_id !== authUser._id);
    }
    return users;
  },
);
