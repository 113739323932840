import AuthProvider from '@sportnet/auth-react/AuthProvider';
import React from 'react';
import Administration from '../Administration';
import { useParams } from 'react-router-dom';
import CoreApi from '../../SportnetApi';
import TestovanieApi from '../../API';

const Authorization = () => {
  const [isAuthorized, setIsAuthorized] = React.useState(false);

  const params = useParams();

  const { appspace: appSpaceFromUrl } = params;

  const handleAuthorize = React.useCallback(({ accessToken }) => {
    if (accessToken) {
      CoreApi.setToken(accessToken);
      TestovanieApi.setToken(accessToken);
      setIsAuthorized(true);
    }
  }, []);

  React.useEffect(() => {
    if (appSpaceFromUrl) {
      setIsAuthorized(false);
    }
  }, [appSpaceFromUrl]);

  return (
    <AuthProvider
      app={process.env.REACT_APP_APP_ID}
      ppo={appSpaceFromUrl}
      onAuthorize={handleAuthorize}
    >
      {!isAuthorized ? <>Authorizing ...</> : <Administration />}
    </AuthProvider>
  );
};

export default Authorization;
