const namespace = 'AUTHORIZATION';

export const SET_APP_INFO = `${namespace}/RECEIVE_APP_INFO_SUCCESS`;
export const SET_APPS = `${namespace}/SET_APPS`;
export const SET_APPSPACES = `${namespace}/SET_APPSPACES`;
export const SET_ACTIVE_APPSPACE_ID = `${namespace}/SET_ACTIVE_APPSPACE_ID`;
export const AUTH_USER_AUTHORIZED = `${namespace}/AUTH_USER_AUTHORIZED`;
export const AUTH_USER_UNAUTHORIZED = `${namespace}/AUTH_USER_UNAUTHORIZED`;
export const SET_ACL = `${namespace}/SET_ACL`;
export const SET_ACTIVE_APPSPACE = `${namespace}/SET_ACTIVE_APPSPACE`;
