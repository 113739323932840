import BasicTable from '@sportnet/ui/BasicTable';
import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import HeaderBar from '@sportnet/ui/HeaderBar';
import { ContentLoader } from '@sportnet/ui/Loader';
import Paginator from '@sportnet/ui/Paginator';
import Segment from '@sportnet/ui/Segment';
import Sidebar from '@sportnet/ui/Sidebar';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import API from '../../API';
import Layout from '../../components/Layout';
import TestMenu from '../../components/TestMenu';
import useQuery from '../../hooks/useQuery';
import { aclIsAllowed, __ } from '../../utils';
import { aclSelector } from '../Authorization/selectors';
import AddUsersForm from './addUsersForm';
import UserForm from './userForm';

export const Faded = styled.span`
  font-size: 90%;
  color: ${({ theme }) => theme.color.fadedText};
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.color.primary};
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

const TestTemplateUsers = ({
  match: {
    params: { userspace_id: appSpace, test_id: testTemplateId },
  },
  history,
  acl,
}) => {
  const query = useQuery();
  const offset = parseInt(query.get('offset'), 10) || 0;
  const limit = parseInt(query.get('limit'), 10) || 100;

  const [isFetching, setIsFetching] = useState(0);
  const [testDetail, setTestDetail] = useState(null);
  const [templateUsers, setTemplateUsers] = useState([]);
  const [nextOffset, setNextOffset] = useState(null);

  // naloadovanie detailu testu
  useEffect(() => {
    setIsFetching((prev) => prev + 1);
    API.templateDetail(appSpace, testTemplateId)
      .then((tpl) => setTestDetail(tpl))
      .catch((e) => {
        alert(
          `Chyba pri získavaní detailu testu: ${
            e.details ? e.details.name : e.name
          }`,
        );
      })
      .finally(() => {
        setIsFetching((prev) => prev - 1);
      });
  }, [appSpace, testTemplateId]);

  // naloadovanie zoznamu userov testu
  const [reload, setReload] = useState(new Date());
  useEffect(() => {
    setIsFetching((prev) => prev + 1);
    API.templateUsersList(appSpace, testTemplateId, { offset, limit })
      .then(({ items, nextOffset: respNextOffset }) => {
        setTemplateUsers(items);
        setNextOffset(respNextOffset);
      })
      .catch((e) => {
        alert(
          `Chyba pri získavaní zoznamu používateľov: ${
            e.details ? e.details.name : e.name
          }`,
        );
      })
      .finally(() => {
        setIsFetching((prev) => prev - 1);
      });
  }, [appSpace, testTemplateId, offset, limit, reload]);

  const [editingUser, setEditingUser] = useState(null);

  const [isAddingUsers, setIsAddingUsers] = useState(null);

  return (
    <Layout
      topFixed={
        <HeaderBar>
          <HeaderBar.Action
            icon="back"
            as={Link}
            to={`/userspace/${appSpace}/templates`}
            title={__('Testy')}
          />
          <HeaderBar.Header>
            {testTemplateId
              ? `${__('Šablóna testu')}: ${
                  testDetail ? testDetail.name : testTemplateId
                }`
              : `${__('Nová šablóna testu')}`}
          </HeaderBar.Header>
        </HeaderBar>
      }
      bottomFixed={
        <ContextBar>
          <Paginator
            offset={offset}
            onChangeOffset={(newOffset) => {
              query.set('offset', newOffset);
              history.push(
                `/userspace/${appSpace}/template/${testTemplateId}/templateusers?${query.toString()}`,
              );
            }}
            limit={limit}
            nextOffset={nextOffset}
          />
          <ContextBarSpacer />
          {aclIsAllowed(acl, 'templateAddUser') && (
            <ContextBarItem>
              <Button
                primary
                onClick={() => {
                  setIsAddingUsers(true);
                }}
              >
                {__('Pridať používateľov')}
              </Button>
            </ContextBarItem>
          )}
        </ContextBar>
      }
    >
      <TestMenu
        active="Používatelia privátneho testu"
        isPrivate={!!testTemplateId && testDetail && testDetail.private}
      />
      {isFetching > 0 ? (
        <ContentLoader size="xl" />
      ) : (
        <Segment>
          <Segment raised>
            <BasicTable
              columns={[
                { id: 'user', header: __('Používateľ') },
                { id: 'maxRetries', header: __('Počet opakovaní') },
                { id: 'validity', header: __('Platnosť testu') },
                { id: 'firstStartedOn', header: __('Prvé spustenie testu') },
                { id: 'printTest', header: __('') },
              ]}
              rows={templateUsers}
              renderRow={(templateUser) => {
                const {
                  user: { displayName, sportnetId },
                  settings: { maxRetries, validity } = {},
                  retriesCounter = {},
                } = templateUser;
                return [
                  <>
                    <strong>
                      {aclIsAllowed(acl, 'adminUserAllTestsList') ? (
                        <StyledLink
                          to={`/userspace/${appSpace}/users/${sportnetId}/tests`}
                          title={__('Zobraziť všetky testy používateľa')}
                        >
                          {displayName}
                        </StyledLink>
                      ) : (
                        displayName
                      )}
                    </strong>
                    <br />
                    <Faded>{sportnetId}</Faded>
                  </>,
                  maxRetries === null || maxRetries === undefined
                    ? '-'
                    : `${retriesCounter.counter || 0}/${maxRetries}`,
                  (({ from, to, days }) => {
                    const datevalidations = [];
                    if (from) {
                      datevalidations.push(
                        `${__('od')} ${format(
                          new Date(from),
                          'dd.MM.yyyy HH:mm',
                        )}`,
                      );
                    }
                    if (to) {
                      datevalidations.push(
                        `${__('do')} ${format(
                          new Date(to),
                          'dd.MM.yyyy HH:mm',
                        )}`,
                      );
                    }
                    if (days) {
                      datevalidations.push(`${days} dní od prvého spustenia`);
                    }
                    return datevalidations.length
                      ? datevalidations.join(' / ')
                      : '-';
                  })(validity || {}),
                  retriesCounter.firstStartedOn
                    ? format(
                        new Date(retriesCounter.firstStartedOn),
                        'dd.MM.yyyy HH:mm',
                      )
                    : '-',
                  <Button
                    primary
                    icon="print"
                    style={{ whiteSpace: 'nowrap' }}
                    onClick={() => {
                      window.open(
                        `/userspace/${appSpace}/template/${testTemplateId}/test/anonymouse/print?displayName=${encodeURIComponent(
                          `${displayName} (${sportnetId})`,
                        )}`,
                      );
                    }}
                  >
                    {__('Vytlačiť test')}
                  </Button>,
                ];
              }}
              rowKey="_id"
              onClickRow={
                aclIsAllowed(acl, 'templateAddUser')
                  ? (row, index, event) => {
                      let { tagName } = event.target;
                      let parent = event.target.parentNode;
                      while (!['TR', 'A', 'BUTTON'].includes(tagName)) {
                        tagName = parent.tagName;
                        parent = parent.parentNode;
                      }
                      if (tagName === 'A' || tagName === 'BUTTON') {
                        event.stopPropagation();
                      } else if (editingUser && editingUser._id === row._id) {
                        setEditingUser(null);
                      } else {
                        setEditingUser(row);
                      }
                    }
                  : null
              }
            />
          </Segment>
        </Segment>
      )}
      {editingUser ? (
        <Sidebar
          visible
          header={editingUser.user.displayName}
          onClose={() => setEditingUser(null)}
        >
          <UserForm
            user={editingUser}
            onRemove={(editedUser) => {
              const existingUserIndex = templateUsers.findIndex(
                ({ _id }) => _id === editedUser._id,
              );
              if (existingUserIndex >= 0) {
                setTemplateUsers((prev) => {
                  const newUsers = [...prev];
                  newUsers.splice(existingUserIndex, 1);
                  return newUsers;
                });
              }
              setEditingUser(null);
            }}
            onSave={(editedUser) => {
              // vymenime usera v poli userov
              const existingUserIndex = templateUsers.findIndex(
                ({ _id }) => _id === editedUser._id,
              );
              if (existingUserIndex >= 0) {
                setTemplateUsers((prev) => {
                  const newUsers = [...prev];
                  newUsers[existingUserIndex] = editedUser;
                  return newUsers;
                });
              }
              setEditingUser(null);
            }}
          />
        </Sidebar>
      ) : isAddingUsers ? (
        <Sidebar
          visible
          header={__('Pridať nových používateľov')}
          onClose={() => setIsAddingUsers(false)}
        >
          <AddUsersForm
            appSpace={appSpace}
            templateId={testTemplateId}
            onAdd={() => {
              setReload(new Date());
            }}
          />
        </Sidebar>
      ) : null}
    </Layout>
  );
};

TestTemplateUsers.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userspace_id: PropTypes.string,
      test_id: PropTypes.string,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  acl: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => {
  return {
    acl: aclSelector(state),
  };
};

export default connect(mapStateToProps)(TestTemplateUsers);
