import { RECEIVE_USERSPACE_USERS_SUCCESS } from './constants';

export const userspaceUsersReducer = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_USERSPACE_USERS_SUCCESS:
      return action.payload.userspaceUsers;
    default:
      return state;
  }
};
