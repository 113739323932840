import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import Feeds from '@sportnet/ui/Feeds';
import FormField from '@sportnet/ui/FormField';
import FormGroup from '@sportnet/ui/FormGroup';
import Col, { Row } from '@sportnet/ui/Grid';
import HeaderBar from '@sportnet/ui/HeaderBar';
import Input from '@sportnet/ui/Input';
import Label from '@sportnet/ui/Label/Label';
import Loader, { ContentLoader } from '@sportnet/ui/Loader';
import Message from '@sportnet/ui/Message';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import Sidebar from '@sportnet/ui/Sidebar';
import Text from '@sportnet/ui/Text';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import API from '../../API';
import Layout from '../../components/Layout';
import TestMenu from '../../components/TestMenu';
import { aclIsAllowed, getExceptionMessage, __ } from '../../utils';
import { userspaceUsersSelector } from '../Administration/selectors';
import { appSpaceDefinition } from '../Authorization/definitions';
import {
  aclSelector,
  activeAppSpaceSelector,
  appInfoSelector,
  authUserSelector,
} from '../Authorization/selectors';
import QuestionsSettings from './QuestionsSettings';
import { ErrorText } from './styles';
import withSportnetAuth from '../../hoc/withSportnetAuth';
import { compose } from 'redux';

class Tests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCopyingTemplate: false,
      sidebarOpened: false,
      isFetching: false,
      stGroups: [],
      totalQuestions: 0,
      managersById: {},
      isPublicListing: false,
      perex: '',
      picture: null,
      locked: false,
      isInkognito: false,
      isPractice: false,
      isArchived: false,
      isPrivate: false,
      name: '',
      limit: 0,
      passed: 0,
      evaluationType: 'all',
      testVenue: '',
      testPurpose: '',
      testInstitutionName:
        props.userspace && props.userspace.org_profile
          ? props.userspace.org_profile.name
          : '',
      testInstitutionId:
        props.userspace && props.userspace.org_profile
          ? props.userspace.org_profile._id
          : '',
      testLectorName: props.userspace ? props.userspace.display_name || '' : '',
      testLectorId: props.authUser ? props.authUser._id || '' : '',
      testIsCreatable: false,
      isCreatableIsLoading: false,
      created: null,
      appSetup: {
        appId: props.appInfo._id,
        appSpace: this.props.match.params.userspace_id,
        token: props.accessToken,
      },
      formErrors: {
        passed: '',
        limit: '',
      },
    };
    this.handleDefaultChange = this.handleDefaultChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAnonymousePrint = this.handleAnonymousePrint.bind(this);
    this.copyTestURL = this.copyTestURL.bind(this);
    this.handleBooleanChange = this.handleBooleanChange.bind(this);
    this.handleChangeAccess = this.handleChangeAccess.bind(this);
    this.checkIfTestIsCreatable = this.checkIfTestIsCreatable.bind(this);
    this.getIsCreatableMessage = this.getIsCreatableMessage.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.test_id !== this.props.match.params.test_id) {
      this.fetchData();
    }
  }

  getTotalQuestions(gr) {
    return gr.reduce((acc, { count }) => acc + count, 0);
  }

  fetchData() {
    const { params } = this.props.match;
    this.setState({
      isFetching: true,
    });
    if (params.test_id) {
      this.fetchTest();
    } else {
      this.setState({
        isFetching: false,
      });
    }
  }

  fetchTest() {
    const { params } = this.props.match;
    API.templateDetail(params.userspace_id, params.test_id)
      .then(async (res) => {
        const managersById = (res.managers || []).reduce((acc, userId) => {
          acc[userId] = true;
          return acc;
        }, {});
        this.setState({
          isFetching: false,
          name: res.name,
          limit: parseInt(res.limit, 10) / 60,
          locked: res.locked,
          isPublicListing: res.isPublicListing,
          picture: res.picture,
          perex: res.perex,
          isInkognito: res.is_inkognito,
          isPractice: res.type === 'practice',
          isArchived: res.state === 'archived',
          isPrivate: !!res.private,
          stGroups: res.stGroups.map((g, idx) => ({ ...g, _id: idx })),
          totalQuestions: this.getTotalQuestions(res.stGroups),
          managersById,
          passed: parseFloat(res.minPassed * 100).toFixed(2),
          testVenue: res.testVenue,
          testPurpose: res.testPurpose,
          testInstitutionName: res.testInstitutionName,
          testInstitutionId: res.testInstitutionId,
          testLectorName: res.testLectorName,
          testLectorId: res.testLectorId,
          created: res.created,
          maxRetries: res.maxRetries || null,
          validityDays: res.validity ? res.validity.days || null : null,
          validityFrom: res.validity
            ? res.validity.from
              ? new Date(res.validity.from)
              : null
            : null,
          validityTo: res.validity
            ? res.validity.to
              ? new Date(res.validity.to)
              : null
            : null,
        });
        await this.checkIfTestIsCreatable(res.stGroups);
      })
      .catch((e) => {
        console.error(e);
      });
  }

  handleBooleanChange(name) {
    return () => {
      this.setState((prevState) => ({
        [name]: !prevState[name],
      }));
    };
  }

  handleDateChange(name) {
    return (date) => {
      this.setState({
        [name]: date,
      });
    };
  }

  handleDefaultChange(e) {
    console.error(e);
    const {
      target: { name, value },
    } = e;

    switch (name) {
      case 'passed': {
        const parsed = Number.parseInt(value, 10);
        if (Number.isNaN(parsed) || parsed <= 0) {
          this.setState((prev) => ({
            ...prev,
            formErrors: {
              ...prev.formErrors,
              passed: __('Zadajte celé číslo väčšie ako 0, bez znaku "%".'),
            },
            passed: value,
          }));
        } else {
          this.setState((prev) => ({
            ...prev,
            formErrors: {
              ...prev.formErrors,
              passed: '',
            },
            passed: value,
          }));
        }
        break;
      }
      case 'limit': {
        const parsed = Number.parseInt(value, 10);
        if (Number.isNaN(parsed) || parsed <= 0) {
          this.setState((prev) => ({
            ...prev,
            formErrors: {
              ...prev.formErrors,
              limit: __('Zadajte celé číslo väčšie ako 0.'),
            },
            limit: value,
          }));
        } else {
          this.setState((prev) => ({
            ...prev,
            formErrors: {
              ...prev.formErrors,
              limit: '',
            },
            limit: value,
          }));
        }
        break;
      }
      default: {
        this.setState({
          [name]: value,
        });
      }
    }
  }

  validate(data = this.state) {
    let { limit, passed } = data;
    const { name, stGroups } = data;
    let validation = true;
    let error = '';
    limit = parseInt(limit, 10);
    passed = parseInt(passed, 10);
    if (!Number.isInteger(limit) || limit <= 0) {
      validation = false;
      error = __('Časový limit musí byť celé číslo väčšie ako 0.');
    }
    stGroups.forEach((tag) => {
      if (!Number.isInteger(tag.count)) {
        validation = false;
        error = __('Počet otázok z okruhu musí byť zadaný ako celé číslo.');
      }
      return validation;
    });
    if (name === '') {
      validation = false;
      error = __('Zadajte prosím názov testu.');
    }
    if (!Number.isInteger(passed) || passed <= 0) {
      validation = false;
      error = __('Potrebná úspešnosť musí byť celé číslo väčšie ako 0.');
    }
    if (stGroups.length === 0) {
      validation = false;
      error = __('Nie sú zvolené žiadne kritériá otázok');
    } else {
      const numberOfSmartTags = stGroups.reduce((acc, tag) => {
        if (tag.count > 0) {
          return acc + tag.count;
        }
        return acc;
      }, 0);

      if (numberOfSmartTags === 0) {
        validation = false;
        error = __('Celkový počet otázok musí byť vačší ako 0.');
      }
    }

    if (data.maxRetries) {
      const maxRetries = Number(data.maxRetries);
      if (maxRetries <= 0) {
        validation = false;
        error = __('Počet opakovaní musí byť väčší ako 0');
      }
    }

    if (data.validityDays) {
      const validityDays = Number(data.validityDays);
      if (validityDays <= 0) {
        validation = false;
        error = __('Dostupnosť testu musí byť väčší ako 0');
      }
    }

    return {
      result: validation,
      error,
    };
  }

  handleAnonymousePrint() {
    const { userspace_id: userspace, test_id: template } =
      this.props.match.params;
    window.open(
      `/userspace/${userspace}/template/${template}/test/anonymouse/print`,
    );
  }

  handleSubmit() {
    this.setState({
      isFetching: true,
    });
    const { params } = this.props.match;
    const {
      name,
      limit,
      stGroups,
      locked,
      perex,
      picture,
      isPublicListing,
      isInkognito,
      isPractice,
      testVenue,
      testPurpose,
      testInstitutionName,
      testInstitutionId,
      testLectorName,
      testLectorId,
      isArchived,
      isPrivate,
      managersById,
      maxRetries,
      validityDays,
      validityFrom,
      validityTo,
      evaluationType,
    } = this.state;

    const stGroupsPayload = stGroups.reduce((acc, group) => {
      if (group.count > 0) {
        acc.push({
          smarttags: group.smarttags,
          count: parseInt(group.count, 10),
        });
      }
      return acc;
    }, []);

    const type = isPractice ? 'practice' : 'normal';
    const state = isArchived ? 'archived' : 'active';
    const managers = Object.keys(managersById).reduce((acc, userId) => {
      if (managersById[userId]) {
        acc.push(userId);
      }
      return acc;
    }, []);
    const validation = this.validate();
    if (validation.result && stGroups.length > 0) {
      const maxRetriesNumber = Number(maxRetries);
      const validityDaysNumber = Number(validityDays);
      const data = {
        data: {
          name,
          limit: parseInt(limit, 10) * 60,
          stGroups: stGroupsPayload,
          locked,
          perex,
          picture,
          isPublicListing,
          is_inkognito: isInkognito,
          type,
          state,
          private: !!isPrivate,
          minPassed: this.state.passed / 100,
          testVenue,
          testPurpose,
          testInstitutionName,
          testInstitutionId,
          testLectorName,
          testLectorId,
          managers,
          evaluationType,
        },
      };
      if (data.data.private) {
        data.data.validity = {
          from: validityFrom ? new Date(validityFrom) : null,
          to: validityTo ? new Date(validityTo) : null,
          days: validityDaysNumber > 0 ? validityDaysNumber : null,
        };
        data.data.maxRetries = maxRetriesNumber > 0 ? maxRetriesNumber : null;
      }
      if (params.test_id) {
        API.templateUpdate(params.userspace_id, params.test_id, {
          body: data.data,
        })
          .then(() => {
            this.props.history.push(
              `/userspace/${params.userspace_id}/templates`,
            );
          })
          .catch((e) => {
            alert(`Chyba: ${e.details ? e.details.name : e.name}`);
          });
      } else {
        API.templateCreate(params.userspace_id, { body: data.data })
          .then(() => {
            this.props.history.push(
              `/userspace/${params.userspace_id}/templates`,
            );
          })
          .catch((e) => {
            alert(`Chyba: ${e.details ? e.details.name : e.name}`);
          });
      }
    } else {
      window.alert(validation.error);
    }
    this.setState({
      isFetching: false,
    });
  }

  copyTestURL() {
    const link = document.querySelector('#testUrl');
    link.select();
    document.execCommand('copy');
  }

  handleChangeAccess(userId) {
    return () => {
      this.setState((prevState) => ({
        managersById: {
          ...prevState.managersById,
          [userId]: !prevState.managersById[userId],
        },
      }));
    };
  }

  getIsCreatableMessage() {
    if (this.state.isCreatableIsLoading) {
      return (
        <Message primary title={__('Prebieha kontrola testu')}>
          <Loader size="xs" />
        </Message>
      );
    }
    if (this.state.testIsCreatable) {
      return (
        <Message success title={__('Test je možné vytvoriť')}>
          {__('Počet existujúcich otázok je dostatočný na vytvorenie testu.')}
        </Message>
      );
    }
    return (
      <Message danger title={__('Pozor, test nie je možné vytvoriť!')}>
        {__('Počet existujúcich otázok nie je dostatočný na vytvorenie testu.')}
      </Message>
    );
  }

  async checkIfTestIsCreatable(groups) {
    const { params } = this.props.match;
    if (groups && groups.length) {
      this.setState({ isCreatableIsLoading: true });
      try {
        const response = await API.isTestCreatableBasedOnGroups(
          params.userspace_id,
          {
            body: groups.map((p) => ({
              count: p.count,
              smarttags: p.smarttags,
            })),
          },
        );
        this.setState({ testIsCreatable: response.isCreatable });
      } catch (e) {
        let m = `${__('Chyba pri kontrole, či je test vytvoriťeľný!')}`;
        m += ` ${getExceptionMessage(e)}`;
        alert(m);
      } finally {
        this.setState({ isCreatableIsLoading: false });
      }
    }
  }

  getTestPublicUrl(templateId) {
    return `${process.env.REACT_APP_TEST_BASE_URL}/?template=${templateId}`;
  }

  formHasErrors() {
    const fieldsWithError = Object.values(this.state.formErrors).filter(
      (item) => item !== '',
    );
    return fieldsWithError.length > 0;
  }

  handleCopyTemplate = async () => {
    const {
      match: {
        params: { test_id: templateId },
      },
      history: { push },
    } = this.props;
    const { ppo: appSpace } = this.props.auth;
    if (window.confirm(__('Skutočne si želáte vytvoriť kópiu šablóny?'))) {
      this.setState({ isCopyingTemplate: true });
      try {
        const res = await API.templateCopy(appSpace, templateId);
        push(`/userspace/${appSpace}/template/${res._id}`);
      } catch (e) {
        console.error(e);
        alert(__('Šablónu testu nebolo možné skopírovať.'));
      } finally {
        this.setState({ isCopyingTemplate: false });
      }
    }
  };

  render() {
    const { params } = this.props.match;
    const { userspaceUsers } = this.props;
    const { managersById, formErrors } = this.state;
    const canEditTemplate = aclIsAllowed(this.props.acl, 'templateUpdate');
    const { accessToken, ppo: appSpace } = this.props.auth;

    return (
      <Layout
        topFixed={
          <HeaderBar>
            <HeaderBar.Action
              icon="back"
              as={Link}
              to={`/userspace/${this.props.match.params.userspace_id}/templates`}
              title={__('Testy')}
            />
            <HeaderBar.Header>
              {this.props.match.params.test_id
                ? `${__('Šablóna testu')}: ${
                    this.state.name || this.props.match.params.test_id
                  }`
                : `${__('Nová šablóna testu')}`}
            </HeaderBar.Header>
            <HeaderBar.Spacer />
            {params.test_id && (
              <HeaderBar.Action
                icon="settings"
                onClick={() =>
                  this.setState((state) => {
                    return {
                      ...state,
                      sidebarOpened: !state.sidebarOpened,
                    };
                  })
                }
              >
                {!this.state.sidebarOpened && __('Nastavenia')}
              </HeaderBar.Action>
            )}
          </HeaderBar>
        }
        bottomFixed={
          <ContextBar>
            <ContextBarSpacer />
            {params.test_id && (
              <ContextBarItem>
                <Button
                  basic
                  onClick={this.handleCopyTemplate}
                  loading={this.state.isCopyingTemplate}
                >
                  {__('Skopírovať šablónu')}
                </Button>
                &nbsp;
                <Button
                  basic
                  onClick={this.handleAnonymousePrint}
                  disabled={!this.state.testIsCreatable}
                >
                  {__('Vytlačiť anonymný test')}
                </Button>
                {canEditTemplate && (
                  <>
                    &nbsp;
                    <Button
                      primary
                      disabled={this.formHasErrors()}
                      onClick={this.handleSubmit}
                    >
                      {__('Uložiť')}
                    </Button>
                  </>
                )}
              </ContextBarItem>
            )}
            {!params.test_id && (
              <ContextBarItem>
                <Button
                  primary
                  disabled={this.formHasErrors()}
                  onClick={this.handleSubmit}
                >
                  {__('Vytvoriť')}
                </Button>
              </ContextBarItem>
            )}
          </ContextBar>
        }
      >
        <TestMenu
          active={params.test_id ? 'Úprava šablóny testu' : 'Nový test'}
          isPrivate={!!this.props.match.params.test_id && this.state.isPrivate}
        />
        <Segment>
          {this.state.isFetching ? (
            <ContentLoader size="xl" />
          ) : (
            <>
              <Segment
                raised
                header={
                  <SegmentHeader
                    withSeparator
                    size="s"
                    disabled={!canEditTemplate}
                  >
                    {__('Základné nastavenia')}
                  </SegmentHeader>
                }
              >
                <Row>
                  <Col m={6}>
                    <FormField
                      label={__('Názov testu')}
                      type="text"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleDefaultChange}
                      placeholder={__('Názov testu')}
                      required
                      disabled={!canEditTemplate}
                    />
                  </Col>
                  <Col m={6}>
                    <FormField
                      label={__('Miesto konania skúšky')}
                      type="text"
                      name="testVenue"
                      value={this.state.testVenue}
                      onChange={this.handleDefaultChange}
                      placeholder={__('(nepovinné)')}
                      disabled={!canEditTemplate}
                    />
                  </Col>
                  <Col m={6}>
                    <FormField
                      label={__('Účel vykonania skúšky')}
                      type="text"
                      name="testPurpose"
                      value={this.state.testPurpose}
                      onChange={this.handleDefaultChange}
                      placeholder={__('(nepovinné)')}
                      disabled={!canEditTemplate}
                    />
                  </Col>
                  <Col m={6}>
                    <FormField
                      label={__('Osoba, ktorá vedie skúšku (skúšajúci)')}
                      type="text"
                      name="testLectorName"
                      value={this.state.testLectorName}
                      onChange={this.handleDefaultChange}
                      placeholder={__('(nepovinné)')}
                      disabled={!canEditTemplate}
                    />
                  </Col>
                  <Col m={6}>
                    <FormField
                      label={__('Inštitúcia vykonávajúca skúšku')}
                      type="text"
                      name="testInstitutionName"
                      value={this.state.testInstitutionName}
                      onChange={this.handleDefaultChange}
                      placeholder={__('(nepovinné)')}
                      disabled={!canEditTemplate}
                    />
                  </Col>
                  <Col m={6}>
                    <FormField
                      label={__('Identifikačné číslo inšitúcie')}
                      type="text"
                      name="testInstitutionId"
                      value={this.state.testInstitutionId}
                      onChange={this.handleDefaultChange}
                      placeholder={__('Identifikačné číslo')}
                      disabled={!canEditTemplate}
                    />
                  </Col>
                  <Col m={6}>
                    <FormField
                      label={__('Identifikačné číslo skúšajúceho')}
                      type="text"
                      name="testLectorId"
                      value={this.state.testLectorId}
                      onChange={this.handleDefaultChange}
                      placeholder={__('Identifikačné číslo')}
                      disabled={!canEditTemplate}
                    />
                  </Col>
                  <Col m={3}>
                    <FormGroup>
                      <Label
                        required
                        info={__('Zadávajte celé číslo väčšie ako 0.')}
                      >
                        {__('Časový limit (v minútach)')}
                      </Label>
                      <Input
                        type="number"
                        name="limit"
                        value={this.state.limit}
                        onChange={this.handleDefaultChange}
                        error={formErrors.limit}
                        min="0"
                        disabled={!canEditTemplate}
                      />
                      {formErrors.limit && (
                        <ErrorText>
                          <Text danger>{formErrors.limit}</Text>
                        </ErrorText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col m={3}>
                    <FormField
                      label={__('Spôsob vyhodnotenia')}
                      type="theselectsimple"
                      clearable={false}
                      name="evaluationType"
                      value={this.state.evaluationType}
                      onChange={(i) => this.setState({ evaluationType: i })}
                      required
                      disabled={!canEditTemplate}
                      options={[
                        { label: __('Všetky'), value: 'all' },
                        { label: __('Len nesprávne'), value: 'incorrect' },
                      ]}
                    />
                  </Col>
                  <Col m={12}>
                    <Row>
                      <Col m={6}>
                        <FormField
                          label={__('Zaradiť test do zoznamu verejných testov')}
                          type="checkbox"
                          value={this.state.isPublicListing}
                          checked={this.state.isPublicListing}
                          onChange={this.handleBooleanChange('isPublicListing')}
                          disabled={!canEditTemplate}
                        />
                      </Col>
                      <Col m={6}>
                        <FormField
                          label={__('Povoliť testovanie')}
                          type="checkbox"
                          value={!this.state.locked}
                          checked={!this.state.locked}
                          onChange={this.handleBooleanChange('locked')}
                          disabled={!canEditTemplate}
                        />
                      </Col>
                      <Col m={6}>
                        <FormField
                          label={__('Inkognito mód')}
                          type="checkbox"
                          value={this.state.isInkognito}
                          checked={this.state.isInkognito}
                          onChange={this.handleBooleanChange('isInkognito')}
                          disabled={!canEditTemplate}
                        />
                      </Col>
                      <Col m={6}>
                        <FormField
                          label={__('Cvičný test')}
                          type="checkbox"
                          value={this.state.isPractice}
                          checked={this.state.isPractice}
                          onChange={this.handleBooleanChange('isPractice')}
                          disabled={!canEditTemplate}
                        />
                      </Col>
                      <Col m={6}>
                        <FormField
                          label={__('Archívny test')}
                          type="checkbox"
                          value={this.state.isArchived}
                          checked={this.state.isArchived}
                          onChange={this.handleBooleanChange('isArchived')}
                          disabled={!canEditTemplate}
                        />
                      </Col>
                      <Col m={6}>
                        <FormField
                          label={__('Privátny test')}
                          type="checkbox"
                          value={this.state.isPrivate}
                          checked={this.state.isPrivate}
                          onChange={this.handleBooleanChange('isPrivate')}
                          disabled={!canEditTemplate}
                        />
                      </Col>
                    </Row>
                  </Col>
                  {this.state.isPrivate && (
                    <Col m={12}>
                      <Row>
                        <Col m={3}>
                          <FormField
                            label={__('Počet opakovaní')}
                            info={__(
                              'Maximálny počet opakovaní testu na používateľa',
                            )}
                            name="maxRetries"
                            type="text"
                            value={this.state.maxRetries}
                            onChange={this.handleDefaultChange}
                            placeholder={__('neobmedzene')}
                            disabled={!canEditTemplate}
                          />
                        </Col>
                        <Col m={3}>
                          <FormField
                            label={__('Dostupnosť testu v dňoch')}
                            info={__(
                              'Počet dní dostupnosti testu na riešenie od prvého spustenia',
                            )}
                            name="validityDays"
                            type="text"
                            value={this.state.validityDays}
                            onChange={this.handleDefaultChange}
                            placeholder={__('neobmedzene')}
                            disabled={!canEditTemplate}
                          />
                        </Col>
                        <Col m={3}>
                          <FormField
                            label={__('Platnosť testu od')}
                            name="validityFrom"
                            type="dateTime"
                            value={this.state.validityFrom}
                            onChange={this.handleDateChange('validityFrom')}
                            placeholder={__('bez obmedzenia')}
                            disabled={!canEditTemplate}
                          />
                        </Col>
                        <Col m={3}>
                          <FormField
                            label={__('Platnosť testu do')}
                            name="validityTo"
                            type="dateTime"
                            value={this.state.validityTo}
                            onChange={this.handleDateChange('validityTo')}
                            placeholder={__('bez obmedzenia')}
                            disabled={!canEditTemplate}
                          />
                        </Col>
                      </Row>
                    </Col>
                  )}
                  <Col m={12}>
                    <Row>
                      <Col m={6}>
                        <FormGroup>
                          <Label
                            required
                            info={__(
                              'Zadávajte celé číslo, väčšie ako 0,  bez znaku %',
                            )}
                          >
                            {__('Potrebná úspešnosť testu v percentách')}
                          </Label>
                          <Input
                            type="number"
                            label={__('Potrebná úspešnosť testu v percentách')}
                            name="passed"
                            value={this.state.passed}
                            onChange={this.handleDefaultChange}
                            error={formErrors.passed}
                            min="0"
                            disabled={!canEditTemplate}
                          />
                          {formErrors.passed && (
                            <ErrorText>
                              <Text danger>{formErrors.passed}</Text>
                            </ErrorText>
                          )}
                        </FormGroup>
                      </Col>
                      {params.test_id && (
                        <Col m={6}>
                          <FormField
                            label={`${__(
                              'Odkaz na test (kliknutím sa skopíruje do schránky)',
                            )}:`}
                            type="text"
                            name="testUrl"
                            value={this.getTestPublicUrl(params.test_id)}
                            onChange={() => {}}
                            readOnly
                            onClick={this.copyTestURL}
                            id="testUrl"
                          />
                        </Col>
                      )}
                    </Row>
                  </Col>
                  <Col m={6}>
                    <FormField
                      label={__('Perex')}
                      type="textarea"
                      name="perex"
                      value={this.state.perex}
                      onChange={this.handleDefaultChange}
                      disabled={!canEditTemplate}
                    />
                  </Col>
                  <Col m={6}>
                    <FormField
                      label={__('Náhľadový obrázok')}
                      type="image"
                      name="picture"
                      auth={this.state.appSetup}
                      value={this.state.picture}
                      onChange={(e) => {
                        this.setState({
                          picture: e,
                        });
                      }}
                      disabled={!canEditTemplate}
                    />
                  </Col>
                </Row>
              </Segment>

              <Segment
                raised
                header={
                  <SegmentHeader
                    withSeparator
                    collapsible
                    size="s"
                    isCollapsed={canEditTemplate}
                    disabled={!canEditTemplate}
                  >
                    {__('Kritériá otázok')}
                  </SegmentHeader>
                }
              >
                {this.getIsCreatableMessage()}
                <QuestionsSettings
                  payload={this.state.stGroups}
                  totalQuestions={this.state.totalQuestions}
                  onChange={(e) => {
                    this.setState({
                      stGroups: e,
                      totalQuestions: this.getTotalQuestions(e),
                    });
                    this.checkIfTestIsCreatable(e);
                  }}
                  editIsDisabled={!canEditTemplate}
                />
              </Segment>

              <Segment
                raised
                header={
                  <SegmentHeader
                    withSeparator
                    collapsible
                    size="s"
                    isCollapsed={canEditTemplate}
                    disabled={!canEditTemplate}
                  >
                    {__('Pridelenie práva spravovať test')}
                  </SegmentHeader>
                }
              >
                <Row>
                  {userspaceUsers.map((user) => {
                    const userId = user.user_id;
                    const value = !!managersById[userId];
                    return (
                      <Col s="6" m="4" l="3">
                        <FormField
                          key={userId}
                          label={`${user.display_name} (${user.role})`}
                          type="checkbox"
                          value={value}
                          checked={value}
                          onChange={this.handleChangeAccess(userId)}
                          disabled={!canEditTemplate}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </Segment>
            </>
          )}
        </Segment>
        <Sidebar
          visible={this.state.sidebarOpened}
          onClose={() => {
            this.setState({ sidebarOpened: false });
          }}
          header={__('Nastavenia')}
        >
          <Segment
            header={
              <SegmentHeader collapsible withSeparator size="s">
                {__('Feeds')}
              </SegmentHeader>
            }
            noBottomGutter
            raised
          >
            <Feeds
              accessToken={accessToken}
              appId={this.state.appSetup.appId}
              appSpace={appSpace}
              itemId={params.test_id}
              fieldNameMap={{
                title: __('Názov testu'),
                date: __('Dátum hlasovania od'),
                url: __('URL'),
              }}
              value={{
                title: this.state.name,
                subtitle: this.state.perex,
                date: this.state.created,
                url: this.getTestPublicUrl(params.test_id),
                image: this.state.picture,
              }}
            />
          </Segment>
        </Sidebar>
      </Layout>
    );
  }
}

Tests.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userspace_id: PropTypes.string.isRequired,
      test_id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  userspace: appSpaceDefinition.isRequired,
  authUser: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
  accessToken: PropTypes.string.isRequired,
  userspaceUsers: PropTypes.arrayOf(
    PropTypes.shape({
      display_name: PropTypes.string.isRequired,
      user_id: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
    }),
  ).isRequired,
  appInfo: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
  acl: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => {
  return {
    authUser: authUserSelector(state),
    userspace: activeAppSpaceSelector(state),
    userspaceUsers: userspaceUsersSelector(state),
    appInfo: appInfoSelector(state),
    acl: aclSelector(state),
  };
};

export const TestComponent = Tests;
export default compose(
  withSportnetAuth,
  withRouter,
  connect(mapStateToProps),
)(Tests);
