import DefaultFilter from '@sportnet/ui/Filter';
import PropTypes from 'prop-types';
import React from 'react';
import TagmanagerConnector, {
  smarttagsToUrl,
  urlToSmarttags,
} from '@sportnet/tagmanager-connector';
import { __ } from '../../utils';
import { useAuth } from '@sportnet/auth-react';

const QuestionsFilter = ({ setParameters, parameters }) => {
  const { accessToken, ppo: appSpace } = useAuth();
  return (
    <TagmanagerConnector
      appId={process.env.REACT_APP_APP_ID}
      appspace={appSpace}
      accessToken={accessToken}
    >
      {(getSmarttagsKeys, getSmarttagsValues, getSmarttagsByFts) => (
        <DefaultFilter
          value={{
            q: parameters.q,
            smarttags: urlToSmarttags(parameters.smarttags),
          }}
          onChange={(nextFilterValue) => {
            setParameters({
              q: nextFilterValue.q || '',
              smarttags: nextFilterValue.smarttags
                ? smarttagsToUrl(nextFilterValue.smarttags)
                : undefined,
            });
          }}
          filters={[
            {
              type: 'query',
              name: 'q',
              placeholder: __('Zadajte znenie otázky'),
            },
            {
              type: 'smarttag',
              label: 'smart:tag',
              name: 'smarttags',
              getSmarttagsKeys,
              getSmarttagsValues,
              getSmarttagsByFts,
            },
          ]}
        />
      )}
    </TagmanagerConnector>
  );
};

QuestionsFilter.propTypes = {
  setParameters: PropTypes.func.isRequired,
  parameters: PropTypes.shape({
    q: PropTypes.string,
    smarttags: PropTypes.string,
  }).isRequired,
};

export default QuestionsFilter;
