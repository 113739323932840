import { format } from 'date-fns';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { FinishedPartName, getSeminarUserStatusName } from '.';
import { __ } from '../../utils';

const Dl = styled.dl`
  margin: 0;
  font-size: ${rem(12)};
`;
const Dt = styled.dt`
  margin: 0;
  font-weight: bold;
  border-top: 1px solid #aeaeae;
  padding-top: ${rem(8)};
  margin-top: ${rem(8)};
  :first-child {
    border-top: 1px solid white;
    padding-top: ${rem(0)};
    margin-top: ${rem(0)};
  }
`;
const Dd = styled.dd`
  margin: 0;
`;

const SeminarUserLogEntry = ({ datetime, status, payload, user, seminar }) => {
  const seminarPart =
    seminar && payload.finishedPart
      ? seminar.parts.find(({ _id }) => _id === payload.finishedPart._id)
      : null;
  return (
    <>
      <Dt>
        {format(new Date(datetime), 'dd.MM.yyyy HH:ii:ss')} -{' '}
        {getSeminarUserStatusName(status)}
      </Dt>
      <Dd>{user?.displayName}</Dd>
      {payload.finishedPart && (
        <Dd>
          {__('Ukončenie časti')}
          {seminarPart && (
            <>
              :
              <FinishedPartName
                title={`${seminarPart.nr}. ${seminarPart.name}`}
              >{`${seminarPart.nr}. ${seminarPart.name}`}</FinishedPartName>
            </>
          )}
        </Dd>
      )}
      {payload.finalUnlockedParts && <Dd>{__('Odomknutie ďalších častí')}</Dd>}
    </>
  );
};

SeminarUserLogEntry.propTypes = {
  datetime: PropTypes.string.isRequired,
  user: PropTypes.shape({
    sportnetId: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
  }).isRequired,
  status: PropTypes.string.isRequired,
  payload: PropTypes.shape(),
  seminar: PropTypes.shape(),
};

SeminarUserLogEntry.defaultProps = {
  payload: {},
};

const SeminarUserLogs = ({ logs, seminar }) => {
  return (
    <Dl>
      {logs.map((l) => (
        <SeminarUserLogEntry {...l} seminar={seminar} />
      ))}
    </Dl>
  );
};

export default SeminarUserLogs;
