import { __ } from '../../../utils';
import Button from '@sportnet/ui/Button';
import Header from '@sportnet/ui/Header';
import Message from '@sportnet/ui/Message';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import React from 'react';
import FormField from '@sportnet/ui/FormField';
import SportnetApi from '../../../SportnetApi';
import { activeAppSpaceSelector } from '../../../containers/Authorization/selectors';
import { connect } from 'react-redux';

const MessageModal = ({
  isOpen,
  onClose,
  recipientSportnetIds = [],
  activeAppSpace,
}) => {
  const [subject, setSubject] = React.useState('');
  const [perex, setPerex] = React.useState('');
  const [isSending, setIsSending] = React.useState(false);
  const [isSent, setIsSent] = React.useState(false);
  const sendMessage = () => {
    if (!subject || !perex) {
      return;
    }
    setIsSending(true);
    SportnetApi.crmSendMessages(activeAppSpace.app_space, {
      data: {
        message_parameters: { subject, perex },
        recipient_parameters: {
          targetPPO: activeAppSpace.app_space,
          users: recipientSportnetIds,
        },
      },
    })
      .then(() => {
        setIsSent(true);
        window.setTimeout(() => {
          setIsSent(false);
          onClose();
        }, 2000);
      })
      .catch((e) => {
        alert('Chyba pri odosielaní správy ' + e.message);
      })
      .finally(() => {
        setIsSending(false);
      });
  };
  return (
    <Modal handleClose={onClose} isOpen={isOpen}>
      <ModalContent>
        <Header size="s" withSeparator>
          {__('Správa používateľom')}
        </Header>
        <Message warning>
          {`${__('Počet označných používateľov')}: `}
          <b>{recipientSportnetIds.length}</b>
        </Message>
        {isOpen && (
          <>
            <FormField
              label={__('Odosielateľ')}
              readOnly
              value={activeAppSpace.org_profile.name}
            />
            <FormField
              disabled={isSending}
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              label={__('Predmet')}
              required
            />
            <FormField
              disabled={isSending}
              type="textarea"
              value={perex}
              onChange={(e) => setPerex(e.target.value)}
              label={__('Správa')}
              rows={8}
              required
            />
          </>
        )}
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose}>{__('Zavrieť')}</Button>
        <Button
          primary={!isSent}
          success={isSent}
          icon={isSent ? 'check' : null}
          onClick={sendMessage}
          disabled={isSending || !subject || !perex}
          loading={isSending}
        >
          {isSent ? __('Odoslané') : __('Odoslať')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default connect((state) => ({
  activeAppSpace: activeAppSpaceSelector(state),
}))(MessageModal);
