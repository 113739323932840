import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import HeaderBar from '@sportnet/ui/HeaderBar';
import NotFound from '@sportnet/ui/NotFound';
import Paginator from '@sportnet/ui/Paginator';
import Segment from '@sportnet/ui/Segment';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import PropTypes from 'prop-types';
import connectQueryHoc, { QueryHocTypes } from '@sportnet/query-hoc';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import API from '../../API';
import Layout from '../../components/Layout';
import { __ } from '../../utils';
import { activeAppSpaceSelector } from '../Authorization/selectors';

class SeminarsList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      seminars: [],
    };
  }

  componentDidMount() {
    this.loadSeminars();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.serializedQuery !== nextProps.serializedQuery) {
      this.loadSeminars(nextProps);
    }
  }

  getCurrentResultObject() {
    const { serializedQuery } = this.props;
    return this.state[serializedQuery] || {};
  }

  loadSeminars = async (props = this.props) => {
    const {
      query: { offset, limit },
      serializedQuery,
      match: {
        params: { userspace_id: userspaceId },
      },
    } = props;
    this.setState((prevState) => {
      return {
        [serializedQuery]: {
          ...(prevState[serializedQuery] || {}),
          isCommiting: true,
        },
      };
    });
    try {
      const params = {
        offset,
        limit,
      };
      const { seminars, total } = await API.seminarsList(userspaceId, params);
      this.setState({
        [serializedQuery]: {
          seminars,
          total,
          isCommiting: false,
        },
      });
    } catch (e) {
      console.error(e);
      this.setState({
        [serializedQuery]: {
          error: e,
          isCommiting: false,
        },
      });
    }
  };

  handleSeminarClick = (testId) => {
    this.props.history.push(
      `/userspace/${this.props.match.params.userspace_id}/seminar/${testId}`,
    );
  };

  handleChangeParameter(name) {
    return (value) => {
      const update = { [name]: value };
      // reset paginator
      if (name !== 'offset') {
        update.offset = 0;
      }
      this.props.setParameter(update);
    };
  }

  render() {
    const { total = 0, seminars, isCommiting } = this.getCurrentResultObject();
    const {
      query: { offset, limit },
    } = this.props;
    const topFixed = (
      <>
        <HeaderBar>
          <HeaderBar.Header>{__('Zoznam seminárov')}</HeaderBar.Header>
        </HeaderBar>
      </>
    );

    const bottomFixed = (
      <ContextBar>
        <Paginator
          total={total}
          offset={offset}
          onChangeOffset={this.handleChangeParameter('offset')}
          limit={limit}
          step={limit}
        />
        <ContextBarSpacer />
        <ContextBarItem>
          <Link
            to={`/userspace/${this.props.match.params.userspace_id}/seminar`}
            style={{ textDecoration: 'none', background: 'inherit' }}
          >
            <Button primary icon="plus">
              {__('Pridať seminár')}
            </Button>
          </Link>
        </ContextBarItem>
      </ContextBar>
    );

    return (
      <Layout topFixed={topFixed} bottomFixed={bottomFixed}>
        <Segment noBottomGutter>
          <Segment raised loading={!seminars || isCommiting}>
            {seminars && seminars.length === 0 ? (
              <NotFound
                icon="file"
                title={__('Pre Vašu organizáciu sa nenašli žiadne semináre.')}
              />
            ) : (
              <Table>
                <Thead>
                  <Tr>
                    <Th>{__('Názov')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {seminars &&
                    seminars.map((seminar) => {
                      return (
                        <Tr
                          key={seminar._id}
                          onClick={() => this.handleSeminarClick(seminar._id)}
                        >
                          <Td>{seminar.name}</Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            )}
          </Segment>
        </Segment>
      </Layout>
    );
  }
}

SeminarsList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userspace_id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  userspace: PropTypes.shape({
    org_profile: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  query: PropTypes.shape({
    state: PropTypes.arrayOf(PropTypes.string).isRequired,
    type: PropTypes.arrayOf(PropTypes.string).isRequired,
    offset: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
  }).isRequired,
  setParameter: PropTypes.func.isRequired,
  serializedQuery: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    userspace: activeAppSpaceSelector(state),
  };
};

export default compose(
  connect(mapStateToProps),
  connectQueryHoc({
    parameters: {
      type: {
        type: QueryHocTypes.Array,
        defaultValue: [],
        delimiter: ',',
      },
      state: {
        type: QueryHocTypes.Array,
        defaultValue: [],
        delimiter: ',',
      },
      offset: {
        type: QueryHocTypes.Number,
        defaultValue: 0,
      },
      limit: {
        type: QueryHocTypes.Number,
        defaultValue: 100,
      },
    },
  }),
)(SeminarsList);
