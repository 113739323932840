import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import HeaderBar from '@sportnet/ui/HeaderBar';
import NotFound from '@sportnet/ui/NotFound';
import Paginator from '@sportnet/ui/Paginator';
import Segment from '@sportnet/ui/Segment';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import connectQueryHoc, { QueryHocTypes } from '@sportnet/query-hoc';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import styled from 'styled-components';
import SmarttagTags from '@sportnet/ui/SmarttagsControl/SmarttagTags';
import API from '../../API';
import Layout from '../../components/Layout';
import { nl2br, __, formatDate } from '../../utils';
import {
  aclSelector,
  activeAppSpaceSelector,
} from '../Authorization/selectors';
import QuestionsFilter from './QuestionsFilter';

const Tags = styled.div`
  font-size: 12px;
  padding-top: 10px;
  font-style: italic;
`;

const NotFoundWrapper = styled.div`
  margin-top: ${rem(30)};
`;

class QuestionsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      smarttags: [],
      queryParameters: {
        q: '',
        smarttags: '',
      },
    };

    this.handleQuestionClick = this.handleQuestionClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.loadQuestions = this.loadQuestions.bind(this);
    this.getCurrentResultObject = this.getCurrentResultObject.bind(this);
    this.smarttagsToQuery = this.smarttagsToQuery.bind(this);
    this.handleChangeParameter = this.handleChangeParameter.bind(this);
    this.setAclAccess = this.setAclAccess.bind(this);
    this.handleQuestionsPrint = this.handleQuestionsPrint.bind(this);
    this.getSmarttags = this.getSmarttags.bind(this);
  }

  componentDidMount() {
    this.loadQuestions(this.props);
    this.setAclAccess(this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.serializedQuery !== prevProps.serializedQuery) {
      this.loadQuestions(this.props);
    }
    this.setAclAccess(this.props);
  }

  setAclAccess(props) {
    if (props.acl && (!props.acl.questionList || !props.acl.questionCreate)) {
      const { userspace_id: userspaceId } = this.props.match.params;
      this.props.history.push(`/userspace/${userspaceId}/templates`);
    }
  }

  getCurrentResultObject() {
    const { serializedQuery } = this.props;
    return this.state[serializedQuery] || {};
  }

  getSmarttags(q) {
    if (q.smarttags && q.smarttags.length) {
      return <SmarttagTags items={q.smarttags} />;
    }
    return null;
  }

  async loadQuestions(props) {
    const {
      query: { smarttags, q, offset, limit },
      serializedQuery,
      match: {
        params: { userspace_id: userspaceId },
      },
    } = props;
    this.setState((prevState) => {
      return {
        [serializedQuery]: {
          ...(prevState[serializedQuery] || {}),
          isCommiting: true,
        },
      };
    });
    try {
      const params = {
        offset,
        limit,
      };
      if (q) {
        params.q = q;
      }
      if (smarttags) {
        params.smarttags = smarttags;
      }
      const { questions, total } = await API.questionList(userspaceId, params);
      this.setState({
        [serializedQuery]: {
          questions,
          total,
          isCommiting: false,
        },
      });
    } catch (e) {
      console.error(e);
      this.setState({
        [serializedQuery]: {
          error: e,
          isCommiting: false,
        },
      });
    }
  }

  handleQuestionClick(questionId) {
    this.props.history.push(
      `/userspace/${this.props.match.params.userspace_id}/question/${questionId}`,
    );
  }

  handleOpenNewTabClick(event, questionId) {
    event.stopPropagation();
    window.open(
      `/userspace/${this.props.match.params.userspace_id}/question/${questionId}`,
    );
  }

  handleDeleteClick(event, questionId) {
    event.stopPropagation();
    const confirm = window.confirm('Skutočne si želáte odstrániť otázku?');
    if (confirm) {
      API.questionDelete(this.props.match.params.userspace_id, questionId)
        .then(() => {
          this.loadQuestions(this.props);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }

  smarttagsToQuery(stags) {
    return stags.reduce((acc, st) => {
      const keys = st.values.map((v) => v.key);
      const squery = `${st.key}:${keys.join(',')}`;
      return [...acc, squery];
    }, []);
  }

  handleChangeParameter(name) {
    return (value) => {
      this.props.setParameter({ [name]: value });
    };
  }

  handleQuestionsPrint(withAnswers = false) {
    const { total = 0 } = this.getCurrentResultObject();
    const url = `/userspace/${
      this.props.match.params.userspace_id
    }/questions-print?${Object.entries({
      ...(this.props.query || {}),
      withAnswers,
    })
      .map(([k, v]) => {
        if (k === 'offset') v = 0;
        if (k === 'limit') v = total;
        return [k, v].map(encodeURIComponent).join('=');
      })
      .join('&')}`;
    window.open(url);
  }

  render() {
    const { total = 0, questions, isCommiting } = this.getCurrentResultObject();
    const {
      query: { limit, offset, q, smarttags },
      match: {
        params: { userspace_id: appSpace },
      },
    } = this.props;
    const topFixed = (
      <>
        <HeaderBar>
          <HeaderBar.Header>{__('Zoznam otázok')}</HeaderBar.Header>
        </HeaderBar>
        <QuestionsFilter
          appSpace={appSpace}
          parameters={{ q, smarttags }}
          setParameters={(parameters) => {
            this.props.setParameter(parameters);
          }}
        />
      </>
    );

    const bottomFixed = (
      <ContextBar>
        {total > 0 && (
          <>
            <Paginator
              total={total}
              offset={offset}
              onChangeOffset={this.handleChangeParameter('offset')}
              limit={limit}
              step={limit}
            />
            &nbsp;
            {__('Celkom')} {total} {__('otázok')}
          </>
        )}
        <ContextBarSpacer />
        <ContextBarItem>
          <Button basic onClick={() => this.handleQuestionsPrint(true)}>
            {__('Vytlačiť otázky ako anonymný test s odpoveďami')}
          </Button>
        </ContextBarItem>
        <ContextBarItem>
          <Button basic onClick={() => this.handleQuestionsPrint(false)}>
            {__('Vytlačiť otázky ako anonymný test')}
          </Button>
        </ContextBarItem>
        <ContextBarItem>
          <Link
            to={`/userspace/${this.props.match.params.userspace_id}/question`}
            style={{ textDecoration: 'none', background: 'inherit' }}
          >
            <Button primary icon="plus">
              {__('Pridať otázku')}
            </Button>
          </Link>
        </ContextBarItem>
      </ContextBar>
    );

    return (
      <Layout topFixed={topFixed} bottomFixed={bottomFixed}>
        <Segment noBottomGutter>
          <Segment raised loading={!questions || isCommiting}>
            {questions && questions.length === 0 ? (
              <NotFoundWrapper>
                <NotFound
                  icon="file"
                  title={__('Pre Vašu organizáciu sa nenašli žiadne otázky')}
                />
              </NotFoundWrapper>
            ) : (
              <Table>
                <Thead>
                  <Tr>
                    <Th center>{__('Dátum vytvorenia')}</Th>
                    <Th center>{__('Otázka')}</Th>
                    <Th center>{__('Akcia')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {questions &&
                    questions.map((question) => {
                      return (
                        <Tr
                          key={question._id}
                          onClick={() => this.handleQuestionClick(question._id)}
                        >
                          <Td>{formatDate(question.created)}</Td>
                          <Td>
                            {nl2br(question.text)}
                            <Tags>{this.getSmarttags(question)}</Tags>
                          </Td>
                          <Td center width="100px">
                            <Button
                              onClick={(e) => {
                                this.handleDeleteClick(e, question._id);
                              }}
                              danger
                              icon="close"
                            />{' '}
                            <Button
                              onClick={(e) => {
                                this.handleOpenNewTabClick(e, question._id);
                              }}
                              primary
                              icon="pencil"
                            />
                          </Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            )}
          </Segment>
        </Segment>
      </Layout>
    );
  }
}

QuestionsList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userspace_id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  userspace: PropTypes.shape({
    org_profile: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      sport: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      organization_id: PropTypes.string.isRequired,
      organization_name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  query: PropTypes.shape({
    q: PropTypes.string.isRequired,
    offset: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    smarttags: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  setParameter: PropTypes.func.isRequired,
  serializedQuery: PropTypes.string.isRequired,
  acl: PropTypes.shape({}),
};

QuestionsList.defaultProps = {
  acl: null,
};

const mapStateToProps = (state) => {
  return {
    userspace: activeAppSpaceSelector(state),
    acl: aclSelector(state),
  };
};

export const questionsQueryParameters = {
  smarttags: {
    type: QueryHocTypes.String,
    defaultValue: '',
  },
  q: {
    type: QueryHocTypes.String,
    defaultValue: '',
  },
  offset: {
    type: QueryHocTypes.Number,
    defaultValue: 0,
  },
  limit: {
    type: QueryHocTypes.Number,
    defaultValue: 100,
  },
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  connectQueryHoc({
    parameters: questionsQueryParameters,
  }),
)(QuestionsList);
