import Tabber from '@sportnet/ui/Tabber';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { activeAppSpaceSelector } from '../../containers/Authorization/selectors';

class TestMenu extends PureComponent {
  constructor(props) {
    super(props);

    this.handleClickEditTestTab = this.handleClickEditTestTab.bind(this);
    this.handleClickTestsListTab = this.handleClickTestsListTab.bind(this);
    this.handleClickParticipantsListTab =
      this.handleClickParticipantsListTab.bind(this);
  }

  handleClickEditTestTab() {
    const { userspace, match } = this.props;
    this.props.history.push(
      `/userspace/${userspace.org_profile._id}/template/${match.params.test_id}`,
    );
  }

  handleClickNewTestTab() {
    const { userspace } = this.props;
    this.props.history.push(`/userspace/${userspace.org_profile._id}/template`);
  }

  handleClickTestsListTab() {
    const { userspace, match } = this.props;
    this.props.history.push(
      `/userspace/${userspace.org_profile._id}/template/${match.params.test_id}/tests`,
    );
  }

  handleClickParticipantsListTab() {
    const { userspace, match } = this.props;
    this.props.history.push(
      `/userspace/${userspace.org_profile._id}/template/${match.params.test_id}/users`,
    );
  }

  handleClickTestTemplateusersTab() {
    const { userspace, match } = this.props;
    this.props.history.push(
      `/userspace/${userspace.org_profile._id}/template/${match.params.test_id}/templateusers`,
    );
  }

  render() {
    return (
      <Tabber
        items={
          this.props.match.params.test_id
            ? [
                'Úprava šablóny testu',
                ...(this.props.isPrivate
                  ? ['Používatelia privátneho testu']
                  : []),
                'Zoznam testov',
                'Zoznam riešiteľov',
              ]
            : ['Nový test']
        }
        active={this.props.active}
        onClickItem={(item) => {
          switch (item) {
            case 'Úprava šablóny testu':
              this.handleClickEditTestTab();
              break;
            case 'Nový test':
              this.handleClickNewTestTab();
              break;
            case 'Zoznam testov':
              this.handleClickTestsListTab();
              break;
            case 'Používatelia privátneho testu':
              this.handleClickTestTemplateusersTab();
              break;
            case 'Zoznam riešiteľov':
              this.handleClickParticipantsListTab();
              break;
            default:
              this.handleClickEditTestTab();
              break;
          }
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userspace: activeAppSpaceSelector(state),
  };
};

TestMenu.propTypes = {
  userspace: PropTypes.shape({
    org_profile: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      test_id: PropTypes.node,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  active: PropTypes.string.isRequired,
  isPrivate: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(withRouter(TestMenu));
