import API from '../../API';
import {
  AUTH_USER_AUTHORIZED,
  AUTH_USER_UNAUTHORIZED,
  SET_ACL,
  SET_ACTIVE_APPSPACE_ID,
  SET_ACTIVE_APPSPACE,
  SET_APPS,
  SET_APPSPACES,
  SET_APP_INFO,
} from './constants';

export const setApplicationInfo = (appInfo) => {
  return {
    type: SET_APP_INFO,
    payload: {
      appInfo,
    },
  };
};

export function setAcl(acl) {
  return {
    type: SET_ACL,
    payload: {
      acl,
    },
  };
}

export const loadAcl = (appSpaceId) => {
  return async (dispatch) => {
    try {
      dispatch(setAcl(null));
      const acl = await API.adminGetUserAcl(appSpaceId);
      dispatch(setAcl(acl));
    } catch (e) {
      console.error(e);
    }
  };
};

export const setApps = (apps) => {
  return {
    type: SET_APPS,
    payload: {
      apps,
    },
  };
};

export const setAppSpaces = (appSpaces) => {
  return {
    type: SET_APPSPACES,
    payload: {
      appSpaces,
    },
  };
};

export const setActiveAppSpaceId = (appSpaceId) => {
  return {
    type: SET_ACTIVE_APPSPACE_ID,
    payload: {
      appSpaceId,
    },
  };
};

export function setAuthUser(authUser, accessToken = null) {
  return authUser && accessToken
    ? {
        type: AUTH_USER_AUTHORIZED,
        authUser,
        accessToken,
      }
    : {
        type: AUTH_USER_UNAUTHORIZED,
      };
}

export const setActiveAppSpace = (activeAppSpace) => {
  return {
    type: SET_ACTIVE_APPSPACE,
    payload: {
      activeAppSpace,
    },
  };
};
