import {
  AUTH_USER_AUTHORIZED,
  AUTH_USER_UNAUTHORIZED,
  SET_ACL,
  SET_ACTIVE_APPSPACE,
  SET_APPS,
  SET_APPSPACES,
  SET_APP_INFO,
  SET_ACTIVE_APPSPACE_ID,
} from './constants';

export const applicationReducer = (
  state = {
    appInfo: null,
    apps: [],
    activeAppSpaceId: null,
    appSpaces: [],
    acl: null,
    authUser: null,
    accessToken: null,
  },
  action,
) => {
  switch (action.type) {
    case AUTH_USER_AUTHORIZED:
      return {
        ...state,
        authUser: action.authUser,
        accessToken: action.accessToken,
      };
    case AUTH_USER_UNAUTHORIZED:
      return {
        ...state,
        authUser: null,
        accessToken: null,
      };
    case SET_ACTIVE_APPSPACE_ID:
      return {
        ...state,
        activeAppSpaceId: action.payload.appSpaceId,
      };
    case SET_ACTIVE_APPSPACE:
      return {
        ...state,
        activeAppSpace: action.payload.activeAppSpace,
      };
    case SET_APP_INFO:
      return {
        ...state,
        appInfo: action.payload.appInfo,
      };
    case SET_APPS:
      return {
        ...state,
        apps: action.payload.apps,
      };
    case SET_APPSPACES:
      return {
        ...state,
        appSpaces: action.payload.appSpaces,
      };
    case SET_ACL:
      return {
        ...state,
        acl: action.payload.acl,
      };
    default:
      return state;
  }
};
