import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import { FilterPanel } from '@sportnet/ui/FilterPanel';
import SelectFilter from '@sportnet/ui/FilterPanel/types/SelectFilter';
import HeaderBar from '@sportnet/ui/HeaderBar';
import NotFound from '@sportnet/ui/NotFound';
import Paginator from '@sportnet/ui/Paginator';
import Segment from '@sportnet/ui/Segment';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import PropTypes from 'prop-types';
import useQuery, {
  ArrayParam,
  StringParam,
  NumberParam,
} from '@sportnet/query-hoc/useQuery';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import API from '../../API';
import Layout from '../../components/Layout';
import { __, getApiError, formatDate } from '../../utils';
import { activeAppSpaceSelector } from '../Authorization/selectors';
import { renderSmarttagsFromStGroups } from '../../components/TestPrint';
import Icon from '@sportnet/ui/Icon';
import { useLocation, useHistory } from 'react-router-dom';
import { useAuth } from '@sportnet/auth-react';

const DEFALUT_LIMIT = 25;

const INITIAL_STATE = {
  templates: undefined,
  offset: 0,
  limit: DEFALUT_LIMIT,
  isLoading: false,
  isError: false,
  error: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'START': {
      const { limit = DEFALUT_LIMIT, offset = 0 } = action.payload ?? {};
      return {
        ...INITIAL_STATE,
        limit,
        offset,
        isLoading: true,
      };
    }
    case 'DONE': {
      const { templates = [], offset = 0 } = action.payload ?? {};
      return {
        ...state,
        templates,
        offset,
        isLoading: false,
      };
    }

    case 'ERROR': {
      const { error = '' } = action.payload ?? {};
      return {
        ...state,
        isLoading: false,
        isError: true,
        error,
      };
    }
    default: {
      return state;
    }
  }
};

const QUERY_HOC_CONFIG = {
  parameters: {
    testType: ArrayParam(StringParam(''), [], ','),
    testState: ArrayParam(StringParam(''), [], ','),
    offset: NumberParam(0),
    limit: NumberParam(DEFALUT_LIMIT),
  },
};

const TYPE_CODE_LIST = [
  {
    title: __('Cvičný'),
    value: 'practice',
  },
  {
    title: __('Ostrý'),
    value: 'normal',
  },
];

const STATE_CODE_LIST = [
  {
    title: __('Aktívny'),
    value: 'active',
  },
  {
    title: __('Archívny'),
    value: 'archived',
  },
];

const TestsList = () => {
  const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);
  const location = useLocation();
  const { push: historyPush } = useHistory();

  const { ppo: appSpace } = useAuth();

  const { query, setQuery } = useQuery(
    location.search,
    (serializedQuery) =>
      historyPush({
        search: serializedQuery,
      }),
    QUERY_HOC_CONFIG,
  );

  const { total, offset, limit, testType, testState } = query;

  React.useEffect(() => {
    if (!appSpace) {
      return;
    }
    (async () => {
      try {
        dispatch({
          type: 'START',
          payload: {
            offset,
            limit,
          },
        });
        const { templates, total } = await API.templateList(appSpace, {
          limit,
          offset,
          type: testType,
          state: testState,
        });
        dispatch({
          type: 'DONE',
          payload: {
            templates,
            total,
          },
        });
      } catch (e) {
        const err = getApiError(e);
        dispatch({
          type: 'ERROR',
          payload: {
            error: `${err.details.statusCode}: ${err.details.name} (${err.details.description})`,
          },
        });
      }
    })();
  }, [total, offset, limit, testType, testState, appSpace]);

  const handleTestClick = (testId) => {
    historyPush(`/userspace/${appSpace}/template/${testId}`);
  };

  const handleChangeSelectFilterTestType = (val) => {
    setQuery({
      testType: val,
    });
  };

  const handleChangeSelectFilterTestState = (val) => {
    setQuery({
      testState: val,
    });
  };

  const handleChangeOffset = () => {};

  const selectedTestType = TYPE_CODE_LIST.filter((type) =>
    query.testType.includes(type.value),
  ).map((item) => item.value);

  const selectedTestState = STATE_CODE_LIST.filter((state) =>
    query.testState.includes(state.value),
  ).map((item) => item.value);

  const topFixed = (
    <>
      <HeaderBar>
        <HeaderBar.Header>{__('Zoznam testových šablón')}</HeaderBar.Header>
      </HeaderBar>
      <FilterPanel>
        <SelectFilter
          placeholder={__('Vyberte typ testu')}
          value={selectedTestType}
          onChange={handleChangeSelectFilterTestType}
          items={TYPE_CODE_LIST}
        />
        <SelectFilter
          placeholder={__('Vyberte stav testu')}
          value={selectedTestState}
          onChange={handleChangeSelectFilterTestState}
          items={STATE_CODE_LIST}
        />
      </FilterPanel>
    </>
  );

  const bottomFixed = (
    <ContextBar>
      <Paginator
        total={total}
        offset={offset}
        onChangeOffset={handleChangeOffset}
        limit={limit}
        step={limit}
      />
      <ContextBarSpacer />
      <ContextBarItem>
        <Link
          to={`/userspace/${appSpace}/template`}
          style={{ textDecoration: 'none', background: 'inherit' }}
        >
          <Button primary icon="plus">
            {__('Pridať test')}
          </Button>
        </Link>
      </ContextBarItem>
    </ContextBar>
  );

  const { templates, isLoading } = state;

  return (
    <Layout topFixed={topFixed} bottomFixed={bottomFixed}>
      <Segment noBottomGutter>
        <Segment raised loading={!templates || isLoading}>
          {templates && templates.length === 0 ? (
            <NotFound
              icon="file"
              title={__('Pre Vašu organizáciu sa nenašli žiadne testy.')}
            />
          ) : (
            <Table>
              <Thead>
                <Tr>
                  <Th>{__('Stav')}</Th>
                  <Th>{__('Dátum')}</Th>
                  <Th>{__('Názov')}</Th>
                  <Th>{__('Popis')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {templates &&
                  templates.map((test) => {
                    return (
                      <Tr
                        key={test._id}
                        onClick={() => handleTestClick(test._id)}
                      >
                        <Td>
                          {test.locked ? (
                            <Icon name="lock" />
                          ) : (
                            <Icon name="lock-open" />
                          )}
                        </Td>
                        <Td>{formatDate(test.created)}</Td>
                        <Td>{test.name}</Td>
                        <Td>{renderSmarttagsFromStGroups(test.stGroups)}</Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          )}
        </Segment>
      </Segment>
    </Layout>
  );
};

TestsList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userspace_id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  userspace: PropTypes.shape({
    org_profile: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  query: PropTypes.shape({
    state: PropTypes.arrayOf(PropTypes.string).isRequired,
    type: PropTypes.arrayOf(PropTypes.string).isRequired,
    offset: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
  }).isRequired,
  setParameter: PropTypes.func.isRequired,
  serializedQuery: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    userspace: activeAppSpaceSelector(state),
  };
};

export default connect(mapStateToProps)(TestsList);
