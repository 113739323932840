import baseTheme from '@sportnet/ui/Themes/baseTheme';
import { css } from 'styled-components';

const sizes = {
  lg: 1200,
  md: 992,
  sm: 768,
};

const media = {
  ...baseTheme.media,
  sm: (...args) => css`
    @media (min-width: ${sizes.sm}px) {
      ${css(...args)};
    }
  `,
  md: (...args) => css`
    @media (min-width: ${sizes.md}px) {
      ${css(...args)};
    }
  `,
  lg: (...args) => css`
    @media (min-width: ${sizes.lg}px) {
      ${css(...args)};
    }
  `,
};

export const theme = {
  ...baseTheme,
  primary: '#515151',
  primaryLight: '#AAA',
  secondary: '#ff3737',
  warning: '#FF9800',
  success: '#5cb85c',
  danger: '#A94442',
  white: '#fff',
  black: '#000',

  headerColorGrayDark: '#373737',
  headerColorGrayDarker: '#404040',
  headerColorGray: '#cccccc',
  headerColorGrayLighter: '#f5f5f5',

  separatorColorLighter: '#E5E5E5',

  xs: '0.6rem',
  sm: '0.85rem',
  md: '1rem',
  lg: '1.25rem',
  xl: '1.5rem',
  xxl: '1.75rem',

  borderRadius: '0.25rem',

  inputHeight: '1.538rem',

  media,

  shadow: '0px 0px 20px 0px rgba(50, 50, 50, 0.1)',

  app: {
    textColor: '#333',
    fontName: 'Barlow',
    padding: 15,
    maxWidth: 1200,
  },
};
