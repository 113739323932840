import Loader from '@sportnet/ui/Loader';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import API from '../../API';
import TestPrint from '../../components/TestPrint';
import { __, formatDate } from '../../utils';

class TestUsersPrint extends Component {
  constructor(props) {
    super(props);
    const { evalHidden } = API.paramsToObject(this.props.location.search);
    this.state = {
      isFetching: true,
      testDetail: null,
      evalHidden: evalHidden === 'true',
    };
    this.oldTitle = window.document.title;
    this.fetchTestDetail = this.fetchTestDetail.bind(this);
  }

  async componentDidMount() {
    const {
      match: {
        params: {
          userspace_id: userspaceId,
          template_id: templateId,
          test_id: testId,
        },
      },
    } = this.props;
    try {
      if (testId === 'anonymouse') {
        const test = await API.adminCreateAnonymouseTest(
          userspaceId,
          templateId,
        );
        this.setState(
          {
            isFetching: false,
            testDetail: test,
          },
          () => {
            window.document.title = `Sportnet TEST - ${
              this.state.testDetail.name
            } - ${formatDate(this.state.testDetail.startAt)} - ${
              this.state.testDetail.user
                ? this.state.testDetail.user.display_name
                : __('Anonymný test')
            }`;
          },
        );
      } else {
        await this.fetchTestDetail();
      }
      setTimeout(() => {
        window.print();
      }, 100); // istota
    } catch (e) {
      if (e.response && e.response.status === 403) {
        alert(__('Test je uzamknutý a nie je možné vygenerovať otázky'));
      } else {
        alert(__('Test sa nepodarilo načítať'));
      }
      if (testId !== 'anonymouse') {
        this.props.history.push(
          `/userspace/${userspaceId}/template/${templateId}/test/${testId}`,
        );
      } else {
        this.props.history.push(
          `/userspace/${userspaceId}/template/${templateId}`,
        );
      }
    }
  }

  componentWillUnmount() {
    window.document.title = this.oldTitle;
  }

  fetchTestDetail() {
    const { params } = this.props.match;
    return API.adminTestDetail(
      params.userspace_id,
      params.template_id,
      params.test_id,
    ).then((res) => {
      // let i = 0;
      // const nonEvaluated = res.questions.reduce((prev, next) => {
      //   i += 1;
      //   if (next.type === 'text') {
      //     const el = next;
      //     el.checked = false;
      //     el.index = i - 1;
      //     return { ...prev, [el._id]: el };
      //   }
      //   return prev;
      // }, {});
      this.setState(
        {
          isFetching: false,
          testDetail: res,
        },
        () => {
          window.document.title = `Sportnet TEST - ${
            this.state.testDetail.name
          } - ${formatDate(this.state.testDetail.startAt)} - ${
            (this.state.testDetail.user || {}).display_name ||
            __('Anonymný test')
          }`;
        },
      );
    });
  }

  render() {
    const { testDetail, evalHidden, isFetching } = this.state;
    if (isFetching) {
      return <Loader />;
    }

    return <TestPrint testDetail={testDetail} evalHidden={evalHidden} />;
  }
}

TestUsersPrint.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userspace_id: PropTypes.string.isRequired,
      template_id: PropTypes.string.isRequired,
      test_id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape().isRequired,
};

export default TestUsersPrint;
