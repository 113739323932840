import Button from '@sportnet/ui/Button';
import Segment from '@sportnet/ui/Segment';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import FormField from '@sportnet/ui/FormField';
import Input from '@sportnet/ui/Input';
import Label from '@sportnet/ui/Label/Label';
import PropTypes from 'prop-types';
import Message from '@sportnet/ui/Message';
import BasicTable from '@sportnet/ui/BasicTable';
import { Loader } from '@sportnet/ui/Loader';
import { isValidEmail, __ } from '../../utils';
import API from '../../API';
import SportnetApi from '../../SportnetApi';
import { Faded } from './index';

const SidebarButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const DefaultPropTypes = {
  appSpace: PropTypes.string.isRequired,
  templateId: PropTypes.string.isRequired,
  onAdd: PropTypes.func,
};

const AddUserFormSportnetId = ({ appSpace, templateId, onAdd }) => {
  const [sportnetId, setSportnetId] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [foundPerson, setFoundPerson] = useState(null);
  const [displayName, setDisplayName] = useState('');
  const [personError, setPersonError] = useState('');
  const searchUser = () => {
    if (!sportnetId) {
      return;
    }
    setIsSearching(true);
    SportnetApi.getPublicUserProfile(sportnetId)
      .then((r) => {
        setFoundPerson(r);
        setDisplayName(`${r.name} ${r.surname}`);
        setPersonError('');
      })
      .catch((e) => {
        if (e.details && e.details.statusCode === 404) {
          setPersonError(__('Osoba nenájdená. Skontrolujte prosím SportnetId'));
        } else {
          setPersonError(e.details ? e.details.name : e.name);
        }
        setDisplayName('');
      })
      .finally(() => {
        setIsSearching(false);
      });
  };

  const [isSaving, setIsSaving] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const addUser = () => {
    if (!sportnetId || !displayName) {
      return;
    }
    setIsSaving(true);
    API.templateAddUser(appSpace, templateId, {
      body: {
        users: [
          {
            sportnetId,
            displayName,
          },
        ],
      },
    })
      .then(() => {
        setResultMessage(__(`Osoba bola pridaná do testovania`));
        setSportnetId('');
        setFoundPerson(null);
        setDisplayName('');
        setPersonError('');
        window.setTimeout(() => {
          setResultMessage('');
        }, 2000);
        if (onAdd) {
          onAdd();
        }
      })
      .catch((e) => {
        if (e.details && e.details.statusCode === 409) {
          setPersonError(__('Tento používateľ už má aktívnu pozvánku na test'));
          setFoundPerson(null);
        } else {
          alert(
            `Pri pridávaní osoby nastala chyba: ${
              e.details ? e.details.name : e
            }`,
          );
        }
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <>
      <Label required style={{ marginBottom: '5px' }}>
        {__('SportnetId')}
      </Label>
      <Input
        placeholder={__('Zadajte SportnetId osoby')}
        onChange={(e) => {
          setSportnetId(e.target.value);
          setFoundPerson(null);
          setDisplayName('');
        }}
        value={sportnetId}
      >
        <input />
        <Input.Button
          primary
          icon="search"
          onClick={searchUser}
          loading={isSearching}
        />
      </Input>
      <br />
      {personError && (
        <Message
          danger
          onDispose={() => {
            setPersonError('');
          }}
        >
          {personError}
        </Message>
      )}
      {resultMessage && (
        <Message
          success
          onDispose={() => {
            setResultMessage('');
          }}
        >
          {resultMessage}
        </Message>
      )}
      {foundPerson && (
        <>
          <FormField
            label={__('Meno osoby')}
            name="displayName"
            type="text"
            required
            value={displayName}
            onChange={(e) => {
              setDisplayName(e.target.value);
            }}
          />
          <SidebarButtons>
            <Button
              primary
              onClick={addUser}
              disabled={!displayName || isSaving}
              loading={isSaving}
            >
              {__('Pridať osobu')}
            </Button>
          </SidebarButtons>
        </>
      )}
    </>
  );
};
AddUserFormSportnetId.propTypes = { ...DefaultPropTypes };

const AddUserFormGroup = ({ appSpace, templateId, onAdd }) => {
  // nacitame skupiny
  const [isLoadingGroups, setIsLoadingGroups] = useState(false);
  const [crmGroups, setCrmGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);

  // naloadujeme skupiny z CRM
  useEffect(() => {
    setIsLoadingGroups(true);
    SportnetApi.crmGetAppSpaceGroups(appSpace)
      .then(({ groups }) =>
        setCrmGroups(
          groups.map(({ _id: value, name: label }) => ({ value, label })),
        ),
      )
      .catch((e) => {
        alert(
          `Pri načítavaní zoznamu skupín nastala chyba: ${
            e.details ? e.details.name : e
          }`,
        );
      })
      .finally(() => {
        setIsLoadingGroups(false);
      });
  }, [appSpace]);

  // zvolenie skupiny
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [groupUsers, setGroupUsers] = useState([]);
  const [groupUsersNextOffset, setGroupUsersNextOffset] = useState(null);
  const [offset, setOffset] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState({});
  // - vymazanie selectnutych ludi ak sme zmenili skupinu
  useEffect(() => {
    setOffset(0);
    setSelectedUsers({});
    setGroupUsers([]);
  }, [selectedGroup]);
  // - nacitanie ludi
  useEffect(() => {
    if (!selectedGroup) {
      return;
    }
    setIsLoadingUsers(true);
    // nacitame zoznam ludi zo skupiny
    SportnetApi.getUsersByAppSpaceGroup(appSpace, {
      userGroupIds: [selectedGroup],
      limit: 100,
      offset,
    })
      .then((r) => {
        setGroupUsers((uu) => [...uu, ...r.users]);
        setGroupUsersNextOffset(r.next_offset);
      })
      .catch((e) => {
        alert(
          `Pri načítavaní zoznamu skupín nastala chyba: ${
            e.details ? e.details.name : e
          }`,
        );
      })
      .finally(() => {
        setIsLoadingUsers(false);
      });
  }, [appSpace, selectedGroup, offset]);
  // zvoleny useri
  const selectedUserIds = useMemo(() => {
    return Object.entries(selectedUsers)
      .filter(([, status]) => status)
      .map(([uid]) => uid);
  }, [selectedUsers]);
  const [isSaving, setIsSaving] = useState();
  const [resultMessage, setResultMessage] = useState('');
  const addUsers = () => {
    if (!selectedUserIds) {
      return;
    }
    const usersToAdd = selectedUserIds.map((sportnetId) => {
      const groupUser = groupUsers.find(({ _id }) => _id === sportnetId);
      return {
        sportnetId,
        displayName: `${groupUser.name} ${groupUser.surname}`,
      };
    });

    setIsSaving(true);
    API.templateAddUser(appSpace, templateId, {
      body: {
        users: usersToAdd,
      },
    })
      .then(() => {
        setResultMessage(__(`Osoby boli pridané do testovania`));
        setSelectedUsers({});
        window.setTimeout(() => {
          setResultMessage('');
        }, 2000);
        if (onAdd) {
          onAdd();
        }
      })
      .catch((e) => {
        alert(
          `Pri pridávaní osôb nastala chyba: ${e.details ? e.details.name : e}`,
        );
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <>
      <FormField
        label={__('Zvoľte skupinu')}
        loading={isLoadingGroups}
        type="theselectsimple"
        onChange={setSelectedGroup}
        value={selectedGroup}
        options={crmGroups}
        required
      />
      {selectedGroup &&
        (isLoadingUsers ? (
          <Loader />
        ) : (
          <>
            <BasicTable
              columns={[{ id: 'user', header: __('Používateľ') }]}
              rows={groupUsers}
              renderRow={(groupUser) => {
                return [
                  <>
                    <strong>{`${groupUser.name} ${groupUser.surname}`}</strong>
                    <br />
                    <Faded>{groupUser._id}</Faded>
                  </>,
                ];
              }}
              rowKey="_id"
              selected={selectedUsers}
              onClickRow={(u) => {
                setSelectedUsers((prev) => ({
                  ...prev,
                  [u._id]: !prev[u._id],
                }));
              }}
              onSelect={setSelectedUsers}
            />
            {groupUsersNextOffset && (
              <>
                <Button
                  onClick={() => {
                    setOffset(groupUsersNextOffset);
                  }}
                  disabled={isLoadingUsers}
                  loading={isLoadingUsers}
                  style={{ width: '100%' }}
                >
                  {__('Načítať ďalšie')}
                </Button>
                <br />
              </>
            )}
            <br />
            <SidebarButtons>
              <Button
                primary
                onClick={addUsers}
                disabled={!selectedUserIds.length || isSaving}
                loading={isSaving}
              >
                {__('Pridať zvolené osoby')}
              </Button>
            </SidebarButtons>
            {resultMessage && (
              <Message
                success
                onDispose={() => {
                  setResultMessage('');
                }}
              >
                {resultMessage}
              </Message>
            )}
          </>
        ))}
    </>
  );
};
AddUserFormGroup.propTypes = { ...DefaultPropTypes };

const AddUserFormInvitation = ({ appSpace, templateId }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [resultMessage, setResultMessage] = useState('');
  const sendInvitation = () => {
    if (!email || !isValidEmail(email)) {
      alert(__(`Zadajte správny email`));
    }
    setIsSaving(true);
    API.templateUserCreateInvitation(appSpace, templateId, { body: { email } })
      .then(() => {
        setResultMessage(__(`Pozvánka bola odoslaná na email ${email}`));
        setEmail('');
        window.setTimeout(() => {
          setResultMessage('');
        }, 2000);
      })
      .catch((e) => {
        alert(
          `Pri odosielaní pozvánky nastala chyba: ${
            e.details ? e.details.name : e
          }`,
        );
      })
      .finally(() => {
        setIsSaving(false);
      });
  };
  return (
    <>
      <FormField
        label={__('Email')}
        name="email"
        type="text"
        required
        value={email}
        error={emailError}
        onChange={(e) => {
          const filledEmail = e.target.value;
          setEmail(filledEmail);
          if (!isValidEmail(filledEmail)) {
            setEmailError(__('Zadajte správny email'));
          } else {
            setEmailError('');
          }
        }}
        placeholder={__('zadajte email')}
        disabled={isSaving}
      />
      <SidebarButtons>
        <Button
          primary
          onClick={sendInvitation}
          disabled={!email || !isValidEmail(email) || isSaving}
          loading={isSaving}
        >
          {__('Odoslať pozvánku')}
        </Button>
      </SidebarButtons>
      <br />
      {resultMessage && (
        <Message
          success
          onDispose={() => {
            setResultMessage('');
          }}
        >
          {resultMessage}
        </Message>
      )}
    </>
  );
};

AddUserFormInvitation.propTypes = { ...DefaultPropTypes };

const AddUsersForm = ({ appSpace, templateId, onAdd }) => {
  const [addMethod, setAddMethod] = useState('invitation');

  return (
    <Segment>
      <FormField
        label={__('Pridať používateľov podľa')}
        type="theselectsimple"
        onChange={(v) => {
          setAddMethod(v);
        }}
        value={addMethod}
        options={[
          { label: 'Pozvánkou na email', value: 'invitation' },
          { label: 'Podľa SportnetId', value: 'sportnetId' },
          { label: 'Zo skupiny', value: 'group' },
        ]}
      />
      {((m) => {
        const props = { appSpace, templateId, onAdd };
        switch (m) {
          case 'invitation':
            return <AddUserFormInvitation {...props} />;
          case 'group':
            return <AddUserFormGroup {...props} />;
          case 'sportnetId':
            return <AddUserFormSportnetId {...props} />;
          default:
            return null;
        }
      })(addMethod)}
    </Segment>
  );
};

AddUsersForm.propTypes = { ...DefaultPropTypes };

AddUsersForm.defaultProps = {};

export default AddUsersForm;
