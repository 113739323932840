/* eslint-disable */
import 'isomorphic-fetch';

class API {
  constructor() {
    this.baseUrl = null;
    this.token = null;
    this.headers = [];
  }

  serializeQueryParams(parameters) {
    return Object.keys(parameters)
      .reduce((acc, p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  paramsToObject(params) {
    let query = params.substr(1);
    let result = {};
    query.split('&').forEach(function (part) {
      let item = part.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  }

  mergeQueryParams(parameters, queryParameters) {
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (
        parameterName,
      ) {
        const parameter = parameters.$queryParameters[parameterName];
        queryParameters[parameterName] = parameter;
      });
    }
    return queryParameters;
  }

  transformParameter(value, transformOperation = null) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  /**
   * HTTP Request
   * @method
   * @param {string} method - http method
   * @param {string} url - url to do request
   * @param {object} body - body parameters / object
   * @param {object} headers - header parameters
   * @param {object} queryParameters - querystring parameters
   */
  request(
    method,
    url,
    body,
    headers,
    queryParameters,
    form,
    checkFor401 = true,
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    // ugly hack, we need to delete Content-Type header with multipart/form-data
    // that way, browser will calculate form specific headers on it's own
    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    if (body && !Object.keys(body).length) {
      body = undefined;
    } else {
      body = JSON.stringify(body);
    }

    if (form && Object.keys(form).length) {
      body = new FormData();
      for (let k in form) {
        body.append(k, form[k]);
      }
    }

    if (this.headers.length > 0) {
      this.headers.forEach((h) => {
        headers.append(h.name, h.value);
      });
    }

    return fetch(urlWithParams, {
      method,
      headers,
      body,
    })
      .then((response) => {
        if (checkFor401) {
          if (response.status === 401) {
            if (typeof this._onResponseUnauthorized === 'function') {
              this._onResponseUnauthorized();
            } else {
              let error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
          }
        }

        if (response.status === 204) return response;

        if (response.ok) {
          const responseContentType =
            (response.headers && response.headers.get('Content-Type')) || '';
          if (responseContentType.includes('application/json')) {
            return response.json();
          }
          return response;
        } else {
          let error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .catch((error) => {
        return error.response.json().then((error_details) => {
          error.details = error_details;
          throw error;
        });
      });
  }

  /**
   * Set base url
   * @method
   * @param {string} baseUrl
   */
  setBaseUrl(baseUrl) {
    this.baseUrl = baseUrl;
  }

  /**
   * Set Headers
   * @method
   * @param headers - array of headers
   */
  setHeaders(headers) {
    this.headers = headers;
  }

  set onResponseUnauthorized(callback) {
    this._onResponseUnauthorized = callback;
  }

  /**
   * Redirects a user to a given url
   * @method
   * @param {string} url
   */
  redirect(url) {
    window.location = url;
  }

  /**
   * Set Token
   * @method
   * @param {string} token - token's value
   */
  setToken(token) {
    this.token = token;
  }

  /**
   * Set Auth headers
   * @method
   * @param {object} headerParams - headers object
   */
  appendAuthHeaders(headerParams) {
    const headers = new Headers(headerParams);
    if (this.token) {
      headers.append('Authorization', `Bearer ${this.token}`);
    }
    return headers;
  }

  /**
   * Operation access for current user and userspace
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   */
  adminGetUserAcl(appSpace, parameters = {}) {
    let path = '/admin/{appSpace}/acl';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Zoznam otázok.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - RESTful API specification.

       * @param {} parameters.offset - RESTful API specification.

       * @param {} parameters.smarttags - RESTful API specification.

       * @param {} parameters.q - Fulltext search query
       * @param {} appSpace - Identifikátor userspace.
     */
  questionList(appSpace, parameters = {}) {
    let path = '/admin/{appSpace}/questions';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['smarttags'] !== 'undefined') {
      queryParameters['smarttags'] = parameters['smarttags'];
    }

    queryParameters['smarttags'] = this.transformParameter(
      queryParameters['smarttags'],
      'joinUsingPipes',
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Pridanie novej otázky.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} parameters.body -
   */
  questionCreate(appSpace, parameters = {}) {
    let path = '/admin/{appSpace}/questions';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Zoznam otázok ako anonymny test
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - RESTful API specification.

       * @param {} parameters.offset - RESTful API specification.

       * @param {} parameters.smarttags - RESTful API specification.

       * @param {} parameters.q - Tulltext search query
       * @param {} parameters.withAnswers - Include answers in test
       * @param {} appSpace - Identifikátor userspace.
     */
  questionListAsAnonymouseTest(appSpace, parameters = {}) {
    let path = '/admin/{appSpace}/questions-as-anonymouse-test';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['smarttags'] !== 'undefined') {
      queryParameters['smarttags'] = parameters['smarttags'];
    }

    queryParameters['smarttags'] = this.transformParameter(
      queryParameters['smarttags'],
      'joinUsingPipes',
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['withAnswers'] !== 'undefined') {
      queryParameters['withAnswers'] = parameters['withAnswers'];
    }

    queryParameters['withAnswers'] = this.transformParameter(
      queryParameters['withAnswers'],
    );

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} questionId - ID otázky.
   */
  questionDetail(appSpace, questionId, parameters = {}) {
    let path = '/admin/{appSpace}/questions/{question_id}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{question_id}', questionId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} questionId - ID otázky.
   */
  questionDelete(appSpace, questionId, parameters = {}) {
    let path = '/admin/{appSpace}/questions/{question_id}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{question_id}', questionId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} questionId - ID otázky.
   * @param {} parameters.body -
   */
  questionUpdate(appSpace, questionId, parameters = {}) {
    let path = '/admin/{appSpace}/questions/{question_id}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{question_id}', questionId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Zoznam predlôh testov.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - RESTful API specification.

       * @param {} parameters.offset - RESTful API specification.

       * @param {} parameters.type - RESTful API specification.

       * @param {} parameters.private - RESTful API specification.

       * @param {} parameters.state - RESTful API specification.

       * @param {} appSpace - Identifikátor userspace.
     */
  templateList(appSpace, parameters = {}) {
    let path = '/admin/{appSpace}/templates';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(
      queryParameters['type'],
      'joinUsingPipes',
    );

    if (typeof parameters['private'] !== 'undefined') {
      queryParameters['private'] = parameters['private'];
    }

    queryParameters['private'] = this.transformParameter(
      queryParameters['private'],
    );

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
      'joinUsingPipes',
    );

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} parameters.body -
   */
  templateCreate(appSpace, parameters = {}) {
    let path = '/admin/{appSpace}/templates';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Detail predlohy testu.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} templateId - ID predlohy testu.
   */
  templateDetail(appSpace, templateId, parameters = {}) {
    let path = '/admin/{appSpace}/templates/{template_id}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{template_id}', templateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} templateId - ID predlohy testu.
   * @param {} parameters.body -
   */
  templateUpdate(appSpace, templateId, parameters = {}) {
    let path = '/admin/{appSpace}/templates/{template_id}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{template_id}', templateId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} templateId - ID predlohy testu.
   */
  templateDelete(appSpace, templateId, parameters = {}) {
    let path = '/admin/{appSpace}/templates/{template_id}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{template_id}', templateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} templateId - ID predlohy testu.
   */
  templateCopy(appSpace, templateId, parameters = {}) {
    let path = '/admin/{appSpace}/templates/{template_id}/copy';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{template_id}', templateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Vytvori novy anonymny test - bez zapisania do databazy, len nahodne vygeneruje otazky.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} templateId - ID predlohy testu.
   */
  adminCreateAnonymouseTest(appSpace, templateId, parameters = {}) {
    let path = '/admin/{appSpace}/templates/{template_id}/anonymouse-test';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{template_id}', templateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Zoznam všetkých testov pre sportnet id usera.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - RESTful API specification.

       * @param {} parameters.offset - RESTful API specification.

       * @param {} parameters.seminarId - RESTful API specification.

       * @param {} appSpace - Identifikátor userspace.
       * @param {} userId - ID sportnet usera.
     */
  adminUserAllTestsList(appSpace, userId, parameters = {}) {
    let path = '/admin/{appSpace}/user/{user_id}/tests';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['seminarId'] !== 'undefined') {
      queryParameters['seminarId'] = parameters['seminarId'];
    }

    queryParameters['seminarId'] = this.transformParameter(
      queryParameters['seminarId'],
    );

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{user_id}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Zoznam pozvaných používateľov do testu.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - RESTful API specification.

       * @param {} parameters.offset - RESTful API specification.

       * @param {} appSpace - Identifikátor userspace.
       * @param {} templateId - ID predlohy testu.
     */
  templateUsersList(appSpace, templateId, parameters = {}) {
    let path = '/admin/{appSpace}/templates/{template_id}/template-users';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{template_id}', templateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} templateId - ID predlohy testu.
   * @param {} parameters.body -
   */
  templateAddUser(appSpace, templateId, parameters = {}) {
    let path = '/admin/{appSpace}/templates/{template_id}/template-users';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{template_id}', templateId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} templateId - ID predlohy testu.
   * @param {} sportnetId - Sportnet Id pouzivatela
   * @param {} parameters.body -
   */
  templateUpdateUser(appSpace, templateId, sportnetId, parameters = {}) {
    let path =
      '/admin/{appSpace}/templates/{template_id}/template-users/{sportnet_id}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{template_id}', templateId);

    path = path.replace('{sportnet_id}', sportnetId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} templateId - ID predlohy testu.
   * @param {} sportnetId - Sportnet Id pouzivatela
   */
  templateDeleteUser(appSpace, templateId, sportnetId, parameters = {}) {
    let path =
      '/admin/{appSpace}/templates/{template_id}/template-users/{sportnet_id}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{template_id}', templateId);

    path = path.replace('{sportnet_id}', sportnetId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} templateId - ID predlohy testu.
   * @param {} parameters.body -
   */
  templateUserCreateInvitation(appSpace, templateId, parameters = {}) {
    let path = '/admin/{appSpace}/templates/{template_id}/invitations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{template_id}', templateId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} templateId - ID predlohy testu.
   * @param {} invitationId - Id pozvanky core api
   */
  processTemplateInvitationResponse(
    appSpace,
    templateId,
    invitationId,
    parameters = {},
  ) {
    let path =
      '/admin/{appSpace}/templates/{template_id}/invitations/{invitation_id}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{template_id}', templateId);

    path = path.replace('{invitation_id}', invitationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Zoznam vygenerovaných testov pre template.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - RESTful API specification.

       * @param {} parameters.offset - RESTful API specification.

       * @param {} appSpace - Identifikátor userspace.
       * @param {} templateId - ID predlohy testu.
     */
  adminTestList(appSpace, templateId, parameters = {}) {
    let path = '/admin/{appSpace}/templates/{template_id}/tests';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{template_id}', templateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Zoznam riesitelov testu pre template.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - RESTful API specification.

       * @param {} parameters.offset - RESTful API specification.

       * @param {} appSpace - Identifikátor userspace.
       * @param {} templateId - ID predlohy testu.
     */
  adminTestListByUsers(appSpace, templateId, parameters = {}) {
    let path = '/admin/{appSpace}/templates/{template_id}/users';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{template_id}', templateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Detail vygenerovaného testu.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} templateId - ID predlohy testu.
   * @param {} testId - ID testu.
   */
  adminTestDetail(appSpace, templateId, testId, parameters = {}) {
    let path = '/admin/{appSpace}/templates/{template_id}/tests/{test_id}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{template_id}', templateId);

    path = path.replace('{test_id}', testId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Vyhodnotenie testu.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} templateId - ID predlohy testu.
   * @param {} testId - ID testu.
   * @param {} parameters.body - Podklady pre vyhodnotenie testu
   */
  adminTestEvaluate(appSpace, templateId, testId, parameters = {}) {
    let path =
      '/admin/{appSpace}/templates/{template_id}/tests/{test_id}/evaluate';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{template_id}', templateId);

    path = path.replace('{test_id}', testId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Na zaklade zaslanych okruhov a poctu otazok vyhodnoti, ci je test vytvoritelny.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} parameters.body -
   */
  isTestCreatableBasedOnGroups(appSpace, parameters = {}) {
    let path = '/admin/{appSpace}/is-test-creatable';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.smarttags - RESTful API specification.

       * @param {} appSpace - Identifikátor userspace.
     */
  validateQuestionGroups(appSpace, parameters = {}) {
    let path = '/admin/{appSpace}/validate-question-groups';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['smarttags'] !== 'undefined') {
      queryParameters['smarttags'] = parameters['smarttags'];
    }

    queryParameters['smarttags'] = this.transformParameter(
      queryParameters['smarttags'],
      'joinUsingPipes',
    );

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get Contentspace Details
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   */
  adminGetSettings(appSpace, parameters = {}) {
    let path = '/admin/{appSpace}/settings';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Edit Contentspace Settings
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} parameters.body -
   */
  adminEditSettings(appSpace, parameters = {}) {
    let path = '/admin/{appSpace}/settings';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get Contentspace Details
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   */
  publicGetSettings(appSpace, parameters = {}) {
    let path = '/settings/{appSpace}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Detail predlohy testu.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} templateId - ID predlohy testu.
   */
  testTemplateDetail(templateId, parameters = {}) {
    let path = '/public/templates/{template_id}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{template_id}', templateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Vytvori novy anonymny test - bez zapisania do databazy, len nahodne vygeneruje otazky.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} templateId - ID predlohy testu.
   */
  createAnonymouseTest(templateId, parameters = {}) {
    let path = '/public/templates/{template_id}/anonymouse-test';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{template_id}', templateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Zoznam všetkých testov pre prihláseného usera.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - RESTful API specification.

       * @param {} parameters.offset - RESTful API specification.

       * @param {} appSpace - Identifikátor userspace.
     */
  userAllTestsList(appSpace, parameters = {}) {
    let path = '/user/{appSpace}/tests';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Zoznam testov vygenerovaných z danej predlohy pre prihláseného usera.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - RESTful API specification.

       * @param {} parameters.offset - RESTful API specification.

       * @param {} parameters.seminarId - RESTful API specification.

       * @param {} parameters.seminarPartId - RESTful API specification.

       * @param {} templateId - ID predlohy testu.
     */
  userTestList(templateId, parameters = {}) {
    let path = '/public/templates/{template_id}/tests';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['seminarId'] !== 'undefined') {
      queryParameters['seminarId'] = parameters['seminarId'];
    }

    queryParameters['seminarId'] = this.transformParameter(
      queryParameters['seminarId'],
    );

    if (typeof parameters['seminarPartId'] !== 'undefined') {
      queryParameters['seminarPartId'] = parameters['seminarPartId'];
    }

    queryParameters['seminarPartId'] = this.transformParameter(
      queryParameters['seminarPartId'],
    );

    path = path.replace('{template_id}', templateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Vygenerovanie testu z predlohy pre prihláseného usera.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} templateId - ID predlohy testu.
   */
  startNewTest(templateId, parameters = {}) {
    let path = '/public/templates/{template_id}/tests';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{template_id}', templateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Detail testu.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} templateId - ID predlohy testu.
   * @param {} testId - ID testu.
   */
  userTestDetail(templateId, testId, parameters = {}) {
    let path = '/public/templates/{template_id}/tests/{test_id}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{template_id}', templateId);

    path = path.replace('{test_id}', testId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Odoslanie odpovede na otázku. Ak sa jedná o textovú otázku, ano answer_index sa dáva `0`.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} templateId - ID predlohy testu.
   * @param {} testId - ID testu.
   * @param {} questionIndex - Index otazky v teste
   * @param {} parameters.body -
   */
  testAnswerUpdate(templateId, testId, questionIndex, parameters = {}) {
    let path =
      '/public/templates/{template_id}/tests/{test_id}/update/{question_index}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{template_id}', templateId);

    path = path.replace('{test_id}', testId);

    path = path.replace('{question_index}', questionIndex);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Ukončenie testu. Metoda moze byt zavolana aj pred expiraciou testu, vtedy sa len test ukonci (ak este nebol ukonceny automaticky) a vyhodnoti ak sa vyhodnotit da.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} templateId - ID predlohy testu.
   * @param {} testId - ID testu.
   */
  finishUserTest(templateId, testId, parameters = {}) {
    let path = '/public/templates/{template_id}/tests/{test_id}/finish';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{template_id}', templateId);

    path = path.replace('{test_id}', testId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Zoznam private testov
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - RESTful API specification.

       * @param {} parameters.offset - RESTful API specification.

       * @param {} appSpace - Identifikátor userspace.
     */
  userTemplatesList(appSpace, parameters = {}) {
    let path = '/private-templates/{appSpace}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Zoznam verejných testov
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - RESTful API specification.

       * @param {} parameters.offset - RESTful API specification.

       * @param {} appSpace - Identifikátor userspace.
     */
  getPublicListing(appSpace, parameters = {}) {
    let path = '/public-templates/{appSpace}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Manazment seminarov
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - RESTful API specification.

       * @param {} parameters.offset - RESTful API specification.

       * @param {} appSpace - Identifikátor userspace.
     */
  seminarsList(appSpace, parameters = {}) {
    let path = '/admin/{appSpace}/seminars';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} parameters.body -
   */
  seminarCreate(appSpace, parameters = {}) {
    let path = '/admin/{appSpace}/seminars';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Detail seminaru
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} seminarId - ID seminaru.
   */
  seminarDetail(appSpace, seminarId, parameters = {}) {
    let path = '/admin/{appSpace}/seminars/{seminarId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{seminarId}', seminarId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} seminarId - ID seminaru.
   * @param {} parameters.body -
   */
  seminarUpdate(appSpace, seminarId, parameters = {}) {
    let path = '/admin/{appSpace}/seminars/{seminarId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{seminarId}', seminarId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} seminarId - ID seminaru.
   */
  seminarDelete(appSpace, seminarId, parameters = {}) {
    let path = '/admin/{appSpace}/seminars/{seminarId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{seminarId}', seminarId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} seminarId - ID seminaru.
   */
  seminarCopy(appSpace, seminarId, parameters = {}) {
    let path = '/admin/{appSpace}/seminars/{seminarId}/copy';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{seminarId}', seminarId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} seminarId - ID seminaru.
   * @param {} parameters.body -
   */
  seminarPartCreate(appSpace, seminarId, parameters = {}) {
    let path = '/admin/{appSpace}/seminars/{seminarId}/parts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{seminarId}', seminarId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Detail casti seminaru
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} seminarId - ID seminaru.
   * @param {} seminarPartId - ID seminaru.
   */
  seminarPartDetail(appSpace, seminarId, seminarPartId, parameters = {}) {
    let path = '/admin/{appSpace}/seminars/{seminarId}/parts/{seminarPartId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{seminarId}', seminarId);

    path = path.replace('{seminarPartId}', seminarPartId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} seminarId - ID seminaru.
   * @param {} seminarPartId - ID seminaru.
   * @param {} parameters.body -
   */
  seminarPartUpdate(appSpace, seminarId, seminarPartId, parameters = {}) {
    let path = '/admin/{appSpace}/seminars/{seminarId}/parts/{seminarPartId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{seminarId}', seminarId);

    path = path.replace('{seminarPartId}', seminarPartId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} seminarId - ID seminaru.
   * @param {} seminarPartId - ID seminaru.
   */
  seminarPartDelete(appSpace, seminarId, seminarPartId, parameters = {}) {
    let path = '/admin/{appSpace}/seminars/{seminarId}/parts/{seminarPartId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{seminarId}', seminarId);

    path = path.replace('{seminarPartId}', seminarPartId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Detail casti seminaru
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} seminarId - ID seminaru.
   * @param {} parameters.body -
   */
  seminarPartsReorder(appSpace, seminarId, parameters = {}) {
    let path = '/admin/{appSpace}/seminars/{seminarId}/parts-reorder';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{seminarId}', seminarId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Zoznam pozvaných používateľov na seminar.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - RESTful API specification.

       * @param {} parameters.offset - RESTful API specification.

       * @param {} parameters.status - RESTful API specification.

       * @param {} appSpace - Identifikátor userspace.
       * @param {} seminarId - ID seminaru.
     */
  seminarUsersList(appSpace, seminarId, parameters = {}) {
    let path = '/admin/{appSpace}/seminars/{seminarId}/users';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['status'] !== 'undefined') {
      queryParameters['status'] = parameters['status'];
    }

    queryParameters['status'] = this.transformParameter(
      queryParameters['status'],
    );

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{seminarId}', seminarId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} seminarId - ID seminaru.
   * @param {} parameters.body -
   */
  seminarAddUsers(appSpace, seminarId, parameters = {}) {
    let path = '/admin/{appSpace}/seminars/{seminarId}/users';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{seminarId}', seminarId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Detail usera
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} seminarId - ID seminaru.
   * @param {} sportnetId - Sportnet Id pouzivatela
   */
  seminarUserDetail(appSpace, seminarId, sportnetId, parameters = {}) {
    let path = '/admin/{appSpace}/seminars/{seminarId}/users/{sportnet_id}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{seminarId}', seminarId);

    path = path.replace('{sportnet_id}', sportnetId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} seminarId - ID seminaru.
   * @param {} sportnetId - Sportnet Id pouzivatela
   * @param {} parameters.body -
   */
  seminarUpdateUser(appSpace, seminarId, sportnetId, parameters = {}) {
    let path = '/admin/{appSpace}/seminars/{seminarId}/users/{sportnet_id}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{seminarId}', seminarId);

    path = path.replace('{sportnet_id}', sportnetId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} seminarId - ID seminaru.
   * @param {} sportnetId - Sportnet Id pouzivatela
   */
  seminarDeleteUser(appSpace, seminarId, sportnetId, parameters = {}) {
    let path = '/admin/{appSpace}/seminars/{seminarId}/users/{sportnet_id}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{seminarId}', seminarId);

    path = path.replace('{sportnet_id}', sportnetId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get documents
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} seminarId - ID seminaru.
   * @param {} sportnetId - Sportnet Id pouzivatela
   */
  seminarUserDocs(appSpace, seminarId, sportnetId, parameters = {}) {
    let path =
      '/admin/{appSpace}/seminars/{seminarId}/users/{sportnet_id}/docs';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{seminarId}', seminarId);

    path = path.replace('{sportnet_id}', sportnetId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Upload new document
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} seminarId - ID seminaru.
   * @param {} sportnetId - Sportnet Id pouzivatela
   * @param {} parameters.body -
   */
  seminarUserUploadDoc(appSpace, seminarId, sportnetId, parameters = {}) {
    let path =
      '/admin/{appSpace}/seminars/{seminarId}/users/{sportnet_id}/docs';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{seminarId}', seminarId);

    path = path.replace('{sportnet_id}', sportnetId);

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Delete document
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} seminarId - ID seminaru.
   * @param {} sportnetId - Sportnet Id pouzivatela
   * @param {} documentId - documentId
   */
  seminarUserDocDelete(
    appSpace,
    seminarId,
    sportnetId,
    documentId,
    parameters = {},
  ) {
    let path =
      '/admin/{appSpace}/seminars/{seminarId}/users/{sportnet_id}/docs/{documentId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{seminarId}', seminarId);

    path = path.replace('{sportnet_id}', sportnetId);

    path = path.replace('{documentId}', documentId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get document downloader
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} seminarId - ID seminaru.
   * @param {} sportnetId - Sportnet Id pouzivatela
   * @param {} documentId - documentId
   */
  seminarUserDocDownloader(
    appSpace,
    seminarId,
    sportnetId,
    documentId,
    parameters = {},
  ) {
    let path =
      '/admin/{appSpace}/seminars/{seminarId}/users/{sportnet_id}/docs/{documentId}/download';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{seminarId}', seminarId);

    path = path.replace('{sportnet_id}', sportnetId);

    path = path.replace('{documentId}', documentId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} seminarId - ID seminaru.
   * @param {} parameters.body -
   */
  seminarUserCreateInvitation(appSpace, seminarId, parameters = {}) {
    let path = '/admin/{appSpace}/seminars/{seminarId}/invitations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{seminarId}', seminarId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} seminarId - ID seminaru.
   * @param {} invitationId - Id pozvanky core api
   */
  processSeminarInvitationResponse(
    appSpace,
    seminarId,
    invitationId,
    parameters = {},
  ) {
    let path =
      '/admin/{appSpace}/seminars/{seminarId}/invitations/{invitation_id}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{seminarId}', seminarId);

    path = path.replace('{invitation_id}', invitationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Zoznam všetkých seminarov pre prihláseného usera.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - RESTful API specification.

       * @param {} parameters.offset - RESTful API specification.

       * @param {} appSpace - Identifikátor userspace.
     */
  userSeminarsList(appSpace, parameters = {}) {
    let path = '/user/{appSpace}/seminars';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Detail seminaru usera
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} seminarId - ID seminaru.
   */
  userSeminarDetail(appSpace, seminarId, parameters = {}) {
    let path = '/user/{appSpace}/seminars/{seminarId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{seminarId}', seminarId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Zacatie seminaru - musi byt pending
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} seminarId - ID seminaru.
   * @param {} parameters.body -
   */
  userSeminarStart(appSpace, seminarId, parameters = {}) {
    let path = '/user/{appSpace}/seminars/{seminarId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{seminarId}', seminarId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Detail casti seminaru - len ak ju ma user odomknutu, inak 403
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} seminarId - ID seminaru.
   * @param {} seminarPartId - ID seminaru.
   */
  userSeminarPartDetail(appSpace, seminarId, seminarPartId, parameters = {}) {
    let path = '/user/{appSpace}/seminars/{seminarId}/parts/{seminarPartId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{seminarId}', seminarId);

    path = path.replace('{seminarPartId}', seminarPartId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Odomkni calsie casti seminaru podla testov v tejto casti seminaru
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} seminarId - ID seminaru.
   * @param {} seminarPartId - ID seminaru.
   * @param {} parameters.body -
   */
  userSeminarUnlockNextParts(
    appSpace,
    seminarId,
    seminarPartId,
    parameters = {},
  ) {
    let path =
      '/user/{appSpace}/seminars/{seminarId}/parts/{seminarPartId}/unlock';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{seminarId}', seminarId);

    path = path.replace('{seminarPartId}', seminarPartId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get document downloader
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} seminarId - ID seminaru.
   * @param {} documentId - documentId
   */
  userSeminarDocDownloader(appSpace, seminarId, documentId, parameters = {}) {
    let path =
      '/user/{appSpace}/seminars/{seminarId}/docs/{documentId}/download';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{seminarId}', seminarId);

    path = path.replace('{documentId}', documentId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} appSpace - Identifikátor userspace.
   * @param {} seminarId - ID seminaru.
   * @param {} parameters.body -
   */
  ticketingAssignSeminarUser(appSpace, seminarId, parameters = {}) {
    let path = '/app/{appSpace}/seminar-ticket/{seminarId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{seminarId}', seminarId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.downloadId - RESTful API specification.

     */
  getDownloaderFile(parameters = {}) {
    let path = '/downloader/{downloadId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['downloadId'] !== 'undefined') {
      queryParameters['downloadId'] = parameters['downloadId'];
    }

    queryParameters['downloadId'] = this.transformParameter(
      queryParameters['downloadId'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.template - RESTful API specification.

     */
  redirectToCustomDomain(parameters = {}) {
    let path = '/v2v3/redirector';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['template'] !== 'undefined') {
      queryParameters['template'] = parameters['template'];
    }

    queryParameters['template'] = this.transformParameter(
      queryParameters['template'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
}

export default new API();
export { API };
