import Header from '@sportnet/ui/Header';
import Segment from '@sportnet/ui/Segment';
import { Table, Tbody, Td, Tr } from '@sportnet/ui/Table';
import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';
import SmarttagTags from '@sportnet/ui/SmarttagsControl/SmarttagTags';
import {
  generateSts,
  normalizeSmarttags,
} from '@sportnet/tagmanager-connector';
import {
  nl2br,
  __,
  formatDateLocalized,
  getDurationInMinutes,
} from '../../utils';
import ContentView from '../Content/view';
import Image from '../Image';
import useQuery from '../../hooks/useQuery';
// TODO odstran ak nebude treba

const NotFound = styled.div`
  color: #999;
  margin-left: ${rem(32)};
`;

const HeaderTag = styled.span`
  font-weight: bold;
`;

const TextIconWrapper = styled.div``;

const QuestionText = styled.div`
  font-weight: bold;
  padding-bottom: ${rem(8)};
`;

const PageBreak = styled.div`
  display: none;
  @media print {
    display: block;
    page-break-before: always;
    * {
      box-shadow: none !important;
    }
    img {
      visibility: visible;
    }
  }
`;

const WidgetsWrapper = styled.div`
  * {
    background: none !important;
  }
  padding: ${rem(5)} 0;
`;

const IconWrapper = styled.span`
  margin-right: ${rem(16)};
  width: ${rem(10)};
`;
const Wrapper = styled.div`
  font-size: ${rem(14)};
  * {
    font-family: sans-serif;
  }
`;

const Print = styled.div`
  @media print {
    * {
      display: block !important;
      height: auto !important;
      width: auto !important;
      line-height: 1 !important;
    }
    span {
      display: initial;
    }
    @page {
      size: auto; /* auto is the initial value */

      /* this affects the margin in the printer settings */
      margin: 25mm;
    }
    .print-summary {
      padding: 0;
      box-shadow: none;
    }
    .print-summary-table {
      td {
        height: auto;
      }
    }
    .print-wrapper {
      padding: 0;
    }
    .print-col {
      width: 100%;
      padding: 0;
      > div {
        box-shadow: none;
      }
    }
    .print-question-segment {
      padding: 0;
      display: block;
      * {
        display: table;
      }
    }
    img {
      visibility: visible;
    }
    .print-widgets-wrapper {
      * {
        background: none !important;
      }
    }
  }
`;

// renderovanie okruhov z testu (bud vyrenderuje smarttagy alebo povodne tagy)
// povodne sa testy nemigrovali, takze ak test neobsahuje smarttagy, vygenerujem okruhy z
// tagov
export const renderSmartTagsFromTest = (questions) => {
  const allSmartTags = questions.reduce((acc, question) => {
    if (question.smarttags) {
      return [...acc, ...question.smarttags];
    }
    return [
      ...acc,
      ...question.tags.map((t) => ({
        key: 'Tagy z testovania',
        values: [{ key: t }],
      })),
    ];
  }, []);
  return <SmarttagTags items={normalizeSmarttags(allSmartTags)} />;
};

// renderovanie okruhov (smarttagov) z templateovych stGroups
export const renderSmarttagsFromStGroups = (stg) => {
  if (stg && stg.length) {
    const allSmartTags = stg.reduce((acc, g) => {
      if (g.smarttags.length) {
        if (g.smarttags[0].key) {
          // stare zobrazovanie - po migracii kriterii sa moze vypnut
          return [...acc, ...g.smarttags];
        }
        return [...acc, ...g.smarttags.reduce((ss, s) => [...ss, ...s], [])];
      }
      return acc;
    }, []);
    return <SmarttagTags items={normalizeSmarttags(allSmartTags)} />;
  }
  return null;
};

export const getQuestionGroupsSt = (questions) => {
  if (questions.length) {
    const uniqueSts = questions.reduce((acc, question) => {
      if (question.smarttags && question.smarttags.length) {
        acc.add(...generateSts(question.smarttags));
      }
      return acc;
    }, new Set());
    const smartTags = [...uniqueSts].map((sts) => {
      const split = sts.split(':');
      return {
        key: split[0],
        values: [
          {
            key: split[1],
          },
        ],
      };
    });
    return <SmarttagTags items={smartTags} />;
  }
  return null;
};

const QuestionIcon = ({ question, evalHidden, testIsEvaluated }) => {
  if (evalHidden) {
    return null;
  }

  // ak bol vyhodnoteny
  if (testIsEvaluated) {
    if (question.correct) {
      // spravne zodpovedana otazka
      return <Image name="checkbox-correct" width={16} height={16} />;
    }
    if (question.answers.filter((a) => a.checked).length === 0) {
      // nezodpovedal otazku
      return <Image name="checkbox-unknown" width={16} height={16} />;
    }
    // zle zodpovedal otazku
    return <Image name="checkbox-wrong" width={16} height={16} />;
  }
  if (question.type === 'text') {
    return <Image name="exclamation-mark-circle" width={16} height={16} />;
  }
  return null;
};

const CorrectAnswerIndicator = ({ answer, evalHidden }) => {
  if (evalHidden) {
    if (answer.checked) {
      return <Image name="checkbox-correct" width={16} height={16} />;
    }
    return <Image name="checkbox" width={16} height={16} />;
  }

  if (answer.correct && answer.checked) {
    return <Image name="checkbox-correct" width={16} height={16} />;
  }
  if (answer.correct === false) {
    return <Image name="checkbox-wrong" width={16} height={16} />;
  }

  return <Image name="checkbox" width={16} height={16} />;
};

export default ({ testDetail, evalHidden }) => {
  const {
    testInstitutionName = '',
    testLectorName = '',
    testPurpose = '',
    testVenue = '',
  } = testDetail.template || {};

  const query = useQuery();
  const displayName = query.get('displayName');

  return (
    <Wrapper>
      <Print>
        <Segment className="print-wrapper">
          <div style={{ fontSize: rem(14) }}>
            <Segment raised className="print-summary">
              <Header size="l">{testDetail.name}</Header>
              <Table className="print-summary-table">
                <Tbody>
                  <Tr>
                    <Td>
                      <HeaderTag>
                        {__('Inštitúcia vykonávajúca skúšku')}:
                      </HeaderTag>
                    </Td>
                    <Td>{testInstitutionName}</Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <HeaderTag>{__('Osoba, ktorá vedie skúšku')}:</HeaderTag>
                    </Td>
                    <Td>{testLectorName}</Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <HeaderTag>
                        {__('Meno a priezvisko testovaného')}:
                      </HeaderTag>
                    </Td>
                    <Td>
                      {testDetail.user ? (
                        <span>
                          {testDetail.user.display_name} (
                          {testDetail.user.sportnet_id})
                        </span>
                      ) : (
                        displayName || ''
                      )}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <HeaderTag>{__('Dátum konania skúšky')}:</HeaderTag>
                    </Td>
                    <Td>{formatDateLocalized(new Date(testDetail.startAt))}</Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <HeaderTag>{__('Účel vykonania skúšky')}:</HeaderTag>
                    </Td>
                    <Td>{testPurpose}</Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <HeaderTag>{__('Miesto konania skúšky')}:</HeaderTag>
                    </Td>
                    <Td>{testVenue}</Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <HeaderTag>{__('Okruhy otázok v teste')}:</HeaderTag>
                    </Td>
                    {testDetail.questions && (
                      <Td>{renderSmartTagsFromTest(testDetail.questions)}</Td>
                    )}
                  </Tr>
                  <Tr>
                    <Td>
                      <HeaderTag>{__('Počet otázok v teste')}</HeaderTag>
                    </Td>
                    <Td>{testDetail.questions.length}</Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <HeaderTag>
                        {__('Dĺžka času na vypracovanie testu')}
                      </HeaderTag>
                    </Td>
                    <Td>{testDetail.limit / 60} min</Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <HeaderTag>{__('Dĺžka vypracovania testu')}</HeaderTag>
                    </Td>
                    <Td>
                      {testDetail.userFinishAt
                        ? getDurationInMinutes(
                            new Date(testDetail.userFinishAt),
                            new Date(testDetail.startAt),
                          )
                        : testDetail.limit / 60}{' '}
                      min
                    </Td>
                  </Tr>
                  {!evalHidden && (
                    <>
                      <Tr>
                        <Td>
                          <HeaderTag>
                            {__('Počet správnych odpovedí')}
                          </HeaderTag>
                        </Td>
                        <Td>
                          {testDetail.evaluated
                            ? testDetail.nrofCorrectQuestions
                            : '-'}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <HeaderTag>
                            {__('Počet nesprávnych odpovedí:')}
                          </HeaderTag>
                        </Td>
                        <Td>
                          {testDetail.evaluated
                            ? testDetail.nrofQuestions -
                              testDetail.nrofCorrectQuestions
                            : '-'}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <HeaderTag>
                            {__('Počet nezodpovedaných otázok')}
                          </HeaderTag>
                        </Td>
                        <Td>
                          {testDetail.evaluated
                            ? testDetail.questions.filter((q) => {
                                return (
                                  q.answers.filter((a) => a.checked).length ===
                                  0
                                );
                              }).length
                            : '-'}
                        </Td>
                      </Tr>
                    </>
                  )}
                  <Tr>
                    <Td>
                      <HeaderTag>
                        {__('Minimálna potrebná úspešnosť')}
                      </HeaderTag>
                    </Td>
                    <Td>
                      {parseFloat(testDetail.minPassed * 100).toFixed(2)}%
                    </Td>
                  </Tr>
                  {!evalHidden && (
                    <Tr>
                      <Td>
                        <HeaderTag>{__('Dosiahnutá úspešnosť')}</HeaderTag>
                      </Td>
                      <Td>
                        {testDetail.evaluated
                          ? `${parseFloat(testDetail.evaluation * 100).toFixed(
                              2,
                            )}% (${testDetail.nrofCorrectQuestions}/${
                              testDetail.nrofQuestions
                            })`
                          : '-'}
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </Segment>
          </div>
        </Segment>
      </Print>

      <PageBreak />
      <Segment>
        {testDetail.questions.map((question, index) => {
          return (
            <Segment key={question._id}>
              <QuestionText>
                <TextIconWrapper>
                  <IconWrapper>
                    <QuestionIcon
                      question={question}
                      evalHidden={evalHidden}
                      testIsEvaluated={testDetail.evaluated}
                    />
                  </IconWrapper>
                  {index + 1}.{nl2br(question.text)}
                </TextIconWrapper>
              </QuestionText>
              <WidgetsWrapper>
                <ContentView items={question.description} />
              </WidgetsWrapper>

              {(question.answers || []).length === 0 && (
                <NotFound>{__('Žiadna odpoveď')}</NotFound>
              )}

              {question.answers.map((answer) => {
                const answerStyle = {};
                if (!evalHidden && answer.isCorrect) {
                  answerStyle.color = 'green';
                  answerStyle.fontWeight = 'bold';
                }
                const isTextAnswer =
                  question.type === 'text' && question.answers.length;
                return (
                  <div key={answer.text ? answer.text : answer.userAnswer}>
                    <TextIconWrapper style={answerStyle}>
                      {!isTextAnswer && (
                        <IconWrapper>
                          {CorrectAnswerIndicator({ answer, evalHidden })}
                        </IconWrapper>
                      )}
                      {nl2br(answer.text ? answer.text : answer.userAnswer)}{' '}
                      {!evalHidden && answer.isCorrect
                        ? __('(správna odpoveď)')
                        : ''}
                    </TextIconWrapper>
                    <WidgetsWrapper>
                      <ContentView items={answer.description} />
                    </WidgetsWrapper>
                  </div>
                );
              })}
            </Segment>
          );
        })}
      </Segment>
    </Wrapper>
  );
};
