import Button from '@sportnet/ui/Button';
import FormField from '@sportnet/ui/FormField';
import { Loader } from '@sportnet/ui/Loader';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import API from '../../API';
import FileInput from '../../components/FileInput';
import { __ } from '../../utils';
import SeminarUserLogs from './seminarUserLogs';

const SidebarButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const UserForm = ({ availableStatuses, user, onRemove, onSave, seminar }) => {
  const [isFetching, setIsFetching] = React.useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [downloadingFiles, setDownloadingFiles] = useState(false);
  const [deletingFiles, setDeletingFiles] = useState(false);

  React.useEffect(() => {
    setIsFetching(true);
    API.seminarUserDetail(user.userspace, user.seminarId, user.user.sportnetId)
      .then((res) => {
        setEditingUser(res);
      })
      .catch((e) => {
        alert(__('Nepodarilo sa získať informácie o účastníkovi seminára'));
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [user]);

  const changeEditingUserSettings = (setting, value) => {
    setEditingUser((current) => {
      return {
        ...current,
        [setting]: value,
      };
    });
  };

  const [isSaving, setIsSaving] = useState(false);
  const saveEditingUserSettings = () => {
    if (!editingUser) {
      return;
    }
    setIsSaving(true);
    const uploadPromises = [];

    Array.from(editingUser.docs || [])
      .filter((doc) => doc._new)
      .forEach((file) => {
        uploadPromises.push(
          API.seminarUserUploadDoc(
            user.userspace,
            user.seminarId,
            user.user.sportnetId,
            { body: file.file },
          ),
        );
      });
    Promise.all(uploadPromises)
      .then(() => {
        API.seminarUpdateUser(
          user.userspace,
          user.seminarId,
          user.user.sportnetId,
          {
            body: {
              status: editingUser.status,
              result: editingUser.result || '',
            },
          },
        );
      })
      .then(() => {
        if (onSave) {
          onSave(editingUser);
        }
      })
      .catch((e) => {
        alert(
          `Pri ukladaní parametrov používateľa nastala chyba: ${
            e.details ? e.details.name : e.name
          }`,
        );
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const downloadFile = async (file) => {
    try {
      setDownloadingFiles(true);
      const res = await API.seminarUserDocDownloader(
        user.userspace,
        user.seminarId,
        user.user.sportnetId,
        file._id,
      );
      window.location.href = res.url;
    } catch (e) {
      alert(__('Súbor sa nepodarilo stiahnuť.'));
    } finally {
      setDownloadingFiles(false);
    }
  };

  const [isRemoving, setIsRemoving] = useState(false);
  const removeUser = () => {
    if (!editingUser) {
      return;
    }
    if (!window.confirm(__('Skutočne si prajete účastníka odstrániť?'))) {
      return;
    }
    setIsRemoving(true);
    API.seminarDeleteUser(
      user.userspace,
      user.seminarId,
      `${user.user.sportnetId}`,
    )
      .then(() => {
        if (onRemove) {
          onRemove(editingUser);
        }
      })
      .catch((e) => {
        alert(
          `Pri odstraňovaní používateľa nastala chyba: ${
            e.details ? e.details.name : e
          }`,
        );
      })
      .finally(() => {
        setIsRemoving(false);
      });
  };

  const deleteFile = async (file) => {
    try {
      setDeletingFiles(true);
      await API.seminarUserDocDelete(
        user.userspace,
        user.seminarId,
        user.user.sportnetId,
        file._id,
      );
      const docs = editingUser.docs.filter((doc) => doc._id !== file._id);
      setEditingUser((prevState) => ({
        ...prevState,
        docs,
      }));
    } catch (e) {
      alert(__('Súbor sa nepodarilo odstrániť'));
    } finally {
      setDeletingFiles(false);
    }
  };

  if (isFetching || !editingUser) {
    return (
      <LoaderWrapper>
        <Loader size="xl" />
      </LoaderWrapper>
    );
  }

  return (
    <>
      <Segment loading={downloadingFiles || deletingFiles}>
        <FormField
          label={__('Zmena stavu na')}
          name="status"
          type="theselectsimple"
          value={editingUser.status || ''}
          clearable={false}
          onChange={(value) => {
            changeEditingUserSettings('status', value);
          }}
          options={availableStatuses}
          disabled={isSaving || isRemoving}
        />
        {['complete', 'incomplete'].includes(editingUser.status) && (
          <>
            <FileInput
              id={editingUser._id}
              onChange={(files) => {
                changeEditingUserSettings('docs', files);
              }}
              onDeleteCallback={(file) => {
                deleteFile(file);
              }}
              onFileClick={(file) => {
                downloadFile(file);
              }}
              multiple
              value={[...(editingUser.docs || [])]}
              disabled={isSaving || isRemoving}
            />
            <br />
            <FormField
              label={__('Záverečná správa')}
              name="result"
              type="textarea"
              value={editingUser.result || ''}
              onChange={(e) => {
                changeEditingUserSettings('result', e.target.value);
              }}
              disabled={isSaving || isRemoving}
            />
          </>
        )}
        <SidebarButtons>
          {editingUser.status === 'pending' && (
            <Button
              danger
              onClick={removeUser}
              disabled={isSaving || isRemoving}
              loading={isRemoving}
            >
              {__('Odstrániť')}
            </Button>
          )}
          <Button
            primary
            onClick={saveEditingUserSettings}
            disabled={isSaving || isRemoving}
            loading={isSaving}
          >
            {__('Uložiť')}
          </Button>
        </SidebarButtons>
      </Segment>
      <Segment
        header={
          <SegmentHeader size="xs" collapsible>
            {__('História zmien')}
          </SegmentHeader>
        }
      >
        <SeminarUserLogs logs={editingUser.log || []} seminar={seminar} />
      </Segment>
    </>
  );
};

UserForm.propTypes = {
  user: PropTypes.shape({
    userspace: PropTypes.string.isRequired,
    seminarId: PropTypes.string.isRequired,
    user: PropTypes.shape({
      sportnetId: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    }).isRequired,
    settings: PropTypes.shape(),
  }).isRequired,
  onRemove: PropTypes.func,
  onSave: PropTypes.func,
  availableStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

UserForm.defaultProps = {
  onRemove: null,
  onSave: null,
};

export default UserForm;
