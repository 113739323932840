import Button from '@sportnet/ui/Button';
import FormField from '@sportnet/ui/FormField';
import FormGroup from '@sportnet/ui/FormGroup';
import Col, { Row } from '@sportnet/ui/Grid';
import Header from '@sportnet/ui/Header';
import Label from '@sportnet/ui/Label/Label';
import Loader from '@sportnet/ui/Loader';
import Modal, { AlertModal, ModalActions } from '@sportnet/ui/Modal';
import { ModalContent } from '@sportnet/ui/Modal/modal';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import SmarttagControl from '@sportnet/ui/SmarttagsControl/SmarttagControl';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { getProp } from '@sportnet/utilities';
import styled from 'styled-components';
import TagmanagerConnector from '@sportnet/tagmanager-connector';
import API from '../../API';
import ContentEditor from '../../components/Content/editor';
import { getAvailableWidgets, __ } from '../../utils';
import {
  activeAppSpaceSelector,
  appSpacesSelector,
} from '../Authorization/selectors';
import MandatoryVideo from '../MandatoryVideo';
import { AddAnswerButtonWrapper } from '../Questions/styles';
import withSportnetAuth from '../../hoc/withSportnetAuth';

const LoaderWrapper = styled.div`
  width: 100%;
  height: ${rem(80)};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
`;

const ActionWrapper = styled.div`
  margin-left: auto;
`;

class CopyQuestionModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      mandatoryVideoForm: false,
      haveAnswers: true,
      video: null,
      answers: [
        {
          text: '',
          checked: false,
          description: {
            widgets: [],
          },
        },
      ],
      content: {
        widgets: [],
        name: '',
        publish: false,
        scriptLoaded: false,
      },
      smarttags: [],
      autocompleteTags: [],
      newTag: '',
      questionName: '',
      isFetching: false,
      hint: {
        widgets: [],
      },
      isSubmitting: false,
      submitError: null,
    };

    this.hanleChangeHaveAnsw = this.hanleChangeHaveAnsw.bind(this);
    this.handleChangeVideo = this.handleChangeVideo.bind(this);
    this.handleChangeText = this.handleChangeText.bind(this);
    this.handleChangeSwitch = this.handleChangeSwitch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeQuestionName = this.handleChangeQuestionName.bind(this);
    this.handleAddAnswer = this.handleAddAnswer.bind(this);
    this.handleDeleteAnswer = this.handleDeleteAnswer.bind(this);

    this.handleUpdateContent = this.handleUpdateContent.bind(this);
    this.handleUpdateAnswerContent = this.handleUpdateAnswerContent.bind(this);
    this.handleChangeHint = this.handleChangeHint.bind(this);
    this.loadDefaults = this.loadDefaults.bind(this);
    this.handleRemoveSubmitError = this.handleRemoveSubmitError.bind(this);
    this.handleUpdateHint = this.handleUpdateHint.bind(this);
  }

  componentDidMount() {
    this.loadDefaults(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen && this.props.isOpen !== nextProps.isOpen) {
      this.loadDefaults(nextProps);
    }
  }

  async loadDefaults(props) {
    const { userspaceId, defaults } = props;
    if (!userspaceId) return;
    this.setState({
      isFetching: true,
    });

    const data = {
      ...(defaults || {}),
      isFetching: false,
    };
    this.setState(data);
  }

  async handleSubmit() {
    const { video, haveAnswers, smarttags, answers, questionName, hint } =
      this.state;
    const { userspaceId, onSubmit, userspaces } = this.props;
    if (!questionName || questionName.length < 1) {
      this.setState({
        submitError: __('Vyplňte prosím znenie otázky.'),
      });
      return false;
    }
    if (!smarttags.length) {
      this.setState({
        submitError: __('Vyplňte prosím okruhy (SMART:TAGS) otázky.'),
      });
      return false;
    }
    const data = {};
    data.text = questionName;
    data.description = this.state.content.widgets;
    data.smarttags = smarttags;
    data.answers = [];
    data.mandatoryVideo = video;
    if (haveAnswers) {
      data.type = 'options';
      const newA = [];
      let hasCorrectAnswer = false;
      answers.forEach((answ) => {
        if (
          answ.text !== '' ||
          (answ.description && answ.description.widgets.length > 0)
        ) {
          newA.push({
            text: answ.text,
            isCorrect: answ.checked,
            description: answ.description ? answ.description.widgets : [],
          });
          if (answ.checked) {
            hasCorrectAnswer = true;
          }
        }
      });
      if (!hasCorrectAnswer) {
        this.setState({
          submitError: __('Otázka musí mať aspoň jednu správnu odpoveď'),
        });
        return false;
      }
      data.answers = newA;
    } else {
      data.type = 'text';
    }
    if (hint.widgets && hint.widgets.length > 0) {
      data.hint = hint.widgets;
    }
    this.setState({
      isSubmitting: true,
    });
    try {
      await API.questionCreate(userspaceId, { body: data });
      if (onSubmit) {
        onSubmit();
      }
    } catch (e) {
      let submitError = __('Uloženie otázky sa nepodarilo.');
      if (getProp(e, ['details', 'name'], '') === 'ACL_FORBIDDEN') {
        const orgProfileName = getProp(
          userspaces.find(
            (us) => getProp(us, ['org_profile', '_id'], '') === userspaceId,
          ),
          ['org_profile.name'],
          '',
        );
        submitError = `${submitError} ${__(
          'V organizácií',
        )} ${orgProfileName} ${__('nemáte právo spravovať otázky')}.`;
      }
      this.setState({
        submitError,
      });
      console.error(e);
    } finally {
      this.setState({
        isSubmitting: false,
      });
    }

    return true;
  }

  handleRemoveSubmitError() {
    this.setState({
      submitError: null,
    });
  }

  handleUpdateContent(widgets) {
    this.setState({
      content: {
        widgets,
      },
    });
  }

  handleUpdateHint(widgets) {
    this.setState({
      hint: {
        widgets,
      },
    });
  }

  handleUpdateAnswerContent(content, i) {
    const { answers } = this.state;
    const newAnswers = [...answers];
    newAnswers[i].description = content;
    this.setState({
      answers: newAnswers,
    });
  }

  handleAddAnswer() {
    const newAnswers = [...this.state.answers];
    newAnswers.push({
      text: '',
      checked: false,
      description: {
        widgets: [],
      },
    });
    this.setState({
      answers: newAnswers,
    });
  }

  handleDeleteAnswer(i) {
    const newAnswers = [...this.state.answers];
    newAnswers.splice(i, 1);
    this.setState({
      answers: newAnswers,
    });
  }

  handleChangeSwitch(e, i) {
    const { answers } = this.state;
    const newAnswers = [...answers];
    newAnswers[i].checked = !answers[i].checked;
    this.setState({
      answers: newAnswers,
    });
  }

  handleChangeText(e, i) {
    const { answers } = this.state;
    const newAnswers = [...answers];
    newAnswers[i].text = e.target.value;
    this.setState({
      answers: newAnswers,
    });
  }

  hanleChangeHaveAnsw() {
    this.setState({
      haveAnswers: !this.state.haveAnswers,
    });
  }

  handleChangeVideo(video) {
    this.setState({
      video,
      mandatoryVideoForm: false,
    });
  }

  handleChangeQuestionName(e) {
    this.setState({
      questionName: e.target.value,
    });
  }

  handleChangeHint(e) {
    this.setState({
      hint: {
        text: e.target.value,
      },
    });
  }

  renderAnswerBlock(answer, i) {
    const { text, checked } = answer;
    const { answers } = this.state;
    return (
      <Segment
        secondary
        key={String(i + 1)}
        header={
          <SegmentHeader
            isCollapsed
            size="xs"
            withSeparator
            collapsible
            onDispose={
              answers.length > 1 ? () => this.handleDeleteAnswer(i) : undefined
            }
          >
            {`Odpoveď č. ${i + 1}`}
          </SegmentHeader>
        }
      >
        <Row>
          <Col>
            <FormField
              label={__('Odpoveď')}
              type="textarea"
              value={text}
              onChange={(e) => this.handleChangeText(e, i)}
            />
          </Col>
          <Col>
            <FormField
              label={__('Odpoveď je správna')}
              type="checkbox"
              value={checked}
              checked={checked}
              onChange={(e) => this.handleChangeSwitch(e, i)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ContentEditor
              value={getProp(answer.description, ['widgets'], [])}
              onChange={(widgets) => {
                const content = getProp(answer, ['description'], {
                  widgets: [],
                });
                content.widgets = widgets.reduce((acc, next) => {
                  if (next.type === 'text') {
                    return [
                      ...acc,
                      {
                        ...next,
                        value: next.text,
                      },
                    ];
                  }
                  return [...acc, next];
                }, []);
                this.handleUpdateAnswerContent(content, i);
              }}
              availableWidgets={getAvailableWidgets()}
            />
          </Col>
        </Row>
      </Segment>
    );
  }

  render() {
    const {
      haveAnswers,
      video,
      answers,
      questionName,
      isFetching,
      hint,
      isSubmitting,
      submitError,
    } = this.state;
    const { isOpen, handleClose, userspaceId, userspaces } = this.props;
    return (
      <Modal isOpen={isOpen} handleClose={handleClose} size="m">
        <ModalContent>
          <AlertModal
            isOpen={!!submitError}
            handleClose={this.handleRemoveSubmitError}
          >
            {submitError}
          </AlertModal>
          <Header withSeparator>
            {`${__('Skopírovať otázku do:')} ${getProp(
              userspaces.find(
                (us) => getProp(us, ['org_profile', '_id'], '') === userspaceId,
              ),
              ['org_profile', 'name'],
              '',
            )}`}
          </Header>
          {isFetching ? (
            <LoaderWrapper>
              <Loader size="xl" />
            </LoaderWrapper>
          ) : (
            <>
              <Segment
                raised
                header={
                  <SegmentHeader size="s" withSeparator>
                    {__('Úprava otázky')}
                  </SegmentHeader>
                }
              >
                <Row>
                  <Col m={6}>
                    <FormField
                      label={__('Znenie')}
                      type="textarea"
                      value={questionName}
                      onChange={this.handleChangeQuestionName}
                    />
                  </Col>
                  <Col m={6}>
                    <FormGroup>
                      <Label htmlFor="smarttags">{__('Smarttags')}</Label>
                      <TagmanagerConnector
                        appId={process.env.REACT_APP_APP_ID}
                        appspace={this.props.userspace.org_profile._id}
                        accessToken={this.props.auth.accessToken}
                      >
                        {(
                          getSmarttagsKeys,
                          getSmarttagsValues,
                          getSmarttagsByFts,
                        ) => (
                          <SmarttagControl
                            name="smarttags"
                            getSmarttagsKeys={getSmarttagsKeys}
                            getSmarttagsValues={getSmarttagsValues}
                            getSmarttagsByFts={getSmarttagsByFts}
                            value={this.state.smarttags}
                            onChange={(v) => this.setState({ smarttags: v })}
                          />
                        )}
                      </TagmanagerConnector>
                    </FormGroup>
                  </Col>
                  <Col>
                    <Button
                      primary={!!video}
                      basic={!!!video}
                      onClick={() => {
                        this.setState((prevState) => ({
                          mandatoryVideoForm: !prevState.mandatoryVideoForm,
                        }));
                      }}
                      icon={video ? 'check-circle' : 'plus'}
                    >
                      {__('Povinné video')}
                    </Button>
                  </Col>
                </Row>
              </Segment>

              <Segment
                raised
                header={
                  <SegmentHeader withSeparator collapsible size="s" isCollapsed>
                    {__('Nápoveda')}
                  </SegmentHeader>
                }
              >
                <Row>
                  <Col>
                    <ContentEditor
                      onChange={(widgets) => {
                        const newWidgets = widgets.reduce((acc, next) => {
                          if (next.type === 'text') {
                            return [
                              ...acc,
                              {
                                ...next,
                                value: next.text,
                              },
                            ];
                          }
                          return [...acc, next];
                        }, []);
                        this.handleUpdateHint(newWidgets);
                      }}
                      value={hint.widgets || []}
                      availableWidgets={getAvailableWidgets()}
                    />
                  </Col>
                </Row>
              </Segment>

              <Segment
                raised
                header={
                  <SegmentHeader withSeparator collapsible size="s" isCollapsed>
                    {__('Popis')}
                  </SegmentHeader>
                }
              >
                <Row>
                  <Col>
                    <ContentEditor
                      onChange={(widgets) => {
                        const newWidgets = widgets.reduce((acc, next) => {
                          if (next.type === 'text') {
                            return [
                              ...acc,
                              {
                                ...next,
                                value: next.text,
                              },
                            ];
                          }
                          return [...acc, next];
                        }, []);
                        this.handleUpdateContent(newWidgets);
                      }}
                      value={this.state.content.widgets || []}
                      availableWidgets={getAvailableWidgets()}
                    />
                  </Col>
                </Row>
              </Segment>

              <Segment
                raised
                header={
                  <SegmentHeader withSeparator collapsible size="s" isCollapsed>
                    {__('Odpovede')}
                  </SegmentHeader>
                }
              >
                <Row>
                  <Col>
                    <FormField
                      label={__('Otázka má odpovede')}
                      type="checkbox"
                      value={haveAnswers}
                      checked={haveAnswers}
                      onChange={this.hanleChangeHaveAnsw}
                    />
                  </Col>
                </Row>

                {haveAnswers && (
                  <>
                    {answers &&
                      answers.map((answer, i) =>
                        this.renderAnswerBlock(answer, i),
                      )}
                    <AddAnswerButtonWrapper>
                      <Button
                        success
                        icon="plus"
                        onClick={this.handleAddAnswer}
                      >
                        {__('Pridať odpoveď')}
                      </Button>
                    </AddAnswerButtonWrapper>
                  </>
                )}
              </Segment>
            </>
          )}
        </ModalContent>
        <ModalActions>
          <ActionWrapper>
            <Button secondary onClick={handleClose}>
              {__('Zrušiť')}
            </Button>
            <Button
              primary
              onClick={this.handleSubmit}
              disabled={isFetching || isSubmitting}
              loading={isSubmitting}
            >
              {__('Uložiť')}
            </Button>
          </ActionWrapper>
        </ModalActions>
        {this.state.mandatoryVideoForm && (
          <MandatoryVideo
            isOpen={this.state.mandatoryVideoForm}
            handleClose={() => this.setState({ mandatoryVideoForm: false })}
            video={video}
            onVideoChange={this.handleChangeVideo}
          />
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userspaces: appSpacesSelector(state),
    userspace: activeAppSpaceSelector(state),
  };
};

CopyQuestionModal.propTypes = {
  onSubmit: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  userspaceId: PropTypes.string,
  defaults: PropTypes.shape({}),
  userspaces: PropTypes.arrayOf(
    PropTypes.shape({
      org_profile: PropTypes.shape({
        name: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
};

CopyQuestionModal.defaultProps = {
  onSubmit: null,
  defaults: null,
  userspaceId: null,
};

export default connect(mapStateToProps)(withSportnetAuth(CopyQuestionModal));
