import React from 'react';
import {
  addSeconds,
  differenceInSeconds,
  format,
  startOfDay,
  differenceInMinutes,
} from 'date-fns';
import { sk } from 'date-fns/locale/sk';

export const __ = (toTranslate) => toTranslate; // eslint-disable-line no-underscore-dangle

export function parseQs(qs) {
  const result = {};
  if (qs.length === 0) {
    return result;
  }
  const query = qs[0] === '#' ? qs.substr(1) : qs;
  query.split('&').forEach((part) => {
    const item = part.split('=');
    result[decodeURIComponent(item[0])] = decodeURIComponent(item[1]);
  });
  return result;
}

export const nl2br = (str) => {
  if (typeof str === 'number') {
    return str;
  }
  if (typeof str !== 'string') {
    return '';
  }

  const newlineRegex = /(\r\n|\r|\n)/g;
  return str.split(newlineRegex).map((line, index) => {
    if (line.match(newlineRegex)) {
      return React.createElement('br', { key: index.toString() });
    }
    return line;
  });
};

export const getAvailableWidgets = () => {
  return [
    { label: 'Dokument', type: 'document' },
    { label: 'Externý obsah', type: 'externalArticle' },
    { label: 'Fotogaléria', type: 'photogallery' },
    { label: 'Fotografia', type: 'photo' },
    { label: 'Tabuľka', type: 'table' },
    { label: 'Video', type: 'video' },
  ];
};

export const getExceptionMessage = (err) => {
  let exceptionText;
  if (err.details) {
    exceptionText = `${err.details.statusCode}: ${err.details.description}`;
  }
  return `${exceptionText || err}`;
};

export const isValidEmail = (email) => {
  return (email || '').match(/^.+@.+\..+$/);
};

export const aclIsAllowed = (acl, method) => {
  return acl && !!acl[method];
};

export const validateApiKey = (value) => {
  if (!value || value.length === 0) {
    return null;
  } else if (value.length >= 32 && value.length <= 64) {
    return null;
  }

  return __('Api Key musí mať minimálne 32 a maximálne 64 znakov');
};

export const required = (value) => {
  if (value) {
    return null;
  }
  return __('Pole je povinné');
};

export const getApiError = (error) => {
  if (error.details !== undefined) {
    if (error.details.code !== undefined) {
      const { code: statusCode, ...restDetails } = error.details;
      return {
        ...error,
        details: {
          ...restDetails,
          statusCode,
        },
      };
    } else {
      return error;
    }
  } else if (error.statusCode !== undefined) {
    return {
      message: error.description,
      details: {
        statusCode: 500,
        name: error.name,
        description: error.description,
      },
    };
  }
  return {
    message: error.message,
    details: {
      statusCode: 500,
      name: 'OTHER_EXCEPTION',
      description: error.message,
    },
  };
};

export const getTimeDurationAsString = (latterDate, earlierDate) => {
  const diff = differenceInSeconds(new Date(latterDate), new Date(earlierDate));
  return format(addSeconds(startOfDay(new Date()), diff), 'HH:mm:ss');
};

export const getDurationInMinutes = (latterDate, earlierDate) => {
  return differenceInMinutes(new Date(latterDate), new Date(earlierDate));
};

export const getTimeDurationFromSeconds = (seconds) => {
  return format(addSeconds(startOfDay(new Date()), seconds), 'HH:mm:ss');
};

export const getDateFormatString = (resolution) => {
  switch (resolution) {
    case 'day': {
      return 'dd.MM.yyyy';
    }
    case 'minutes': {
      return 'dd.MM.yyyy HH:mm';
    }
    case 'seconds': {
      return 'dd.MM.yyyy HH:mm:ss';
    }
    default: {
      return 'dd.MM.yyyy HH:mm:ss';
    }
  }
};

export const formatDate = (date, resolution = 'day') => {
  const formatStr = getDateFormatString(resolution);
  return format(date, formatStr, {
    locale: sk,
  });
};

export const formatDateLocalized = (date) => {
  return format(date, 'PPPpp', {
    locale: sk,
  });
};
