import HeaderBar from '@sportnet/ui/HeaderBar';
import Segment from '@sportnet/ui/Segment';
import React, { useEffect, useState } from 'react';
import Domains from '@sportnet/ui/Domains';
import Col, { Row } from '@sportnet/ui/Grid';
import API from '../../API';
import Layout from '../../components/Layout';
import { __ } from '../../utils';
import { useAuth } from '@sportnet/auth-react';

const DomainSettings = () => {
  const { accessToken, ppo: appSpace } = useAuth();

  const topFixed = (
    <>
      <HeaderBar>
        <HeaderBar.Header>{__('Nastavenia domény')}</HeaderBar.Header>
      </HeaderBar>
    </>
  );

  const [domain, setDomain] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    API.adminGetSettings(appSpace)
      .then((s) => {
        setDomain(s.domain || null);
      })
      .catch((e) => {
        alert(
          `Chyba pri získavaní nastavení: ${
            e.details ? e.details.name : e.name
          }`,
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [appSpace]);

  // ulozime settingy ked sa zmenia
  const [settings, changeSettings] = useState(null);
  useEffect(() => {
    if (!settings) {
      return;
    }
    API.adminEditSettings(appSpace, { body: settings }).catch((e) => {
      alert(
        `Chyba pri ukladaní nastavení: ${e.details ? e.details.name : e.name}`,
      );
    });
  }, [appSpace, settings]);

  return (
    <Layout topFixed={topFixed}>
      <Row>
        <Col s={12} m={8} l={6}>
          <Segment loading={isLoading}>
            {!isLoading && (
              <Domains
                domain={domain || undefined}
                appSpace={appSpace}
                appId={process.env.REACT_APP_APP_ID}
                testDomainPostfix=".testovanie.sportnet.online"
                accessToken={accessToken}
                onChange={(d) => {
                  if (d === null) {
                    setDomain(null);
                    changeSettings({ domain: null });
                  } else {
                    setDomain(d._id);
                    changeSettings({ domain: d._id });
                  }
                }}
              />
            )}
          </Segment>
        </Col>
      </Row>
    </Layout>
  );
};

export default DomainSettings;
